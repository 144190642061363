import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import { CuestionarioService } from '../../../_services';

@Component({
  selector: 'kt-cuestionario',
  templateUrl: './cuestionario.component.html',
  styleUrls: ['./cuestionario.component.scss']
})
export class CuestionarioComponent implements OnInit {
  
  cuestionarioH: any = {
    nombre: "HIJOS / HIJAS",
    descripcion: "",
    fecha: null,
    url: null,
    password: null,
    activo: true,
    preguntas: [
      //Pagina 1
      [
        {
          tipo: "TITULO",
          texto: "CONSENTIMIENTO INFORMADO",
          codigo: "TIT1H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>El presente cuestionario tiene como objetivo conocer con más profundidad las preocupaciones y dificultades de los usuarios de los Puntos de Encuentro Familiar con el fin de poder darles una respuesta más adecuada a sus necesidades. Para ello es importante, que respondas a unos cuestionarios anualmente, lo que te llevará aproximadamente 15-20 minutos.</p>" +
                  "<p>Los cuestionarios son confidenciales y, si bien se te pide más adelante que pongas tu dirección de correo electrónico (email), esto será exclusivamente para poder enviarte recordatorios futuros cuando se acerque el momento de que vuelvas a responder a este cuestionario (se repetirá solo una segunda vez ). Este proceso se realizará de forma automatizada, sin que se conozca tu identidad. También nos servirá para agradecerte, con un bono-regalo de 10 euros, tu participación cada vez que respondas al cuestionario.</p>" +
                  "<p>Los datos serán enviados a investigadores vinculados a la Universidad de Deusto, quienes no conocen a los usuarios del centro y tratarán tus datos con la mayor confidencialidad posible. Esta confidencialidad sólo podrá romperse como medida de protección cuando exista certeza de riesgo de daño para tí o para las personas que te rodean.</p>" +
                  "<p>Por tanto, responde con la máxima sinceridad posible. Si tienes alguna duda al responder al cuestionario, puedes preguntarle a la persona que te ha atendido en el Punto de Encuentro Familiar.</p>" +
                  "<p>Una vez que hayas leído la información previa y aclarado las dudas que tengas puedes proceder a participar, siempre con la posibilidad de interrumpir tu participación en el momento en que lo desees.</p>" +
                  "<p>Si deseas obtener más información sobre el estudio o realizar cualquier consulta, puedes contactar con en el equipo de investigación a través de la siguiente dirección de correo electrónico: <a href='mailto:pefs.familia@deusto.es'>pefs.familia@deusto.es</a>. También, si deseas conocer más sobre la política de protección de datos, puedes pinchar <a href='https://deustofamilypsych.deusto.es/proteccion-de-datos/' target='_blank'>AQUÍ</a>. Si no, puedes comenzar.</p>",
                  //"<p><b>NOTA: En el caso de que uno de sus hijos/as responda al cuestionario, las respuestas del hijo/a serán tratadas igualmente con confidencialidad, no teniendo acceso a su contenido ninguno de los dos progenitores ni ningún otro profesional.</b></p>",
          codigo: "TXT1H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "Me han explicado para qué voy a rellenar los cuestionarios",
          codigo: "RAD1H",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Sé que puedo hacer preguntas sobre el estudio",
          codigo: "RAD2H",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Sé que puedo pedir información sobre los resultados generales de este estudio cuando éste haya concluido",
          codigo: "RAD3H",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Conozco el objetivo del estudio",
          codigo: "RAD4H",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Acepto participar en este estudio",
          codigo: "RAD5H",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
      ],
      //Pagina 2
      [
        {
          tipo: "TITULO",
          texto: "PROTOCOLO DE EVALUACIÓN",
          codigo: "TIT2H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<b>En primer lugar, te solicitamos una serie de datos para poder: 1) Enlazar tus respuestas con las tu padre o madre sin pedir, para ello, datos identificativos, 2) Ponernos en contacto contigo para el recordatorio del siguiente momento de evaluación</b>",
          codigo: "TXT2H",
          visible: true
        },
        {
          tipo: "INPUT",
          texto: "Correo electrónico (si no tienes, puedes indicar tu número de teléfono al que enviaremos un mensaje recordatorio)",
          codigo: "INP1H",
          visible: true,
          required: true,
          maxSize: null,
          type: "TEXT"
        },
        {
          tipo: "SELECT",
          texto: 'Punto de encuentro al que acudes (Ciudad: Por ejemplo "Burgos")',
          codigo: "SEL1H",
          visible: true,
          required: true,
          placeholder: "-- Selecciona tu Centro --",
          options: [
            "La Rioja - Logroño", "La Rioja - Calahorra", "León", "Murcia", "Navarra - Pamplona (Rochapea)", "Navarra - Pamplona (Mendebaldea)", "Navarra - Tudela",
            "Navarra - Tafalla", "Madrid", "Mancomunidad Mejorada del Campo-Velilla (Madrid)", "Burgos", "Baleares - Palma de Mallorca", "Baleares - Mahon", "Baleares - Inca",
            "Baleares - Ibiza", "Santander", "San Andrés", "Torrelavega", "Araba - IRSE", "Valladolid", "A Coruña", "Tenerife", "Gran Canaria", "Lanzarote", "Ciudad Real",
            "Cuenca", "Guadalajara", "Toledo", "Alcázar de San Juan", "Talavera de la Reina", "Badajoz", "Mérida", "Cáceres", "Plasencia", "Aranda de Duero", "Miranda de Ebro", 
            "Ponferrada", "Palencia", "Segovia", "Soria", "Bilbao", "Donosti"]
        },
        {
          tipo: "TEXTO",
          texto: "<b>Comenzamos con el cuestionario. Es importante que contestes de la forma más sincera posible a todas las preguntas que se presentan. Por favor, procura no saltarte ninguna pregunta.</b>",
          codigo: "TXT3H",
          visible: true
        },
        {
          tipo: "TITULO",
          texto: "DATOS SOCIO DEMOGRÁFICOS",
          codigo: "TIT3H",
          visible: true
        },
        {
          tipo: "INPUT",
          texto: "1. FECHA NACIMIENTO",
          codigo: "INP3H",
          visible: true,
          required: true,
          maxSize: null,
          type: "DATE"
        },
        {
          tipo: "RADIO",
          texto: "2. SEXO",
          codigo: "RAD6H",
          visible: true,
          required: true,
          columnas: null,
          options: ["CHICO", "CHICA"]
        },
        {
          tipo: "INPUT",
          texto: "3. Nº HERMANOS (Sin incluirte)",
          codigo: "INP4H",
          visible: true,
          required: true,
          maxSize: null,
          type: "TEXT"
        },
        {
          tipo: "RADIO",
          texto: "4. ¿CON QUÉ PROGENITOR CONVIVES A DIARIO?",
          codigo: "RAD7H",
          visible: true,
          required: true,
          columnas: null,
          options: ["PADRE", "MADRE", "AMBOS POR IGUAL", "OTROS"]
        },
        {
          tipo: "RADIO",
          texto: "5. ¿ CON QUÉ FRECUENCIA VES AL PROGENITOR CON QUÉN NO CONVIVES A DIARIO?",
          codigo: "RAD8H",
          visible: true,
          required: true,
          columnas: null,
          options: ["TODOS LOS DIAS", "VARIOS DIAS A LA SEMANA", "UNA VEZ A LA SEMANA", "CADA DOS SEMANAS", "CADA MES", "CON MENOS FRECUENCIA", "LOS DOS POR IGUAL"]
        }
      ],
      //Pagina 3
      [
        {
          tipo: "TITULO",
          texto: "DIVORCIO Y FAMILIA",
          codigo: "TIT4H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Con qué frecuencia se producen estas situaciones en tu vida diaria.</b></p>" +
          "<p><b>0 = Nunca 1 = Al menos una vez al mes 2 = Al menos una vez a la semana 3 = Casi a diario 4 = Diariamente</b></p>",
          codigo: "TXT4H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Que no confíe en mí.",
          codigo: "RAD9H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "RADIO",
          texto: "2. Que intente decirme cómo vivir mi vida.",
          codigo: "RAD10H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "RADIO",
          texto: "3. Que sea estricto/a.",
          codigo: "RAD11H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "RADIO",
          texto: "4. Tener que mentirle.",
          codigo: "RAD12H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "RADIO",
          texto: "5. Preocuparme porque se entere de algo.",
          codigo: "RAD13H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "RADIO",
          texto: "6. Que no escuche mi opinión.",
          codigo: "RAD14H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "RADIO",
          texto: '7. Ser "menospreciado/a" por un miembro de la familia.',
          codigo: "RAD15H",
          visible: true,
          required: true,
          columnas: ["MADRE", "PADRE"],
          options: ["0","1","2","3","4"],
          repite: false,
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Por favor, indica tu grado de acuerdo sobre las siguientes frases 1= Totalmente en desacuerdo, 2= En desacuerdo; 3= Ni desacuerdo ni de acuerdo; 4= de acuerdo; 5= Totalmente de acuerdo</b></p>",
          codigo: "TXT5H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1.     Desde el divorcio mis padres se han peleado o criticado mutuamente cuando nosotros estábamos alrededor',
          codigo: "RAD16H",
          visible: true,
          required: true,
          columnas: null,
          options: ["1","2","3","4","5"]
        },
        {
          tipo: "RADIO",
          texto: '2.     Desde el divorcio mis padres se han gritado el uno al otro',
          codigo: "RAD17H",
          visible: true,
          required: true,
          columnas: null,
          options: ["1","2","3","4","5"]
        },
        {
          tipo: "RADIO",
          texto: '3.     Desde el divorcio mis padres han discutido sobre cómo educarnos',
          codigo: "RAD18H",
          visible: true,
          required: true,
          columnas: null,
          options: ["1","2","3","4","5"]
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Por favor, indica tu grado de acuerdo respecto a las siguientes afirmaciones. ESCALA: 0= Completamente en desacuerdo 1= Un poco en desacuerdo 2=Un poco de acuerdo 3=Completamente de acuerdo</b></p>",
          codigo: "TXT6H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1. Estoy contento de formar parte de mi familia porque hay más cosas positivas que negativas.',
          codigo: "RAD19H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '2. Cuando pasa algo malo en mi familia, me gustaría vivir en una familia diferente.',
          codigo: "RAD20H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '3. No sé por qué aguanto todas las veces que mi familia me enfada',
          codigo: "RAD21H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '4. Siento que puedo contar con mi familia para que me ayude y me aconseje cuando lo necesito',
          codigo: "RAD22H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '5. Las cosas que pasan en mi familia no parecen tener ningún sentido.',
          codigo: "RAD23H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '6. Cuando tengo desacuerdos con los miembros de mi familia, no vale la pena tratar de entender su punto de vista',
          codigo: "RAD24H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '7. Vale la pena preocuparse por los miembros de la familia, aun cuando las cosas van mal',
          codigo: "RAD25H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '8. Cuando pienso en los problemas en mi familia, siento que las cosas se resolverán al final.',
          codigo: "RAD26H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '9. Cuando estoy enfadado, no hay nadie en mi familia que pueda hacerme sentir mejor.',
          codigo: "RAD27H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '10. Estoy orgulloso(a) de mi familia.',
          codigo: "RAD28H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '11. Creo que los miembros de mi familia estarán cerca para ayudarme en el futuro.',
          codigo: "RAD29H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '12. Cuando pasa algo malo en mi familia, me apetece salir huyendo.',
          codigo: "RAD30H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        }
      ],
      //Pagina 4
      [
        {
          tipo: "TITULO",
          texto: "BIENESTAR EMOCIONAL",
          codigo: "TIT5H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Marca el número que mejor refleje el grado de acuerdo con cada una de las frases que se presentan a continuación ESCALA:</b></p>" +
          "<p><b> 0= Completamente falso 1= Falso 2=Verdadero 3= Completamente Verdadero </b></p>",
          codigo: "TXT7H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1. No me importa a quién lastimo para conseguir lo que quiero.',
          codigo: "RAD31H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '2. Me siento mal o culpable cuando hago algo mal.',
          codigo: "RAD32H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '3. No muestro mis emociones a los/as demás.',
          codigo: "RAD33H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '4. Me preocupan los sentimientos de los demás.',
          codigo: "RAD34H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '5. No me importa si me meto en problemas.',
          codigo: "RAD35H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '6. No me preocupa hacer las cosas bien.',
          codigo: "RAD36H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '7. A los/as demás les parezco muy frío/a e indiferente.',
          codigo: "RAD37H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '8. Pido disculpas a las personas que hago daño.',
          codigo: "RAD38H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '9. Trato de no herir los sentimientos de los/as demás.',
          codigo: "RAD39H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '10. No siento remordimientos cuando hago algo malo.',
          codigo: "RAD40H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '11. Los sentimientos de los/as demás no son importantes para mí.',
          codigo: "RAD41H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '12. Hago cosas para hacer que los/as demás se sientan bien.',
          codigo: "RAD42H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>¿Has vivido algunas de las siguientes situaciones en los últimos dos meses? ESCALA: 0= Nunca 1=A veces 2= A menudo 3= Siempre</b></p>",
          codigo: "TXT8H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1. Cuando estoy enfadado/a con alguien, a veces cotilleo sobre él/ellos',
          codigo: "RAD43H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '2. Cuando alguien me hace algo malo, difundo rumores y mentiras sobre él/ellos o ella/ellas',
          codigo: "RAD44H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '3. Amenazo a los demás cuando me hacen algo malo',
          codigo: "RAD45H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '4. Hago daño a los/as demás cuando estoy enfadado/a con ellos/as',
          codigo: "RAD46H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '5. Cuando los/as demás me enfadan, escribo notas hirientes sobre ellos/as y las hago circular',
          codigo: "RAD47H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '6. Si otros/as me enfadan, les hago daño',
          codigo: "RAD48H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '7. Cuando estoy enfadado/a con los/as demás, intento hacer que queden mal/ intento que los/as demás tengan una imagen negativa de ellos/as',
          codigo: "RAD49H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '8. Cuando me enfado con otras personas, trato de que dejarles sin amigos/as.',
          codigo: "RAD50H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '9. Si me enfado, lastimaré a alguien',
          codigo: "RAD51H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        },
        {
          tipo: "RADIO",
          texto: '10. Me he metido en peleas, incluso por pequeños insultos de otros/as',
          codigo: "RAD52H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3"]
        }
      ],
      //Pagina 5
      [
        {
          tipo: "TITULO",
          texto: "BIENESTAR FÍSICO",
          codigo: "TIT6H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Durante los últimos treinta días, ¿has presentado alguno de los siguientes síntomas? ¿Si presentaste algún síntoma, acudiste al médico?? ESCALA: 0= No 1=Si, pero no acudí al medico 2= Sí y acudí a médico</b></p>",
          codigo: "TXT9H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1. Trastorno estomacal o nausea',
          codigo: "RAD53H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '2. Dolor de espalda',
          codigo: "RAD54H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '3. Problemas de sueño',
          codigo: "RAD55H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '4. Erupción cutánea',
          codigo: "RAD56H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '5. Respiración breve',
          codigo: "RAD57H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '6. Dolor en el pecho',
          codigo: "RAD58H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '7. Dolor de cabeza',
          codigo: "RAD59H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '8. Fiebre',
          codigo: "RAD60H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '9. Indigestión',
          codigo: "RAD61H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '10. Dolor de ojos (Tensión ocular)',
          codigo: "RAD62H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '11. Diarrea',
          codigo: "RAD63H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '12. Calambres estomacales (No menstrual)',
          codigo: "RAD64H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '13. Estreñimiento',
          codigo: "RAD65H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '14. Latidos cardíacos sin ejercicio',
          codigo: "RAD66H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '15. Infección',
          codigo: "RAD67H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '16. Pérdida de apetito',
          codigo: "RAD68H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '17. Mareos',
          codigo: "RAD69H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '18. Cansancio o fatiga',
          codigo: "RAD70H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Señala la opción que describa cómo te sientes.</b></p>" + 
          "<p><b> ESCALA: 0= Nunca 1=A veces o 2= Siempre </p></b>",
          codigo: "TXT10H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1. Estoy triste',
          codigo: "RAD71H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '2. Las cosas me salen mal',
          codigo: "RAD72H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '3. No hago bien las cosas',
          codigo: "RAD73H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '4. No me gusto mucho',
          codigo: "RAD74H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '5. Lloro',
          codigo: "RAD75H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '6. Me preocupo',
          codigo: "RAD76H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '7. Soy feo/a',
          codigo: "RAD77H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '8. Estoy solo/a',
          codigo: "RAD78H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '9. No tengo amigos/as',
          codigo: "RAD79H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "RADIO",
          texto: '10. No me quieren',
          codigo: "RAD80H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2"]
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>Marca el número que mejor refleje tus sentimientos en relación con cada una de las frases que se presentan a continuación.ESCALA: 0= Nunca 1= Casi nunca 2= A veces 3=Casi Siempre 4= Siempre</b></p>",
          codigo: "TXT11H",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: '1. Hay cosas que me preocupan.',
          codigo: "RAD81H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '2. Me preocupa separarme de mis padres.',
          codigo: "RAD82H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '3. Temo hacer una tontería delante de la gente.',
          codigo: "RAD83H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '4. Me preocupa hacer mal mis deberes escolares.',
          codigo: "RAD84H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '5. Tengo problemas cuando voy al colegio porque me siento nervioso/a o asustado/a.',
          codigo: "RAD85H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '6. De pronto, y sin razón, me pongo a temblar.',
          codigo: "RAD86H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '7. Me preocupa que me ocurra algo malo.',
          codigo: "RAD87H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        },
        {
          tipo: "RADIO",
          texto: '8. De repente: me siento muy asustado/a sin que haya razón para ello.',
          codigo: "RAD88H",
          visible: true,
          required: true,
          columnas: null,
          options: ["0","1","2","3","4"]
        }
      ],
      //Pagina 6
      [
        {
          tipo: "TITULO",
          texto: "ENVÍO",
          codigo: "TIT7H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: '<p><b>POR FAVOR, NO OLVIDES PINCHAR EN EL BOTÓN DE "ENVIAR DATOS” PARA PODER CERRAR EL CUESTIONARIO Y QUE TUS RESPUESTAS QUEDEN REGISTRADAS.</b></p>',
          codigo: "TXT12H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>EN LOS PRÓXIMO DÍAS TE HAREMOS LLEGAR LA TARJETA REGALO DE AMAZON POR VALOR DE 10 EUROS A TRAVÉS DEL MEDIO DE CONTACTO QUE NOS HA FACILITADO AL INICIO DEL CUESTIONARIO (CORREO ELECTRÓNICO O TELÉFONO)</b></p>",
          codigo: "TXT13H",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p><b>MUCHAS GRACIAS POR TU PARTICIPACIÓN</b></p>",
          codigo: "TXT14H",
          visible: true
        },
      ]
    ]
  }
  cuestionarioP = {
    nombre: "PROTOCOLO DE EVALUACIÓN - ADULTOS",
    descripcion: "",
    fecha: null,
    url: null,
    password: null,
    activo: true,
    preguntas: [
      [   // Página 1
        {
          tipo: "TITULO",
          texto: "CONSENTIMIENTO INFORMADO",
          codigo: "TIT1",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>El presente cuestionario tiene como objetivo conocer con más profundidad las preocupaciones y dificultades de los usuarios de los Puntos de Encuentro Familiar con el fin de poder darles una respuesta más adecuada a sus necesidades. Es importante que tanto usted como uno de sus hijos/as (si tiene 12 años o más) respondan a un cuestionario, lo que les llevará aproximadamente 20 minutos. En el propio centro se invitará a participar a los dos progenitores.</p>" +
                  "<p>Los cuestionarios son confidenciales y, si bien se le pide más adelante que indique su dirección de correo electrónico (email), esto será exclusivamente para poder enviarle recordatorios futuros cuando se acerque el momento de que vuelva a responder a este cuestionario (se repetirá solo una segunda vez ). Este proceso se realizará de forma automatizada. También nos servirá para agradecerle, con un bono-regalo de 10 euros, su participación cada vez que responda al cuestionario. Los datos incorporados corresponderían al total de participantes y no de manera individual, manteniendo de este modo el anonimato del participante. Es por eso que no se emitirán informes a nivel individual, únicamente grupal, pues se mantendrá en todo momento el anonimato del participante.</p>" +
                  "<p>Los datos serán enviados directamente, sin conocimiento previo por parte de los profesionales del PEF, a investigadores vinculados a la Universidad de Deusto, quienes no conocen a los usuarios del centro y tratarán sus datos con la mayor confidencialidad posible. Esta confidencialidad sólo podrá romperse como medida de protección cuando exista certeza de riesgo de daño para usted o para las personas que le rodean.</p>" +
                  "<p>Por tanto, responda con la máxima sinceridad posible. Si usted tiene alguna duda al responder al cuestionario, puede preguntarle a la persona que le ha atendido en el Punto de Encuentro Familiar.</p>" +
                  "<p>Una vez que usted haya leído la información previa y aclarado las dudas que tenga puede, si lo desea, proceder a participar, siempre con la posibilidad de interrumpir su participación en el momento en que lo desee.</p>" +
                  "<p>Si deseas obtener más información sobre el estudio o realizar cualquier consulta, puedes contactar con en el equipo de investigación a través de la siguiente dirección de correo electrónico: <a href='mailto:pefs.familia@deusto.es'>pefs.familia@deusto.es</a>. También, si deseas conocer más sobre la política de protección de datos, puedes pinchar <a href='https://deustofamilypsych.deusto.es/proteccion-de-datos/' target='_blank'>AQUÍ</a>. Si no, puedes comenzar.</p>" +
                  "<p><b>NOTA: En el caso de que uno de sus hijos/as responda al cuestionario, las respuestas del hijo/a serán tratadas igualmente con confidencialidad, no teniendo acceso a su contenido ninguno de los dos progenitores ni ningún otro profesional.</b></p>",
          codigo: "TXT1",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "Me han explicado para qué voy a rellenar los cuestionarios",
          codigo: "RAD1",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Sé que puedo hacer preguntas sobre el estudio",
          codigo: "RAD2",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Sé que puedo pedir información sobre los resultados generales de este estudio cuando éste haya concluido",
          codigo: "RAD3",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Conozco el objetivo del estudio",
          codigo: "RAD4",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Acepto participar en este estudio",
          codigo: "RAD5",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "Estoy de acuerdo en que mi hijo/a, que tiene 12 años o más, participe en el estudio y que, por ello, se le de una compensación de 10 Euros a través de una tarjeta regalo de Amazon",
          codigo: "RAD6",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        }
      ],
      [   // Página 2
        {
          tipo: "TITULO",
          texto: "DATOS SOCIO DEMOGRÁFICOS",
          codigo: "TIT2",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<b>En primer lugar, le solicitamos una serie de datos para poder: 1) Enlazar sus respuestas con las del hijo/a que haya contestado a los cuestionarios sin pedir, para ello, datos identificativos, 2) Ponernos en contacto con usted para el recordatorio del siguiente momento de evaluación</b>",
          codigo: "TXT2",
          visible: true
        },
        {
          tipo: "INPUT",
          texto: "Código (últimos tres dígitos y letra de su DNI)",
          codigo: "INP1",
          visible: true,
          required: true,
          maxSize: 4,
          placeholder: "Ej. 123D",
          type: "TEXT"
        },
        {
          tipo: "RADIO",
          texto: "Esta es:",
          codigo: "RAD7",
          visible: true,
          required: true,
          columnas: null,
          options: ["La primera vez que relleno el cuestionario", "La segunda vez que relleno el cuestionario"]
        },
        {
          tipo: "INPUT",
          texto: "Correo electrónico (si no tiene, puede indicar su número de teléfono al que enviaremos un mensaje recordatorio). A través del medio elegido (correo electrónico o teléfono) una vez concluido el cuestionario le haremos llegar la tarjeta regalo de Amazon por valor de 10 Euros)",
          codigo: "INP2",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "TEXT"
        },
        {
          tipo: "SELECT",
          texto: "Centro de procedencia:",
          codigo: "SEL1",
          visible: true,
          required: true,
          placeholder: "-- Selecciona tu Centro --",
          options: [
            "La Rioja - Logroño", "La Rioja - Calahorra", "León", "Murcia", "Navarra - Pamplona (Rochapea)", "Navarra - Pamplona (Mendebaldea)", "Navarra - Tudela",
            "Navarra - Tafalla", "Madrid", "Mancomunidad Mejorada del Campo-Velilla (Madrid)", "Burgos", "Baleares - Palma de Mallorca", "Baleares - Mahon", "Baleares - Inca",
            "Baleares - Ibiza", "Santander", "San Andrés", "Torrelavega", "Araba - IRSE", "Valladolid", "A Coruña", "Tenerife", "Gran Canaria", "Lanzarote", "Ciudad Real",
            "Cuenca", "Guadalajara", "Toledo", "Alcázar de San Juan", "Talavera de la Reina", "Badajoz", "Mérida", "Cáceres", "Plasencia", "Aranda de Duero", "Miranda de Ebro", 
            "Ponferrada", "Palencia", "Segovia", "Soria", "Bilbao", "Donosti"]
        },
        {
          tipo: "TEXTO",
          texto: "<b>Comenzamos con el cuestionario. Es importante que conteste de la forma más sincera posible a todas las preguntas que se le presentan. Por favor, procure no saltarse ninguna pregunta.</b>",
          codigo: "TXT3",
          visible: true
        },
        {
          tipo: "INPUT",
          texto: "1. ¿Cuál es su fecha de nacimiento?",
          codigo: "INP3",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "DATE"
        },
        {
          tipo: "INPUT",
          texto: "2. Número de hijos/as",
          codigo: "INP4",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "RADIO",
          texto: "3. Usted es el/la",
          codigo: "RAD8",
          visible: true,
          required: true,
          columnas: null,
          options: ["PADRE", "MADRE", "OTRO"]
        },
        {
          tipo: "RADIO",
          texto: "4. Usted tiene",
          codigo: "RAD9",
          visible: true,
          required: true,
          columnas: null,
          options: ["CUSTODIA COMPARTIDA", "CUSTODIA EXCLUSIVA", "NO CUSTODIO"]
        },
        {
          tipo: "RADIO",
          texto: "5. Estado civil (Vínculo legal)",
          codigo: "RAD10",
          visible: true,
          required: true,
          columnas: null,
          options: ["SOLTERO", "SEPARADO", "DIVORCIADO", "CASADO", "NO PRIMER MATRIMONIO", "PAREJA DE HECHO", "OTRO"]
        },
        {
          tipo: "RADIO",
          texto: "6. ¿Convives en pareja?",
          codigo: "RAD11",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "TEXTO",
          texto: "7. Su familia está formada:",
          codigo: "TXT3",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "7.1. Hijos/as biológicos",
          codigo: "RAD12",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "7.2. Hijos/as adoptivos",
          codigo: "RAD13",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "7.3. Hijos/as de nueva pareja",
          codigo: "RAD14",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "8. Indique los estudios cursados",
          codigo: "RAD15",
          visible: true,
          required: true,
          columnas: null,
          options: ["MENOS DE ESTUDIOS PRIMARIOS", "ESTUDIOS PRIMARIOS", "BACHILLERATO", "BUP O FP", "CARRERA MEDIA", "CARRERA SUPERIOR", "MÁSTER O DOCTORADO"]
        },
        {
          tipo: "RADIO",
          texto: "9. ¿Cuál es su situación laboral?",
          codigo: "RAD16",
          visible: true,
          required: true,
          columnas: null,
          options: ["PARO", "ACTIVO", "JUBILADO", "INCAPACIDAD", "BAJA LABORAL", "OTRO"]
        },
        {
          tipo: "RADIO",
          texto: "10. ¿Su jornada laboral afecta a la conciliación familiar?",
          codigo: "RAD17",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "RADIO",
          texto: "11. ¿Su situación familiar afecta a la conciliación familiar?",
          codigo: "RAD18",
          visible: true,
          required: true,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "CHECKBOX",
          texto: "12. Indique si ha vivido alguna de estas situaciones en su infancia (puede seleccionar más de una opción)",
          codigo: "CHK1",
          visible: true,
          required: true,
          options: [
            "Consumo de drogas (drogas, psicofármacos, alcohol…) del padre/madre",
            "Consumo de drogas (drogas, psicofármacos, alcohol…) de algún hermano/a",
            "Pérdida de trabajo del padre/madre",
            "Violencia entre padre/madre",
            "Violencia hacia los hijos/as",
            "Muerte de un familiar cercano",
            "Otros",
            "No he vivido ninguna de estas situaciones durante mi infancia"
          ]
        },
        {
          tipo: "CHECKBOX",
          texto: "13. Indica si estás viviendo alguna de estas situaciones en los últimos 6 meses",
          codigo: "CHK2",
          visible: true,
          required: true,
          options: [
            "Consumo propio de (drogas, psicofármacos, alcohol…)",
            "Consumo de algún hijo/a de drogas (drogas, psicofármacos, alcohol…)",
            "Pérdida de trabajo del padre/madre",
            "Violencia entre padre/madre",
            "Violencia hacia los hijos/as",
            "Muerte de un familiar cercano",
            "Otros",
            "No he vivido ninguna de estas situaciones durante mi infancia"
          ]
        },
        {
          tipo: "RADIO",
          texto: "14. ¿Cuánto tiempo ha transcurrido desde la separación/divorcio?",
          codigo: "RAD19",
          visible: true,
          required: true,
          columnas: null,
          options: ["No nos hemos separado todavía", "Solo hemos presentado los papeles", "Menos de dos meses",
                    "De dos a seis meses", "De seis meses a un año", "De uno a dos años",
                    "De dos a tres años", "Más de tres años"]
        },
        {
          tipo: "INPUT",
          texto: "15. ¿Cuánto tiempo duró su relación previa al divorcio/separación? (nº de años)",
          codigo: "INP5",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "INPUT",
          texto: "16. Coste aproximado de los abogados del último año:",
          codigo: "INP6",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "INPUT",
          texto: "17. Coste del procurador del último año: (si no se ha hecho uso indicar con un cero)",
          codigo: "INP7",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "RADIO",
          texto: "18. El proceso fue/está siendo:",
          codigo: "RAD20",
          visible: true,
          required: true,
          columnas: null,
          options: ["Con mediación", "Sin mediación"]
        },
        {
          tipo: "INPUT",
          texto: "19. ¿Cuántas veces ha vuelto a los juzgados como resultado del conflicto con su expareja?",
          codigo: "INP8",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "RADIO",
          texto: "20. ¿Con qué frecuencia ve usted a su hijo/a?",
          codigo: "RAD21",
          visible: true,
          required: true,
          columnas: null,
          options: ["Todos los días", "Varios días a la semana", "Una vez a la semana", "Cada dos días", "Cada mes", "Con menos frecuencia"]
        },
        {
          tipo: "RADIO",
          texto: "21. ¿Y su excónyuge?",
          codigo: "RAD22",
          visible: true,
          required: true,
          columnas: null,
          options: ["Todos los días", "Varios días a la semana", "Una vez a la semana", "Cada dos días", "Cada mes", "Con menos frecuencia"]
        },
        {
          tipo: "RADIO",
          texto: "22. Tras la separación, ¿Cómo son las relaciones entre usted y su excónyuge?",
          codigo: "RAD23",
          visible: true,
          required: true,
          columnas: null,
          options: ["Inexistentes, no cruzan palabra ni por teléfono", "Muy escasas, y casi siempre hostiles, con insultos y reproches",
                    "Restringidas, intercambian la información mínima sobre los niños (problemas escolares, enfermedades...)",
                    "Fluidas en relación con los niños y corteses en cuanto a ustedes", "Variables y/o ambiguas"]
        },
        {
          tipo: "RADIO",
          texto: "23. ¿Ha habido violencia física (empujar, abofetear, golpear, patear, pegar, etc.) en relación con su pareja?",
          codigo: "RAD24",
          visible: true,
          required: true,
          columnas: null,
          options: ["Sí, actualmente", "Sí, pero no actualmente", "No"]
        },
        {
          tipo: "RADIO",
          texto: "24. ¿Ha habido violencia psicológica (humillaciones, insultos, críticas, ridiculizar, gritos, controlar actividad en relaciones sociales, controlar amistades, etc.) en relación con su pareja?",
          codigo: "RAD25",
          visible: true,
          required: true,
          columnas: null,
          options: ["Sí, actualmente", "Sí, pero no actualmente", "No"]
        },
        {
          tipo: "CHECKBOX",
          texto: "25. ¿Alguno de sus hijos/as ha sido diagnosticado/a de alguna patología física/psicológica? (Puede seleccionar más de una opción)",
          codigo: "CHK3",
          visible: true,
          required: true,
          options: [
            "Problemas respiratorios",
            "Gastrointestinales",
            "Oseos",
            "Cardiopatías",
            "Depresión",
            "Ansiedad",
            "TDAH",
            "Mi hijo/a no ha sido diagnosticado de ninguna patología",
            "Otra"
          ]
        },
        {
          tipo: "INPUT",
          texto: "26. ¿Y has acudido a consulta por alguna de estas dolencias?",
          codigo: "INP9",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "TEXT"
        },
        {
          tipo: "RADIO",
          texto: "27. El centro al que ha acudido es:",
          codigo: "RAD26",
          visible: true,
          required: true,
          columnas: null,
          options: ["Privado", "Público (Seguridad social)", "No ha acudido a ningún centro"]
        },
        {
          tipo: "INPUT",
          texto: "28. En caso de haber acudido a un centro privado: Coste del psicólogo infantil del último año (si no se ha hehco uso indicar con un cero)",
          codigo: "INP10",
          visible: true,
          required: false,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "RADIO",
          texto: "29. Tu caso ha sido derivado por el juzgado de:",
          codigo: "RAD27",
          visible: true,
          required: true,
          columnas: null,
          options: ["Familia", "Violencia"]
        },
        {
          tipo: "INPUT",
          texto: "30. ¿Hace cuánto tiempo acude al Punto de Encuentro Familiar (PEF)?",
          codigo: "INP11",
          visible: true,
          required: true,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "CHECKBOX",
          texto: "31. ¿Qué tipo de intervención recibe en el PEF? Puede indicar más de una:",
          codigo: "CHK4",
          visible: true,
          required: true,
          options: [
            "Realizar el intercambio con mi ex.",
            "Mantener visitas supervisadas con mi hijo/a.",
            "Recibir orientación y asesoramiento.",
            "Participar en alguna intervención."
          ]
        },
        {
          tipo: "TEXTO",
          texto: "<b>Debido a su divorcio, seguramente ha mantenido o sigue manteniendo relación con algún abogado/a. Pensando en esa relación, indique en qué medida está de acuerdo con las siguientes frases:<br>ESCALA: 0 = Totalmente en desacuerdo. 1 = En desacuerdo. 2 = Ni en desacuerdo ni de acuerdo. 3 = De acuerdo. 4 = Totalmente de acuerdo</b>",
          codigo: "TXT5",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Lo que planteaba el abogado me ayudó / me ha ayudado a solucionar nuestros problemas.",
          codigo: "RAD28",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "2. El abogado me ha comprendido.",
          codigo: "RAD29",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "3. Las entrevistas con el abogado me han servido para entender lo que necesitaba.",
          codigo: "RAD30",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "4. Tanto mi ex-pareja como yo, desde un punto de vista legal, hemos querido conseguir lo mejor para nuestra familia y resolver nuestros problemas.",
          codigo: "RAD31",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "5. Siento que he estado trabajando en equipo con el abogado.",
          codigo: "RAD32",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "6. El abogado ha estado haciendo todo lo posible por ayudarme.",
          codigo: "RAD33",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "7. Me he sentido cómodo/a y relajado/a con el abogado.",
          codigo: "RAD34",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "8. Mi ex-pareja y yo valoramos el esfuerzo y el tiempo que ambos hemos estado invirtiendo para llegar a un acuerdo.",
          codigo: "RAD35",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "9. He entendido el sentido del proceder del abogado.",
          codigo: "RAD36",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "10. Considero que el abogado se ha convertido en una persona importante para mí.",
          codigo: "RAD37",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "11. Ha habido algún tema del que no me he atrevido a hablar con el abogado.",
          codigo: "RAD38",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "12. Tanto mi ex-pareja como yo hemos intentado que todos los miembros de la familia tengamos lo que necesitamos.",
          codigo: "RAD39",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "13. Ha intentado reducir el conflicto y promover la negociación.",
          codigo: "RAD40",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "14. Ha evitado conflictos con el objetivo de salvaguardar el bienestar de la familia.",
          codigo: "RAD41",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "15. Ha tenido como objetivo el mutuo acuerdo.",
          codigo: "RAD42",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "16. Ha contribuido a que se tengan en cuenta los intereses de todos los miembros de la familia.",
          codigo: "RAD43",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "17. Tenía formación en mediación o trabajaba en colaboración con un psicólogo.",
          codigo: "RAD44",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "18. Me ha orientado para que pida un informe psicológico que favorezca mis intereses sobre los de mi pareja.",
          codigo: "RAD45",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
      ],
      [   // Página 3
        {
          tipo: "TITULO",
          texto: "DIVORCIO Y FAMILIA",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>Debido a su divorcio, seguramente ha mantenido o sigue manteniendo relación con algún abogado/a. Pensando en esa relación, indique en qué medida está de acuerdo con las siguientes frases:</p>" +
                  "<p>Indique su grado de acuerdo con la siguientes afirmaciones ESCALA: 0 = Totalmente en desacuerdo. 1 = En desacuerdo. 2 = Ni en desacuerdo ni de acuerdo. 3 = De acuerdo. 4 = Totalmente de acuerdo</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Desde el divorcio, mi ex esposo/a y yo nos hemos peleado o criticado mutuamente cuando los niños/as estaban alrededor",
          codigo: "RAD46",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "2. Desde el divorcio, mi exesposo/a y yo nos hemos gritado el uno/a al otro/a",
          codigo: "RAD47",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "3. Desde el divorcio, mi exesposo/a y yo hemos discutido sobre cómo educar a los niños/as",
          codigo: "RAD48",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "4. Cuando yo he necesitado hacer un cambio en los acuerdos de visita, mi ex pareja ha cedido para que pudiera adaptarme mejor",
          codigo: "RAD49",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "5. Desde el divorcio, mi exesposo/a ha cumplido con su parte en cuanto a la crianza y el mantenimiento económico de los niños/as",
          codigo: "RAD50",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "6. Desde el divorcio, mi ex y yo estamos de acuerdo sobre la mayoría de las decisiones que afectan a nuestros hijos (escolares, médicas…)",
          codigo: "RAD51",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "7. Mi ex pareja es una ayuda para mí en la crianza de nuestros hijos/as",
          codigo: "RAD52",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "8. Cuando necesito ayuda con respecto a mis hijos/as, se la pido a su padre/madre",
          codigo: "RAD53",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "BLANK",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>Indique con qué frecuencia suceden los siguientes hechos. ESCALA: 0 = Nunca. 1 = Casi nunca. 2 = Alguna vez. 3 = A veces. 4 = Muchas veces</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Cuando mi hijo/a esta enfadado/a por algún motivo, me insulta o amenaza",
          codigo: "RAD54",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "2. Cuando mi hijo/a esta enfadado/a por algún motivo, insulta o amenaza al otro progenitor",
          codigo: "RAD55",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "3. En alguna discusión o pelea, mi hijo/a me pega o me da un empujón",
          codigo: "RAD56",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "4. En alguna discusión o pelea, mi hijo/a pega o empuja a su otro progenitor",
          codigo: "RAD57",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "5. Mi hijo/a me chantajea para conseguir lo que quiere",
          codigo: "RAD58",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "6. Mi hijo/a chantajea a su otro progenitor para conseguir lo que quiere",
          codigo: "RAD59",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "7. Cuando me enfado con mi hijo/a le pego",
          codigo: "RAD60",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "8. Cuando su otro progenitor se enfada con mi hijo/a le pega",
          codigo: "RAD61",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "9. Cuando mi expareja y yo estamos enfadados nos agredimos físicamente",
          codigo: "RAD62",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "En caso de que haya ocurrido alguna agresión física entre tu expareja y tú, ¿quién de los dos agrede?",
          codigo: "RAD63",
          visible: true,
          required: true,
          columnas: null,
          options: ["USTED", "SU EXPAREJA", "AMBOS", "NO HA HABIDO AGRESIONES"]
        },
      ],
      [   // Página 4
        {
          tipo: "TITULO",
          texto: "BIENESTAR EMOCIONAL",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>Señala la opción que describa como se siente: ESCALA: 0 = Nunca. 1 = De vez en cuando. 2 = Gran parte del dia. 3 = Casi todo el dia</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Me siento tenso/a o nervioso/a",
          codigo: "RAD64",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "2. Sigo disfrutando con las mismas cosas de siempre",
          codigo: "RAD65",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "3. Siento una especie de temor como si algo malo fuera a suceder",
          codigo: "RAD66",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "4. Soy capaz de reírme y ver el lado gracioso de las cosas",
          codigo: "RAD67",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "5. Tengo la cabeza llena de preocupaciones",
          codigo: "RAD68",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "6. Me siento alegre",
          codigo: "RAD69",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "7. Soy capaz de permanecer sentado/a tranquilo/a y relajadamente",
          codigo: "RAD70",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "8. Me siento lento/a y torpe",
          codigo: "RAD71",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "9. Experimento una desagradable sensación de “nervios y hormigueos en el estómago”",
          codigo: "RAD72",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "10. He perdido el interés por mi aspecto personal",
          codigo: "RAD73",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "11. Me siento inquieto/a como si no pudiera parar de moverme",
          codigo: "RAD74",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "12. Tengo ilusión por las cosas",
          codigo: "RAD75",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "13. De repente experimento sensaciones de angustia o temor",
          codigo: "RAD76",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "14. Soy capaz de disfrutar con un buen libro o un buen programa de radio o de TV",
          codigo: "RAD77",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "BLANK",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>En una escala del 1 al 10, siendo 1 muy poco y 10 el máximo</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "En general en tu vida, ¿en qué grado te sientes solo/a?",
          codigo: "RAD78",
          visible: true,
          required: true,
          columnas: null,
          options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        },
      ],
      [   // Página 5
        {
          tipo: "TITULO",
          texto: "BIENESTAR FÍSICO",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>Indique si algo de lo siguiente le ha ocurrido en los últimos meses</p>" + 
                  "<p>ESCALA: 0 = No he tenido molestias. 1 = Si, pero no he acudido al médico. 2 = Si y acudí al médico</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Trastorno estomacal o nausea",
          codigo: "RAD79",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "2. Dolor de espalda",
          codigo: "RAD80",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "3. Problemas de sueño",
          codigo: "RAD81",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "4. Erupción cutánea",
          codigo: "RAD82",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "5. Respiración breve",
          codigo: "RAD83",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "6. Dolor en el pecho",
          codigo: "RAD84",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "7. Dolor de cabeza",
          codigo: "RAD85",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "8. Fiebre",
          codigo: "RAD86",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "9. Indigestión ácida o dispepsia",
          codigo: "RAD87",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "10. Tensión ocular",
          codigo: "RAD88",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "11. Diarrea",
          codigo: "RAD89",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "12. Calambres estomacales (No menstrual)",
          codigo: "RAD90",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "13. Estreñimiento",
          codigo: "RAD91",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "14. Latidos cardíacos sin ejercicio",
          codigo: "RAD92",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "15. Infección",
          codigo: "RAD93",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "16. Pérdida de apetito",
          codigo: "RAD94",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "17. Mareos",
          codigo: "RAD95",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "18. Cansancio o Fatiga",
          codigo: "RAD96",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
      ],
      [   // Página 6
        {
          tipo: "TITULO",
          texto: "TUS RECURSOS",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>En general, ESCALA 0 = Totalmente en desacuerdo. 1 = Neutral. 2 = Totalmente de acuerdo</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Normalmente, me las arreglo de una manera u otra",
          codigo: "RAD97",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "2. Me siento orgulloso/a de las cosas que he logrado",
          codigo: "RAD98",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "3. En general, me tomo las cosas con calma",
          codigo: "RAD99",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "4. Soy una persona con una adecuada autoestima",
          codigo: "RAD100",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "5. Siento que puedo manejar muchas situaciones a la vez",
          codigo: "RAD101",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "6. Soy resuelto/a y decidido/a",
          codigo: "RAD102",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "7. No me asusta sufrir dificultades porque ya las he experimentado en el pasado",
          codigo: "RAD103",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "8. Soy una persona disciplinada",
          codigo: "RAD104",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "9. Pongo interés en las cosas",
          codigo: "RAD105",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "10. Puedo encontrar, generalmente, algo sobre lo que reírme",
          codigo: "RAD106",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "11. La seguridad en mí mismo/a me ayuda en los momentos difíciles",
          codigo: "RAD107",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "12. En una emergencia, soy alguien en quien la gente puede confiar",
          codigo: "RAD108",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "13. Mi vida tiene sentido",
          codigo: "RAD109",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "14. Cuando estoy en una situación difícil, por lo general puedo encontrar una salida",
          codigo: "RAD110",
          visible: true,
          required: true,
          columnas: null,
          options: ["0", "1", "2"]
        },
      ],
      [   // Página 7
        {
          tipo: "TITULO",
          texto: "TUS RECURSOS",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>A continuación, se plantean algunas preguntas sobre los hijos/as. Solo tendrás que responderlas si tu hijo/as es mayor de 4 años. Si no es mayor de la edad indicada, por favor pase la página.</p>" + 
                  "<p>En el caso de tener más de un hijo/a mayor de 4 años, ¿sobre quién debes responder?<br>1. En primer lugar, si tu hijo/a tiene más de 12 años y también va a responder al cuestionario, piensa en él/ella.<br>2. Si ninguno de tus hijos/as participan, piensa tu hijo/a mayor.</p>",
          visible: true
        },
        {
          tipo: "INPUT",
          texto: "Edad del hijo/a sobre el que vas a contestar al cuestionario",
          codigo: "INP12",
          visible: true,
          required: false,
          maxSize: null,
          placeholder: null,
          type: "NUMBER"
        },
        {
          tipo: "INPUT",
          texto: "Fecha de nacimiento del hijo/a:",
          codigo: "INP13",
          visible: true,
          required: false,
          maxSize: null,
          placeholder: "DD/MM/AAAA",
          type: "DATE"
        },
        {
          tipo: "RADIO",
          texto: "Género del hijo/a que ha contestado al cuestionario",
          codigo: "RAD111",
          visible: true,
          required: false,
          columnas: null,
          options: ["MASCULINO", "FEMENINO"]
        },
        {
          tipo: "RADIO",
          texto: "¿Ha participado en el estudio rellenando el cuestionario?",
          codigo: "RAD112",
          visible: true,
          required: false,
          columnas: null,
          options: ["SI", "NO"]
        },
        {
          tipo: "TEXTO",
          texto: "<p>Las siguientes frases se centran en la relación entre padres e hijos en la familia. Indique con qué frecuencia lo que indican las frases es CORRECTO</p>" + 
                  "<p>ESCALA 0 = Nunca. 1 = Casi nunca. 2 = Alguna vez. 3 = A veces. 4 = Muchas veces</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Los/as niños/as que actúan tristes, por lo general, solo buscan que los adultos sientan pena por ellos/as",
          codigo: "RAD113",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "2. Es importante que mi hijo/a experimente enfado",
          codigo: "RAD114",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "3. Cuando mi hijo/a está triste, nos sentamos y hablamos sobre la tristeza",
          codigo: "RAD115",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "4. Los niños/as suelen actuar de manera triste para salirse con la suya.",
          codigo: "RAD116",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "5. Es importante que mi hijo/a experimente tristeza.",
          codigo: "RAD117",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "6. Es importante ayudar al niño/a a descubrir qué causó la ira que siente.",
          codigo: "RAD118",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "7. Cuando mi hijo/a está enfadado/a, no estoy muy seguro/a de qué es lo que quiere que yo haga.",
          codigo: "RAD119",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "8. Cuando mi hijo/a está triste, trato de ayudarlo/a a identificar qué es lo que le hace sentirse triste",
          codigo: "RAD120",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "9. Los niños/as tienen derecho a sentirse enfadados/as",
          codigo: "RAD121",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "10. No me importa lidiar con la tristeza de un niño/a, siempre y cuando no le dure demasiado.",
          codigo: "RAD122",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "11. Cuando mi hijo/a se pone triste, le advierto de que podría llevarle a desarrollar un mal carácter",
          codigo: "RAD123",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "12. La rabia de un niño/a es importante.",
          codigo: "RAD124",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "13. Cuando mi hijo/a se enfada, pienso, 'ojalá aprenda a seguir adelante a pesar de los problemas'.",
          codigo: "RAD125",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "14. Cuando mi hijo/a se enfada, mi objetivo es que se calme.",
          codigo: "RAD126",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "15. Cuando mi hijo/a está triste, trato de ayudarle a descubrir por qué está experimentando ese sentimiento.",
          codigo: "RAD127",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "16. Creo que es bueno que los/as niños/as se sientan enfadados/as a veces",
          codigo: "RAD128",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "17. Cuando mi hijo/a está triste, no estoy muy seguro/a de lo que quiere que haga.",
          codigo: "RAD129",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "18. Cuando mi hijo/a se enfada conmigo, pienso 'no quiero escuchar esto'.",
          codigo: "RAD130",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "19. Cuando mi hijo/a está enfadado/a, 'es hora de resolver un problema'",
          codigo: "RAD131",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "RADIO",
          texto: "20. Cuando mi hijo/a se enfada, pienso, '¿por qué no podrá aceptar las cosas como son?'.",
          codigo: "RAD132",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3", "4"]
        },
        {
          tipo: "TEXTO",
          texto: "<p>Por favor, piense en su hijo/a. Señale en qué medida cree que son CORRECTAS cada una de las siguientes frases:</p>" + 
                  "<p>ESCALA 0 = No es del todo cierto. 1 = Algo cierto. 2 = Muy cierto. 3 = Definitivamente cierto</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. No le preocupa hacer daño a alguien",
          codigo: "RAD133",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "2. Se siente mal o culpable cuando ha hecho algo mal",
          codigo: "RAD134",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "3. No muestra emociones.",
          codigo: "RAD135",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "4. Se preocupa por los sentimientos de los/as demás.",
          codigo: "RAD136",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "5. No le preocupa estar metido/a en problemas.",
          codigo: "RAD137",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "6. No le preocupa hacer las cosas bien",
          codigo: "RAD138",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "7. Se muestra muy frío/a e indiferente",
          codigo: "RAD139",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "8. Se disculpa ('dice que lo siente') con las personas a las que ha hecho daño",
          codigo: "RAD140",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "9. Trata de no herir los sentimientos de los/as demás",
          codigo: "RAD141",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "10. No muestra ningún remordimiento cuando ha hecho algo malo",
          codigo: "RAD142",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "11. Los sentimientos de los/as demás no son importantes para él o ella.",
          codigo: "RAD143",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "RADIO",
          texto: "12. Hace cosas para que los/as demás se sientan bien.",
          codigo: "RAD144",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2", "3"]
        },
        {
          tipo: "TEXTO",
          texto: "<p>Por favor, responda a las preguntas basándose en el comportamiento del niño/a durante los últimos seis meses o durante el presente curso escolar.</p>" + 
                  "<p>ESCALA 0 = No es cierto. 1 = Un tanto cierto. 2 = Absolutamente cierto</p>",
          visible: true
        },
        {
          tipo: "RADIO",
          texto: "1. Se queja con frecuencia de dolor de cabeza, de estómago o de náuseas",
          codigo: "RAD145",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "2. Es más bien solitario/a y tiende a jugar solo/a",
          codigo: "RAD146",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "3. Tiene muchas preocupaciones, a menudo parece inquieto/a o preocupado/a",
          codigo: "RAD147",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "4. Tiene por lo menos un/a buen/a amigo/a",
          codigo: "RAD148",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "5. Se siente a menudo infeliz, desanimado/a o lloroso/a",
          codigo: "RAD149",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "6. Por lo general cae bien a los otros niños/as",
          codigo: "RAD150",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "7. Es nervioso/a o dependiente ante nuevas situaciones, fácilmente pierde la confianza en sí mismo/a",
          codigo: "RAD151",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "8. Los otros niños/as se meten con él/ella o se burlan de él/ella",
          codigo: "RAD152",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "9. Se lleva mejor con adultos que con otros niños/as",
          codigo: "RAD153",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
        {
          tipo: "RADIO",
          texto: "10. Tiene muchos miedos, se asusta fácilmente",
          codigo: "RAD154",
          visible: true,
          required: false,
          columnas: null,
          options: ["0", "1", "2"]
        },
      ],
      [
        {
          tipo: "TITULO",
          texto: "ENVÍO",
          visible: true
        },
        {
          tipo: "TEXTO",
          texto: "<p>POR FAVOR, NO OLVIDE PINCHAR EN EL BOTÓN DE \"FINALIZAR\" PARA PODER CERRAR EL CUESTIONARIO Y QUE SUS RESPUESTAS QUEDEN REGISTRADAS.</p>" + 
                  "<p>EN LOS PRÓXIMOS DÍAS LE HAREMOS LLEGAR LA TARJETA REGALO DE AMAZON POR VALOR DE 10 EUROS A TRAVÉS DEL MEDIO DE CONTACTO QUE NOS HA FACILITADO AL INICIO DEL CUESTIONARIO (CORREO ELECTRÓNICO O TELÉFONO)</p>" +
                  "<p>MUCHAS GRACIAS</p>",
          visible: true
        },
      ]
    ]
  }

  cuestionario = null;

  loading = false;
  estado = "EN CURSO";
  pagina = 0;
  preguntasPagina = null;
  cuestID = null;

  respuestas = {};

  error = {}

  mismosTitulos = false;

  constructor(
    //private cuestionarioService: CuestionarioService,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.cuestID = params.get("id");
      if(this.cuestID && this.cuestID == "HIJOS") {
        this.cuestionario = this.cuestionarioH;
      } else {
        this.cuestionario = this.cuestionarioP;
      }

      this.loadPagina();  
    });
  }

  loadPagina() {
    this.filtrarColumnas();
    this.preguntasPagina = this.cuestionario.preguntas[this.pagina];
    for(let pregunta of this.preguntasPagina) {
      if(pregunta.required && !pregunta.texto.includes("g-color-red")) pregunta.texto += "<b class='g-color-red'> *</b>";
      if(!this.respuestas[pregunta.codigo] && !(pregunta.codigo.includes("TIT") || pregunta.codigo.includes("TXT"))) {
        this.respuestas[pregunta.codigo] = "";
      }
      if(pregunta.tipo == "CHECKBOX") {
        let index = 0;
        for(let option of pregunta.options) {
          if(!this.respuestas[pregunta.codigo + index]) this.respuestas[pregunta.codigo + index] = false;
          index++;
        }
      }
    }

    setTimeout(() => {
      KTUtil.scrollTop();
    }, 100);
  }

  volver() {
    if(this.pagina > 0) {
      this.pagina--;
      this.loadPagina();
    }
  }

  siguiente() {
    if(!this.hasErrors() && this.pagina + 1 < this.cuestionario.preguntas.length ) {
      this.pagina++;
      this.loadPagina();
    }
  }

  filtrarColumnas(){
    for(let i = 0; i< this.cuestionario.preguntas.length; i++){
      for(let j = 0 ; j< this.cuestionario.preguntas[i].length; j++){
        if(this.cuestionario.preguntas[i][j].columnas && this.cuestionario.preguntas[i][j-1].columnas && j!=0 && JSON.stringify(this.cuestionario.preguntas[i][j].columnas) === JSON.stringify(this.cuestionario.preguntas[i][j-1].columnas)){
          this.cuestionario.preguntas[i][j].repite = true;
          console.log(this.cuestionario.preguntas[i][j]);
        }
      }
    }
  }

  hasErrors(): Boolean {
    let errorCheck = false;
    for(let pregunta of this.preguntasPagina) {
      if(pregunta.required) {
        if(pregunta.tipo == "CHECKBOX") {
          let sel = false;
          let index = 0;
          for(let opt of pregunta.options) {
            if(this.respuestas[pregunta.codigo + index]) sel = true;
            index++;
          }
          if(!sel) {
            this.error[pregunta.codigo] = true;
            errorCheck = true; 
          } else {
            this.error[pregunta.codigo] = false;
          }
        } 
        else if(pregunta.tipo == "RADIO" && pregunta.columnas){
          for(let col of pregunta.columnas){
            if(!this.respuestas[pregunta.codigo + col] || this.respuestas[pregunta.codigo + col] == ""){
              this.error[pregunta.codigo + col] = true;
              errorCheck = true; 
            }else{
              this.error[pregunta.codigo + col] = false;
            }
          }
        }
        else { // El resto
          if(!this.respuestas[pregunta.codigo] || this.respuestas[pregunta.codigo] == "") {
            this.error[pregunta.codigo] = true;
            errorCheck = true; 
          } else {
            this.error[pregunta.codigo] = false;
          }
        }
      }
    }
    console.warn(errorCheck);
    return errorCheck;
  }

  async guardar() {
    this.estado = "ENVIADO";

    /*let respuesta = {
      fecha: new Date,
      respuestas: this.respuestas,
      cuestionario: this.cuestionario.nombre
    }
    
    this.cuestionarioService.postRespuestas(respuesta)
      .subscribe(res => {
        console.log(res);
        this.loading = false;
        this.estado = "ENVIADO";
        this.cdr.detectChanges();
      }); */
  }

  enviar() {
    if(!this.hasErrors()) {
      this.loading = true;
      this.guardar();
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 100);
    }
  }
}
