// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
//import { AuthGuard } from './core/auth';
import { AuthGuard, StartGuard } from './_helpers';
//WFN Imports
import { HomeComponent } from './views/pages/home/home.component';
import { StartComponent } from './views/pages/start/start.component';
import { AdminUsuariosComponent } from './views/pages/admin-usuarios/admin-usuarios.component';
import { AdminInteractivoComponent } from './views/pages/admin-interactivo/admin-interactivo.component';
import { AdminEstatisticasComponent } from './views/pages/admin-estatisticas/admin-estatisticas.component';
import { AdminSoporteComponent } from './views/pages/admin-soporte/admin-soporte.component';
import { AdminWfpComponent } from './views/pages/admin-wfp/admin-wfp.component';
import { AdminEstadisticasWfpComponent } from './views/pages/admin-estadisticas-wfp/admin-estadisticas-wfp.component';
import { AdminBlogWfpComponent } from './views/pages/admin-blog-wfp/admin-blog-wfp.component';
import { AdminWfpPresupuestoComponent } from './views/pages/admin-wfp-presupuesto/admin-wfp-presupuesto.component';
import { CuestionarioComponent } from './views/pages/cuestionario/cuestionario.component';

/**
 * Fichero de enrutado de la plataforma.
 * Se define el componente asociado, los parámetros (:tarifa) en caso de haberlos 
 * y las clases auxiliares que pueden activar (comprobar autenticación, estado usuario, etc.).
 * @author Informática Integral Vasca
 */
const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: '',
		component: BaseComponent,
		children: [
			/*{ 
				path: 'start', 
				component: StartComponent,
				canActivate: [AuthGuard]
			},
			{ 
				path: 'start/:tarifa', 
				component: StartComponent,
				canActivate: [AuthGuard]
			},
			{ 
				path: 'home', 
				component: HomeComponent,
				canActivate: [AuthGuard, StartGuard]
			},*/
			{ 
				path: 'cuestionario', 
				component: CuestionarioComponent
			},
			{ 
				path: 'cuestionario/:id', 
				component: CuestionarioComponent
			},
			// -- ADMIN --
			/*{ 
				path: 'admin-usuarios', 
				component: AdminUsuariosComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-interactivo', 
				component: AdminInteractivoComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-estadisticas', 
				component: AdminEstatisticasComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-estadisticas-wfp', 
				component: AdminEstadisticasWfpComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-soporte', 
				component: AdminSoporteComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-soporte/nuevo/:usuario', 
				component: AdminSoporteComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-wfp', 
				component: AdminWfpComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-blog-wfp', 
				component: AdminBlogWfpComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'admin-wfp-presupuesto', 
				component: AdminWfpPresupuestoComponent,
				canActivate: [AuthGuard, StartGuard]
			},*/

			// --------
			{
				path: 'builder',
				loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'cuestionario', pathMatch: 'full'},
			{path: '**', redirectTo: 'cuestionario', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
