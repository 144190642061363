import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de localizaciones
 */
@Injectable()
export class PrecioService {
    constructor(private http: HttpClient) { }
 
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/precio`);
    }
 
    getByLocalizacion(loc: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/precio?localizacion=${loc}`);
    }
 
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/precio/${id}`);
    }
}