import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de centros WFP
 */
@Injectable()
export class CentroService {
    constructor(private http: HttpClient) { }
 
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro`);
    }
 
    getByCP(cpDesde: any, cpHasta) {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_centro?where={"borrado":0,"cp":{"<=":"${cpHasta}",">=":"${cpDesde}"}}`);
    }
 
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/wfp_centro/${id}`);
    }
}