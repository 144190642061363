// English
export const locale = {
	lang: 'en',
	data: {
		MENU: {
			SEGUIMIENTO: 'tracking<br> my bids',
            SEGUIMIENTO2: 'tracking my bids',
            EJECUCION: 'being<br>executed',
            EJECUCION2: 'being executed',
            TODAS: 'see all<br>the bids',
            TODAS2: 'see all the bids',
            CREAR: 'create<br>tender',
            CREAR2: 'create tender',
            EXPLORAR: 'browse my<br>tenders',
            EXPLORAR2: 'browse my tenders',
            PENDIENTES: 'pending<br>award',
            PENDIENTES2: 'pending award',
            TODAS_LIC: 'see all<br>the tenders',
            TODAS_LIC2: 'see all the tenders',
            CONTRATAR: 'CONTRACT',
            PERFIL: 'My Profile',
            LOGOUT: 'Logout'
		},
		FOOTER: {
			CONTACTA: 'Contact us',
            SEDES: 'Offices in: Madrid, Mexico, Lisbon and Cologne.',
            AVISO_LEGAL: 'Legal notice',
            POLITICA: 'Privacy policy'
		},
		HOME: {
			BIENVENIDA: 'Welcome to the WFN tenders area',
            BIENVENIDA2: 'Welcome to the WFP tenders area',
            INVITADO1: 'You are accessing our platform in guest mode.',
            INVITADO2: 'This means you may know about on-going tenders, but not bid for them nor publish other new ones.',
            DEMO: 'You are accessing our demonstration platform. This means you may test all its functionality with fictitious tender examples.',
            CONTRATAR: 'CONTRACT NOW',
            PAGO: 'Subscription payment pending.',
            PAGO2: 'You have not yet provided us with the payment details for your subscription. Click on the button to access the secure payment platform.',
            PLATAFORMA_PAGO: 'SECURE PAYMENT PLATFORM'
		},
		AUTH: {
			LOGIN: {
                TITLE: 'Log in',
                BUTTON: 'Log in',
                BIENVENIDA1: 'Welcome to the contracting process of your WFN rate {{tarifa}}',
                BIENVENIDA1_SUB: 'Log in to associate your account to this rate.',
                BIENVENIDA2: 'Welcom to the WFN contracting process',
                BIENVENIDA2_SUB: 'Log in to continue.',
                FORGOT_BUTTON: 'Forgot your password?',
                REGISTER: 'Don\'t have an account?',
                REGISTER_BUTTON: 'Register',
                VOLVER: 'Return to log in'
            },
            INPUT: {
                EMAIL: 'Email',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password'
            },
            FORGOT: {
                TITLE: 'Recover password',
                DESC: 'Enter the email code received and the new password',
                SUCCESS: 'Your password has been correctly recovered. Log in with your new credentials to continue.',
                INCORRECTO: 'The recovery code entered is not correct.',
                INVALID: 'This recovery link is not valid or has already been used.',
                LOGIN: 'LOG IN',
                CODIGO: 'Recovery code (4 figures)',
                BUTTON: 'RECOVER PASSWORD',
                EMAIL: 'A recovery email has been sent to the email address registered in the system. Follow the instructions therein to continue.'
            },
            REGISTER: {
                TITLE: 'Register',
                BIENVENIDA1: 'Welcome to the contracting process of your WFN rate {{tarifa}}',
                BIENVENIDA1_SUB: 'First of all we create an account associated to your rate.',
                BIENVENIDA2: 'Welcome to the WFN contracting process',
                BIENVENIDA2_SUB: 'First of all we create an account.',
                EMAIL_CORP: 'Only corporate emails (eg. @your-company.com) are accepted',
                PAIS: 'Country',
                PAIS_SELECT: 'Select country...',
                PERTENEZCO: 'I belong to',
                PERTENEZCO_SELECT: 'Select sector...',
                SECTOR1: 'Funeral homes',
                SECTOR2: 'Insurance companies',
                SECTOR3: 'Pension funds',
                SECTOR4: 'Insurance bank',
                SECTOR5: 'Provision and pre-need companies',
                SECTOR6: 'Embassies',
                SECTOR7: 'Collectives with funeral coverage',
                SECTOR8: 'Others',
                BUTTON: 'REGISTER ME',
                LOGIN: 'Do you already have an account?',
                LOGIN_BUTTON: 'Log in',
                SUCCESS: 'Registration correct',
                SUCCESS_SUB: 'We welcome you to World Funeral Net!',
                SUCCESS_MAIL: 'We have sent a confirmation email to the registration address. Please enter the code or click on the link included in the email to access all the functionalities of the platform.',
                SUCCESS_MAIL2: 'We have sent a confirmation email to the registration address. Please enter the code or click on the link included in the email to proceed to contract your rate.',
                ERROR_CODE: 'The code entered is not correct.',
                VALIDAR_BUTTON: 'VALIDATE ACCOUNT',
                GRACIAS: 'Thank you for your trust.'
            },
            VALIDAR: { 
                LOADING: 'Validating your account, one moment please...',
                SUCCESS: 'Account validated',
                SUCCESS_DESC: 'Your account has been validated correctly. We will continue with the registration in a few seconds...',
                ERROR: 'Validation error',
                ERROR_DESC: 'Your validation link is not correct, check your email and use the last link generated.'
            },
            VALIDATION: {
                INVALID: '{{name}} invalid',
                REQUIRED: '{{name}} required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Terms and conditions required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'Invalid log in data. Try again.',
                INACTIVE: 'Your account is inactive. Check your email and click on the validation link.',
                REQUIRED_FIELD: 'This field is required',
                MIN_LENGTH_FIELD: 'The minimum length of this field is:',
                MAX_LENGTH_FIELD: 'The maximum length of this field is:',
                INVALID_FIELD: 'This field is invalid',
                MATCH: 'The password and your confirmation do not match',
                INVALID_REGISTER: 'The email entered is already registered. Try again or log in.'
            }
		},
		DEMO: {
			LOADING: 'Preparing the platform, one moment please...',
            BIENVENIDA: 'Welcome to WFN',
            SECTOR: 'Select the sector you belong to in order to access the demonstration platform.',
            FUNERARIA: 'I AM A FUNERAL OPERATOR',
            ASEGURADORA: 'I AM AN INSURANCE COMPANY, BUSINESS OR COLLECTIVE',
			HOME: { 
				STEP1: '¡Welcome to WFN! This is the demonstration platform. Let\'s discover the platform functionalities and then you may interact with it and conduct the tests you need.',
                STEP2: 'This is the main menu. From here you may access the different pages available.',
                STEP3: 'Here you may track the tenders you have participated in.',
                STEP4: 'This shows the tenders you have been assigned. In these you may consult the information on the successful tenderer and fill in the traceability.',
                STEP5: 'Finallly, this shows the complete list of available tenders. Let\'s go to this page to see them more in detail.',
                STEP6: 'You may make your tenders on this menu. By inserting the details and they will be publised in the list. You may check it when the demostration is finalised.',
                STEP7: 'Once created, you will find all your tenders on this page.',
                STEP8: 'When the tender has been completed, you may find all the ones pending allocation to a funeral home on this screen.',
                STEP9: 'This shows the tenders that have already been assigned to a funeral home and are being executed. It enables you to consult the information of the funeral home and see its traceability.',
                STEP10: 'Finally, this shows the complete list of tenders available. Let\'s go to this page in order to see them in further detail.',
            },
            LICIT: {
                STEP1: '¡Welcome to WFN! This is the demonstration platform. Let\'s discover the platform functionalities and you may then interact with it and conduct the tests you need.',
                STEP2: 'Each “card” you see, is a tender placed by an insurance company or collective. In it you will find all the information and details of the services, such as the localisation, the time remaining, the current amount, service status, etc.',
                STEP3: 'In the area above you will find the tender reference, along with the creation date. In addition, you may delete them at any time (the participants will be informed) and edit them even if you have not participated in them. If the tender remains void, you may also edit it once it is finalised.',
                STEP4: 'In the area above you will find the reference the system has assigned to the tender, along with the creation date.',
                STEP5: "These are the services that must be provided in a tender. By clicking on them you may see the general details. The services with the icon <b style='color: red'>+</b> indicate the tenderer has included a specific detail",
                STEP6: 'The following indicates the location in which the service will be provided or the origin and destination of the transfers',
                STEP7: 'This is the time remaining for the tender to finalise and therefore the time for bidding.',
                STEP8: 'In this space you can see the current status of the tender. It may be open, pending award, pending execution, completed, closed or void.',
                STEP9: 'Finally you will find the current amount of the tender and access the details of your bids or those entered.',
                STEP10: 'Once a tender has been assigned you may track the traceability and the details of the awarded funeral home.',
                STEP11: 'In addition, on the following menu you may see the traceability details filled in by the funeral home.',
                STEP12: 'In this space you can see the current status of the tender. It may be open (winning/losing/entered), ongoing, being executed, completed or void.',
                STEP13: 'Finally, you will find the current amount of the tender and you may bid or register in it.',
                STEP14: 'Once you have been assigned a tender you may see the details of the awarding company.',
                STEP15: 'If the tender is being executed you may fill in the service traceability information.',
                STEP16: 'You may personalise the viewing of the cards at all times with the different filters available.',
                STEP17: 'That is all. Remember you are on the demonstration platform. You may interact with the tenders available, create new ones and conduct all the tests you need.',
                STEP18: 'That is all. Remember you are on the demonstration platform. You may interact with the tenders available, bid or register and conduct all the tests you need.',
			}
		},
		CONFIRMACION: {
			LOADING: 'Processing the contracting...',
            SUCCESS: 'Contracting completed',
            SUCCESS_SUB: 'You will be redirected to the platform in a few seconds...',
            PENDING: 'Confirmation of account pending',
            PENDING_SUB: 'Before continuing, you account must be confirmed by the organisation. The organisation reserves the right of admission, we will evaluate your admission in 24-48 H and you may finalise the contracting process.',
            PAGO: 'Accessing the payment confirmation...'
		},
		PAGO: {
			LOADING: 'Accessing the secure payment platform...',
            RESULT_LOADING: 'Loading subscription data...',
            METODO: 'Selecting method of payment',
            METODO_SUB: 'Select method of payment to complete account activation.',
            METODO_TARJETA: 'CREDIT CARD PAYMENT',
            METODO_SEPA: 'DIRECT DEBIT PAYMENT (SEPA)',
            SEPA_TITLE: 'Direct debit payment - SEPA',
            SEPA_NOMBRE: 'Holder Name',
            SEPA_IBAN: 'Account number (IBAN)',
            SEPA_CODIGO: 'Promotion code',
            SEPA_ERRORCOD: 'The code entered is incorrectódigo introducido no es correcto',
            SEPA_BUTTON: 'Subscribe me to WFuneralNet',
            SEPA_LOADING: 'Processing data...',
            SEPA_LEGAL: 'By providing your payment information and confirming this payment, you authorise WFuneralNet and Stripe, our payment services supplier, to send intstructions to your bank to charge the amount in your account and to your bank to charge the amount in your account according to said instructions. As part of your rights, you have the right to reimbursement from your bank in accordance with the terms and conditions of your agreement with your bank. Your rights are explained in a statement you may obtain from your bank. You consent to receive notifications until 2 days before they take place.',
            SUCCESS: 'Your subscription has been activated correctly.',
            SUCCESS_SUB: 'You may start using the platform with full functionality. Click on the following button to access log in.',
            SUCCESS_BUTTON: 'START USING WORLD FUNERAL NET',
            CANCEL: 'You have cancelled the subscription process.',
            CANCEL_SUB: 'If an error has occurred or you want to try again, click on the following button to access the secure payment platform.',
            CANCEL_BUTTON: 'CONTINUE TO SECURE PAYMENT PLATFORM',
			WFP: 'No WFP charge will be made until the service is performed. You will pay 10% of the price at which the bid was awarded.',
		},
		LICIT: {
			FILTROS: {
                TITLE: 'Customise your filter',
                INVITADO: 'The filters are deactivated in the guest account.',
                CONTRATAR: 'Contract',
                REFERENCIA: 'Reference',
                SERVICIO: 'Service',
                LOC_ORIGEN: 'Source locaisation',
                LOC_DESTINO: 'Destination localisation',
                TODOS: 'All',
                PAIS: 'Country',
                COMUNIDAD: 'Autonomous Community',
                ESTADO: 'State',
                FECHA_DESDE: 'Date from',
                FECHA_HASTA: 'Date up to',
                PRECIO_MIN: 'Min. Price',
                PRECIO_MAX: 'Max. Price',
                ESTADO_LIC: 'Tender status',
                ESTADOS: {
                    PENDIENTE_INI: 'Start pending',
                    ABIERTA: 'Open',
                    PENDIENTE_ADJ: 'Award pending',
                    ADJ_TRAM: 'Award ongoing',
                    ADJ_PEN: 'Award and pending',
                    EJEC: 'Being executed',
                    COMPLETADA: 'Completed',
                    CERRADA: 'Closed',
                    DESIERTA: 'Void'
                },
                REESTABLECER: 'Restore filters'
            },
            ORDEN: {
                ESTADO: 'Order by tender status',
                ESTADO_ABIERTAS: 'Show first opened',
                ESTADO_PENDIENTE: 'Show first pending',
                ESTADO_ADJUD: 'Show first awarded',
                FECHA: 'Order by start date',
                FECHA_ASC: 'Upward',
                FECHA_DESC: 'Downward',
                TIEMPO: 'Order by time until finalisation',
                TIEMPO_ASC: 'Upward',
                TIEMPO_DESC: 'Downward'
            },
			LOADING: 'Loading tenders...',
            NOHAY: 'There are no tenders',
			//NUEVO!!! WFP --------------
			CARD: {
                ORIGEN: 'Origin',
                DESTINO: 'Destination',
                TRASLADO: 'Transfer',
                LOCALIZ: 'Localisation',
				LOC_WFP1: 'Localización fallecido',
				LOC_WFP2: 'Localización tanatorio',
				SIN_PRECIO: 'En curso',
                PUJA: 'bid',
                OFERTA: 'offer',
                PUJAR: 'bid',
                OFERTAR: 'offer',
                LA: 'The',
                TIME: 'Time remaining for',
                COMIENZA: 'starting in',
                VER_ASEG: 'see insurance company',
                VER_FUN: 'see funeral home',
				VER_PART: 'ver particular',
                VER_DETALLES: 'see details',
                ESTADOS: {
                    PENDIENTE_INI: 'Start pending',
                    ABIERTA: 'Open',
                    INSCRITO: 'Registered',
                    GANANDO: 'Winning',
                    PERDIENDO: 'Losing',
                    CONTU: 'with your',
                    PUJA: 'bid',
                    OFERTA: 'offer',
                    DE: 'from',
                    PENDIENTE_ADJ: 'Pending',
                    PENDIENTE_ADJ2: 'award',
                    CERRADA: 'Closed',
                    ADJ_TRAM: 'Award underway',
                    ADJ_PEN: 'Awarded and',
                    ADJ_PEN2: 'pending execution',
                    EJEC: 'Being executed',
                    COMPLETADA: 'Completed',
                    NO_ADJ: 'Not awarded',
                    DESIERTA: 'Void'
                },
                PUJA_SALIDA: 'Opening bid',
                OFERTA_SALIDA: 'Opening offer',
                PUJA_ACTUAL: 'Current bid',
                OFERTA_ACTUAL: 'Current offer',
                PUJA_GANADORA: 'Winning bid',
                OFERTA_GANADORA: 'Winning offer',
                PUJA_DESIERTA: 'Void bid',
                OFERTA_DESIERTA: 'Void offer',
                PUJA_ADJUDIC: 'Bid award.',
                OFERTA_ADJUDIC: 'Offer award.',
                PRECIO_FIJO: 'Fixed price',
                VER_PUJAS: 'SEE BIDS',
                VER_OFERTAS: 'SEE OFFERS',
                INSCRITOS: 'REGISTERED',
                ADJUDICAR: 'AWARD',
                TRAZABILIDAD: 'TRACEABILITY',
                INSCRÍBETE: 'REGISTER',
                PUJAR2: 'BID',
                OFERTAR2: 'OFFER',
                VOLVER_PUJAR: 'BID AGAIN',
                VOLVER_OFERTAR: 'OFFER AGAIN',
				IVA_INC: 'VAT excluded',
                HIST: {
                    HISTORICO_PUJAS: 'Bid history',
                    HISTORICO_OFERTAS: 'Offer history',
                    HISTORICO_INSC: 'Registration history',
                    IMPORTE_SALIDA: 'Opening amount',
                    ACTUALIZAR: 'update',
                    TOTAL: 'Total',
                    FECHA: 'Date',
                    USUARIO: 'User',
                    IMPORTE: 'Amount',
                    DESIERTA: 'Void tender',
                    CREADOR: 'CREATOR',
                },
                CERRAR: 'close',
                ASIG: {
                    NOMBRE: 'Name',
                    LICENCIA: 'Licence',
                    DOMICILIO: 'Address',
                    TELEFONO: 'Telephone',
                    EMAIL: 'Email',
                    WEB: 'Website',
                    CERTIFICACIONES: 'Certifications',
                    PUNTUACIONES: 'Scores',
                    IMPORTE: 'Tender amount',
                    ADJUDICAR: 'AWARD',
                    EXCLUIR: 'EXCLUDE',
                    EXCLUIR_TODAS: 'EXCLUDE ALL',
                    BLOQUEAR: 'BLOCK PROV.',
                    VER_DETALLES: 'see details',
                    CERRAR_DETALLES: 'close details',
                    DETALLE_ASIG1: 'The insurance company reserves the right to award assessing the quality of the services offered by the Funeral Home.',
                    DETALLE_ASIG11: 'El particular se reserva el derecho de adjudicación valorando la calidad de los servicios ofrecidos por la Funeraria.',
                    DETALLE_ASIG2: 'It is important for you to fill in your profile with quality scores and certificates.',
                    DETALLE_ASIG_BTN: 'Complete profile',
                    REFERENCIA: 'WFN tender reference',
                    REFERENCIA2: 'Tender creator reference',
					SIGUIENTES_PUJAS: 'Next bids',
					SIGUIENTES_OFERTAS: 'Next offers'
                },
                PUJAS: {
                    PUJA_RAPIDA: 'Fast bid',
                    OFERTA_RAPIDA: 'Fast offer',
                    PUJA_MANUAL: 'or enter your bid manually',
                    OFERTA_MANUAL: 'or enter your offer manually',
					PUJA_WFP: 'Enter your bid',
					OFERTA_WFP: 'Enter your offer',
                    PUJAR: 'BID',
                    OFERTAR: 'OFFER',
                    AVISO: 'Warning',
                    PRORROGA3_PUJA: 'The tender will extend by 3 minutes when bidding during the final minute',
                    PRORROGA3_OFERTA: 'The tender will extend by 3 minutes when offering during the final minute',
                    PRORROGA1_PUJA: 'The tender will extend by 1 minute when bidding during the final minute',
                    PRORROGA1_OFERTA: 'The tender will extend by 1 minute when offering during the final minute',
                    PROCESO_PUJA: 'Your bid is being processed. One moment please...',
                    PROCESO_OFERTA: 'Your offer is being processed. One moment please...',
                    HISTORICO_PUJAS: 'See bid history',
                    HISTORICO_OFERTAS: 'See offer history',
					FUNERARIA: 'Select funeral home',
					FUNERARIA2: 'Selected Funeral home'
                },
                TRAZA: {
                    INCIDENCIA: 'Service incident',
                    VALIDAR: 'VALIDATE',
                    CANCELAR: 'CANCEL',
                    FECHA: 'Date',
                    LUGAR: 'Location'
                },
                SERVICIO: { //NUEVO WFP
                    TIPO_TRASLADO: 'Type of transfer',
                    CARRETERA: 'Road point to point',
                    AEROPUERTO: 'Airport',
                    INCLUIDOS: 'Servicies Included',
                    TIPO_COMPLETO: 'Complete Serv. Type',
                    CREMACION: 'Cremation + Urn',
                    DESTFIN: 'Final destination Temp.',
                    DETALLE_ESP: 'Specific detail of the service ',
                    ESPEC_GEN: 'General specifications of the service',
                    TRASLADO1: 'Death and final destination (burial or cremation) occur in different locations.',
                    TRASLADO2: 'Regular elements and services of the transfer:',
                    TRASLADO3: 'Transfer casket: In those cases in which it is required by the Regulation of the Sanitary Mortuary Police (RPSM), it must also be additionally equipped with an air filtering device or another to balance the interior and exterior pressure.',
                    TRASLADO4: 'Air freight where applicable, Kms. by road and specific procedures and transactions.',
                    WFP_TIPO: 'Service type',
					WFP_TIPO_CREMA: 'Cremation',
					WFP_TIPO_INHUM: 'Inhumation',		
					WFP_CEREM: 'Ceremony',
					WFP_CIVIL: 'Civil',
					WFP_RELIG: 'Religious',
					WFP_CATERING: 'Number of people',
					WFP_CORONAS: 'Number of wreaths',
					WFP_OTROS: 'Other specifications',
					WFP_MULTIPLE: 'Multiple deaths',
					WFP_PERSONAS: 'People'
                }
            },
			SWAL: {
				CANCELAR: 'Cancel',
                registerSwal: 'Contracting Necessary',
                registerSwal_TEXT: 'This content is not available with a guest account. Contract a rate to continue.',
                registerSwal_BTN1: 'Contract',
                suscriptionSwal: 'Tender not available',
                suscriptionSwal_TEXT: 'This content is not available with your subscription. Check your rate in order to access.',
                suscriptionSwal_BTN1: 'My Profile',
                confirmationSwal: 'Tender Correct',
                confirmationSwal_TEXT: 'Your amount has been correctly tendered',
                errorSwal: 'Wrong Amount',
                errorSwal_TEXT: 'The amount must be lower than the current tender amount.',
                errorNegativeSwal_TEXT: 'The tender amount cannot be negative.',
                error2Swal_TEXT: 'The tender amount has changed and is now lower than your proposal. Try again with another amount.',
                confirmaSwal: 'Confirmation',
                confirmaSwal_TEXT1: 'Do you confirm the tender for {{importeLicitar}} {{divisa}}?',
                confirmaSwal_TEXT2: 'Do you confirm the registration to the tender? You will receive a notification if you are selected to finalise.',
                confirmaSwal_BTN1: 'Yes, I do',
                confirmaElegidoSuccessSwal: 'User selected correctly.',
                confirmaElegidoSuccessSwal_TEXT: 'The successful tenderer has been assigned correctly. You may see the details as follows.',
                confirmaElegidoSwal_TEXT1: 'Do you confirm {{empresa}} as the successful bidder of the tender?',
                confirmaSaltarSwal_TEXT1: 'Do you confirm excluding {{empresa}} as the successful bidder of the tender and reject it in future tenders? There are no more exclusions the tender will remain void. This participant will no longer see your tenders',
                confirmaSaltarSwal_TEXT2: 'Do you confirm excluding {{empresa}} as the successful bidder of the tender and reject it in future tenders? This participatn will no longer be able to see your tenders. Exclusion {{pasadas}}/2',
                confirmaSaltarSwal_TEXT3: 'Do you confirm excluding {{empresa}} as the successful bidder of the tender? There are no more exclusions, the tender will remain void.',
                confirmaSaltarSwal_TEXT4: 'Do you confirm excluding {{empresa}} as the successful bidder of the tender? You will not be able to see this user again. Exclusion {{pasadas}}/2',
                confirmaSaltarSwal_TEXT5: 'Do you confirm excluding {{empresa}} as the successful bidder of the tender and reject it in future tenders? There are no more participants, the tender will remain void. This participant will no longer be able to see your tenders',
                confirmaSaltarSwal_TEXT6: 'Do you confirm excluding {{empresa}} as the successful bidder of the tender and reject it in future tenders? This participant will no longer be able to see your tenders.',
                confirmaSaltarSwal_TEXT7: 'Do you confirm excluding all the participants? The tender will remain void.',
                confirmaSaltarSuccessSwal1: 'Participant excluded correctly.',
                confirmaSaltarSuccessSwal2: 'Participant blocked correctly.',
                confirmaSaltarSuccessSwal_TEXT1: 'The participant has been excluded. Remember that you can only exclude 2 times per tender.',
                confirmaSaltarSuccessSwal_TEXT2: 'The participant has been excluded. The tender has been voided.',
                confirmaSaltarSuccessSwal_TEXT3: 'The participant has been excluded from the tender.',
                confirmaSaltarSuccessSwal_TEXT4: 'The participant has been excluded and will no longer see your tenders. The tender has been voided.',
                confirmaSaltarSuccessSwal_TEXT5: 'The participant has been excluded and will no longer see your tenders.',
                confirmaTrazaSwal: 'Tracking confirmation',
                confirmaTrazaSwal_TEXT: 'Do you want to confirm this tracking? The tenderer will be informedr.',
                confirmaTrazaSwal_BTN1: 'Yes, I do',
                errorTrazaSwal: 'Tracking error',
                errorTrazaSwal_TEXT: 'You must enter a comment on the service as there has been an incident therein.',
                errorTrazaSwal_TEXT2: 'You must enter service date.',
                errorTrazaSwal_TEXT3: 'You must enter the information about service location.',
                confirmaTrazaSuccessSwal: 'Tracking confirmed correctly.',
                confirmaTrazaSuccessSwal_TEXT: 'The tracking selected has been confirmed correctly.',
                cancelaTrazaSwal: 'Cancel tracking',
                cancelaTrazaSwal_TEXT: 'Do you want to cancel this tracking? It will delete all the information therein.',
                cancelaTrazaSwal_BTN1: 'Yes, I do',
                cancelaTrazaSuccessSwal: 'Tracking cancelled correctly.',
                cancelaTrazaSuccessSwal_TEXT: 'The tracking selected has been cancelled correctly.',
                borrarSwal: 'Delete Tender',
                borrarSwal_TEXT: 'Are you sure you want to delete this tender? You will not be able to recover its content.',
                borrarSwal_BTN1: 'Yes, delete',
                borrarConfirmSwal: 'Tender deleted correctly',
                borrarConfirmSwal_TEXT: 'The tender has been deleted correctly.',
                conexionSwal: 'Connection limit exceeded',
                conexionSwal_TEXT: 'The number of simultaneous connections available has been exceeded. Close one of the active sessions to continue.'
			},
			TUTORIAL: 'SEE TUTORIAL AGAIN'
		},
		GLOBAL: {
			PAGO: 'Subscription payment pending.',
            PAGO_SUB: 'You have yet to provide us with the payment details for your subscription. Click on the button to access the secure payment platform.',
            PAGO_BUTTON: 'SECURE PAYMENT PLATFORM',
            errorModoSwal: 'Platform not available',
			errorModoSwal_TEXT: 'This content is not available with your subscription.'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} invalid',
                REQUIRED: '{{name}} required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Acceptance of terms and conditions is required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The data entered are not correct. Try again.',
                REQUIRED_FIELD: 'Filling in this field is required',
                MIN_LENGTH_FIELD: 'The minimum length of this field is:',
                MAX_LENGTH_FIELD: 'The maximum length of this field is:',
                INVALID_FIELD: 'This field is invalid',
                MATCH: 'The password and your confirmation do not match',
                INVALID_REGISTER: 'The email entered is already registered. Try again or log in.'
			}
		},
        CREAR: {
            TITULO: 'Create tender',
            TITULO_EDIT: 'Edit tender',
            REF: 'Customer reference',
            REF2: '(it will not be displayed publicly)',
            REF_ERROR: 'The reference is already registered in the system.',
            TIPO: 'Type of tender',
            ESTANDAR: 'Standard tender.',
            ESTANDAR_DESC: 'Enter the opening figure. The funeral homes will under-bid to win the service or transfer.',
            FIJO: 'Fixed price tender.',
            FIJO_DESC: 'The service will be awarded at the price set between all the funeral homes who have requested it.',
            FECHA_INI: 'Start date/time',
            FECHA_FIN: 'End date/time',
            FECHA_ERROR: 'The end date cannot be before the start date.',
            OPT_TRASLADO: 'Tender for transfer',
            OPT_SERVICIO: 'Tender for service',
            IMPORTE: 'Opening amount',
            IMPORTE_ERROR1: 'The transfer amount cannot be negative',
            IMPORTE_ERROR2: 'The transfer amount must be lower than 100,000',
            DETALLE: 'Specific details of service',
            ORIGEN: 'ORIGIN',
            PAIS: 'Country',
            COMUNIDAD: 'Autonomous Community',
            ESTADO: 'State',
            POBLACION: 'City',
            DESTINO: 'DESTINATION',
            KMS: 'KMs to destination',
            OPCIONAL: '(optional)',
            LOCALIZACION: 'LOCALISATION',
            DESTINO_TRASLADO: '(Transfer destination)',
            PENDIENTE_DESTINO: 'Transfer destination selection pending',
            SERVICIO_ERROR: 'You must select at least one service to tender',
            IMPORTE_ERROR3: 'The transfer amount cannot be negative',
            IMPORTE_ERROR4: 'The transfer amount must be less than 100,000',
            INCLUIDO_EN: 'Included in',
            INCLUIDO_EN2: 'Complete Service',
            SELECT_OPCION: 'Select one option',
            OPCION_ERROR: 'You must select one option',
            OTROS_OPCION: 'Select other services to include',
			CREACION: 'Creation format',
			UNIDO: 'Same tender',
			UNIDO_DESC: 'The transfer and services will be offered in the same bidding process. The funeral home awarded will be the same for both.',
			SEPARADO: 'Separate tenders',
			SEPARADO_DESC: 'The transfer and services will be offered in separate bids. The funeral home awarded may be different for both.',
            BOTON: 'ADD TENDER',
            BOTON_EDIT: 'EDIT TENDER',
			SWAL: {
				confirmationAddSwal: 'Tender Added',
				confirmationAddSwal_TEXT: 'The selected services have been successfully added to the list of tenders.',
				confirmationEditSwal: 'Licitación edited',
				confirmationEditSwal_TEXT: 'The tender has been successfully edited.',
				errorLicitacionSwal: 'Tender with bids',
				errorLicitacionSwal_TEXT: 'This tender has already received bids. It is not possible to make modifications, you can delete it and create it again.'
			}
        },
        START: {
            PASOS: {
                DATOS: 'Data of contracting party',
                VALIDACION: 'Data validation',
                ADICIONAL: 'Additional information',
                PAGO: 'Payment details',
                CONFIRMACION: 'Confirmation',
                TARIFA: 'Select rate',
                TARIFA2: 'Rate information'
            },
            TARIFA_LOC: 'Indicate rate application localisation', 
			TARIFA_LOC_WFP: 'Indicate the geographic scope of your services',
            CANALES: 'I want to be notified through the following channels: <i>(at least one)</i>',
            PLATAFORMAS: 'I want to access the following platforms',
            WFN: 'Make your funeral business grow accessing hundreds of tenders published by Insurance Companies, Businesses and Collectives.',
			WFP: 'Make your funeral business grow accessing hundreds of requests from individuals.',
            INFO: 'More info.',
            VOLVER: 'GO BACK',
            CONTINUAR: 'CONTINUE',
            VALIDACION: 'Data validation',
            SMS: {
                TITULO: 'Mobile phone validation',
                MENSAJE: 'We have sent an SMS to your phone {{telefono}} with a confirmation code. Enter it below.',
                CODIGO: 'Confirmation code',
                CODIGO_ERROR: 'The code entered is invalid',
                REENVIAR: 'Resend code',
                REENVIADO: 'Message resent'
            },
            CERTIFICADOS: 'Additional information - Certificate  and quality scores',
            PAGO: 'Enter bank details',
            CONFIRMACION: 'Confirmation',
            PERSONAL: 'Personal Information',
            TARIFA: 'Rate',
			SUSCRIPCION: 'Subscription',
            AMBITO: 'Geographic scope',
			WFP_RESUMEN: 'Pay 10% of the price at which the tender was awarded.',
            ALTA: 'High as',
            OFERTANTE: 'Bidder',
            PROVEEDOR: 'Supplier',
            SERVICIOS: 'of funeral transfers and services',
            DATOS_PAGO: 'Payment details',
            CONFIRMAR: 'CONFIRM',
            CONFIRMAR_PAGAR: 'CONFIRM AND PAY',
            
            SWAL: {
                errorSMSSwal: 'SMS code incorrect',
                errorSMSSwal_TEXT: 'The telephone validation code sent by SMS is incorrect. Check and enter it again.',
                errorTelegramSwal: 'Telegram Code incorrect',
                errorTelegramSwal_TEXT: 'The Telegram validation code entered is incorrect. Check and enter it again.'
            }
        },
        PERFIL: {
            SUSCRIPCION_HASTA: 'Subscription until',
            GUARDAR: 'STORE INFORMATION',
            DATOS: {
                TITULO: 'My profile',
                TITULO2: 'User information',
                EMAIL: 'Email',
                EMPRESA: 'Business/Collective',
                EMPRESA2: 'Name of the business or collective',
                ACTIVIDAD: 'Activity',
                LICENCIA: 'Licence No.',
                PAIS: 'Country',
                COMUNIDAD: 'Autonomous Community/State',
                DIRECCION: 'Address',
                POBLACION: 'City',
                CP: 'PC',
                MOVIL: 'Mobile Telephone',
                NOTIFICACIONES: 'Notifications',
                TELEFONO: 'Telephone',
                WEB: 'Website',
            },
            SUSCRIPCION: {
                TITULO: 'Subscription',
                NO_HAY: 'There is no active subscription',
                CAMBIO: 'Processing change of method of payment...',
                TARIFA: 'Rate',
                FECHA_INICIO: 'Start date',
                FECHA_FIN: 'End date',
                PROX_RENOV: 'Next Renewal',
                FACTURACION: 'Invoicing',
                PRECIO: 'Price',
                LOCALIZACION: 'Localisation of the rate',
                ESTADO: 'Rate status',
                RENOV_AUTO: 'Automatic renewal',
                RENOV_CANCEL: 'Renewal cancelled',
                PROX_CAMBIO: 'Next change of rate',
                FECHA_EFECT: 'Effective Date',
                LOCALIZACION2: 'Localisation',
                CANCELAR_CAMBIO: 'Cancel change',
                CAMBIO_PROCESO: 'Procesing cancellation...',
                METODO_PAGO: 'Method of payment',
                EXPIRACION: 'Expiration',
                PAGO_CAMBIO: 'Change method of payment',
                PAGO_LOADING: 'Loading payment platform...',
                BTN_LOCALIZACION: 'CHANGE LOCALISATION',
                BTN_TARIFA: 'CHANGE RATE',
                BTN_SUSC: 'CANCEL SUBSCRIPTION',
                BTN_CANCEL: 'PROCESSING CANCELLATION...'
            },
            METODO_PAGO: {
                TITULO: 'Method of payment',
                TITULAR: 'Holder',
                FACTURACION: 'Invoicing',
                MENSUAL: 'Monthly',
                LICITA: 'For tender',
                CAMBIAR: 'Change payment details',
                NOMBRE: 'Account Holder\'s Name',
                IBAN: 'Account number (IBAN)'
            },
            PAGOS: {
                TITULO: 'Payment history',
                TITULO2: 'Payment history - invoices',
                IMPORTE: 'Amount',
                ESTADO: 'Status',
                VER: 'See',
                DESCARGA: 'Download'
            },
            BLOQUEO: {
                TITULO: 'Blocking list',
                FECHA: 'Blocking Date'
            },
            CERTIFICACIONES: {
                TITULO: 'My certifications and scores',
                TITULO2: 'Company quality Certifications/Scores',
                TITULO3: 'Add a new certification',
				TITULO4: 'Certifications',
                TITULO5: 'Update certification',
                NPS: 'Enter NPS scores',
                OTRO: 'Another quality measurement index (identification)',
                OTRO1: 'Enter the % score of',
                OTRO2: 'Enter the % score of the other index',
                NOMBRE: 'Name',
                ESTADO: 'Status',
                SUBIDA: 'Upload date',
                VALIDEZ: 'Validity date',
				BORRAR: 'Delete',
                BOTON: 'Add certification',
				BOTON2: 'Update certification',
				CANCELAR: 'Cancel',
                GUARDAR: 'Save certification',
                PROCESO: 'Processing certification',
                ESTADO_CORRECTO: 'Correct',
                ESTADO_REVISION: 'In review',
                ESTADO_ERROR: 'Invalid',
                ESTADO_CADUCADO: 'Expired',
				ACTUALIZAR: 'Update',
				SUBIR_ERR: 'Incorrect format',
				SUBIR_ERR_TEXT: 'The certification must be uploaded in PDF format',
				SUBIR_ERR2: 'File not selected',
				SUBIR_ERR2_TEXT: 'You must select or drag a file in the box in the upper zone.',
				ARRASTRA: 'Drag or click here to upload your certification (PDF)',
				SUBIR_OK: 'Certification saved',
				SUBIR_OK_TEXT: 'Certification has been successfully saved',
				BORRAR_PREG: 'Delete certification',
				BORRAR_PREG_TEXT: 'Are you sure you want to delete this certification? It cannot be recovered.',
				BORRAR_BOTON: 'Yes, delete',
				BORRAR_BOTON2: 'Cancel',
				BORRAR_OK: 'Certification deleted',
				BORRAR_OK_TEXT: 'The certification has been successfully deleted.'
            },
            FUNERARIAS: {
                
            },
            ALERTAS: {
                TITULO: 'My alerts system',
                FRECUENCIA: 'Select the frequency of notifications of new tenders.',
                INMEDIATO: 'Immediate',
                MINUTOS: 'Every 15 minutes',
                NUNCA: 'Never',
                MODO: 'Set the mode in which you will be notified of the platform messages.',
                MODO_ERROR: 'You must select at least one option',
                TELEGRAM_TITULO: 'Activation of Telegram notifications',
                TELEGRAM1: 'Follow the instructions to activate the notifications in the Telegram application:',
                TELEGRAM2: '1. Open the <b>Telegram</b> application on your mobile device or install it from the application store (App Store - Apple or Google Play - Android)',
                TELEGRAM3: '2. Find the user <b>WFuneralNetBot</b> on the search bar in the area above. Alternative: If you have Telegram installed in your current device you may access this link  <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" target="_blank">https://t.me/WFuneralNetBot</a> and open the application.',
                TELEGRAM4: '3. Click on the <b>Iniciar</b> button which will appear when you find the user in the application.',
                TELEGRAM5: '4. Then enter the validation code which will appear in the conversation.',
                COD_DISPOSITIVO: 'Device code',
                COD_ERROR: 'The code entered is invalid',
				NOTIFICACIONES: 'Customize the notifications you will receive of platform activity'
            },
            CAMBIO: {
                AVISO: 'WARNING: The localisation change will become effective in the next 48 hours.',
                BTN_CANCELAR: 'CANCEL CHANGE',
                BTN_LOC: 'CHANGE LOCALISATION',
                BTN_TARIFA: 'CHANGE RATE',
                BTN_PROCESO: 'PROCESSING CHANGE...',
                SELECCIONE_TARIFA: 'Select the rate',
                PAGAR: 'Amount to pay',
                AVISO2: 'WARNING: The new rate will be applied immediately.'
            },
            TODO_MUNDO: 'Accross the world',
			DISPOSITIVOS: {
				TITULO: 'Registered devices',
				NOMBRE: 'Device name',
				FECHA: 'Registration date'
			},
            SWAL: {
                confirmationSwal: 'Data Stored',
                confirmationSwal_TEXT: 'The data have been stored correctly',
                borrarSwal: 'Delete Blocking',
                borrarSwal_TEXT: 'Do you want to delete this blocking?',
                borrarSwal_BTN1: 'Yes, delete',
                borrarSwal_BTN2: 'Cancel',
                borrarConfirmSwal: 'Blocking deleted correctly',
                borrarConfirmSwal_TEXT: 'Blocking has been deleted correctly.',
                cancelarSuscripSwal: 'Cancel Subscription',
                cancelarSuscripSwal_TEXT: 'Are you sure you want to cancel your subscription? You may continue to use the WFN services until the current end date of your rate.',
                cancelarSuscripSwal_BTN1: 'Yes, cancel',
                cancelarSuscripSwal_BTN2: 'Cancel',
                cancelarSuscripOkSwal: 'Subscription cancelled correctly',
                cancelarSuscripOkSwal_TEXT: 'The subscription has been cancelled correctly. You may activate the rate again from your profile.',
                tarifaCambiadaSwal: 'Change of rate correct',
                tarifaCambiadaSwal_TEXT: 'The rate has been changed correctly',
				tarifaCambiadaSwal_TEXT2: 'Your new rate will be applied immediately.',
                errorSwal: 'Incorrect Email',
                errorSwal_TEXT: 'The email entered is already registered in the system.',
                errorTelegramSwal: 'Incorrect Telegram Code',
                errorTelegramSwal_TEXT: 'The telegram validation code entered is incorrect. Check and enter it again.',
                cambioLocSwal: 'Localisation changed correctly',
                cambioLocSwal_TEXT: 'Your new localisation rate will be effective in 48 hours.',
                borrarCambioSwal: 'Change deleted correctly',
                borrarCambioSwal_TEXT: 'The selected change has been cancelled correctly.',
				borrarDispSwal: 'Delete Device',
				borrarDispSwal_TEXT: 'Do you want to delete this device?',
				borrarDispSwal_BTN1: 'Yes, delete',
				borrarDispSwal_BTN2: 'Cancel',
				borrarDispConfirmSwal: 'Device deleted correctly',
				borrarDispConfirmSwal_TEXT: 'Device has been deleted correctly.',
            }
        },
		TARIFAS: {
			TITULO_FUN1: 'Plans and prices for',
			TITULO_FUN2: 'funeral operators',
			SELECCIONA: 'Select the plan that best suits your funeral operator business. You will always have the flexibility to increase or lower your plan.',
			TITULO_WFP: 'Plans for',
			SELECCIONA_WFP: 'Select the geographic area that best suits your funeral operator business.',
			SELECCIONA_WFP2: 'At WFP you only pay for what you contract. You pay 10% of the price at which the bid was awarded.',
            PAGO_ANUAL: 'ANNUAL PAYMENT',
			AHORRO: 'SAVE 10%',
			PAGO_MENSUAL: 'MONTHLY PAYMENT',
			POPULAR: 'MÁS POPULAR',
			PUJA_EN: 'Bidding in',
			OFERTA_PARA: 'Bidding in',
			TARIFA1: 'anywhere in the world',
			TARIFA2: 'a country',
			TARIFA3: '5 communities',
			TARIFA4: 'a community',
			TARIFA1_MX: 'Mexico',
			TARIFA2_MX: 'Mexico, EEUU and Canadá',
			TARIFA3_MX: 'anywhere in the world',
			MES: 'month',
			AÑO: 'year',
			IVA: 'VAT',
			TAX: 'TAX',
			OFERTA: 'Launch offer',
			CONTRATAR: 'CONTRACT',
			SELECCIONAR: 'SELECT',
			TEXTO1: 'Access the information of tenders that take place anywhere in the world.',
			TEXTO2: 'Premium access to the Wfuneralnews international monthly funeral magazine.',
			TEXTO3: 'Personalised support from our team.',
            TEXTO4: 'System of alerts in your cell phone or email, of tenders, bids or other relevant information that you consider.',

			TITULO_ASEG1: 'Prices for',
			TITULO_ASEG2: 'insurance companies, businesses and collectives',
			TITULO_ASEG3: 'WFN Insurance Companies and Collectives',
			ASEG1: 'Tender for your funeral services or transfers on our platform.',
			ASEG2: 'Obtain the best price for the service.',
			ASEG3: 'Access our database of 60,000 funeral businesses in 194 countries.',
			ASEG4: 'Segmentation by quality and excellence criteria (ISO, UNE, NPS).',
			ASEG5: 'Get information on the traceability of the contracted service.',
			ASEG6: 'Access the information of tenders that take place anywhere in the world.',
			ASEG7: 'Premium access to the Wfuneralnews international monthly funeral magazine.',
			ASEG8: 'Personalised support from our team.',
			PAGANOS: 'Pay us only for what has been contracted.',
			PRECIO: 'of the price awarded in the final tender.',
			FUNERARIA: 'You pay the contracted service directly to the funeral business.'
		}
	}
};
