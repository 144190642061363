import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de localizaciones
 */
@Injectable()
export class LocalizacionService {
    constructor(private http: HttpClient) { }
 
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/localizacion`);
    }
 
    getByCP(cp: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/localizacion?where={"activo":1,"cpDesde":{"<=":"${cp}"},"cpHasta":{">=":"${cp}"}}`);
    }
 
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/localizacion/${id}`);
    }
}