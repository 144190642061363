import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { DxPopupComponent } from 'devextreme-angular';
import { AuthenticationService, UserService } from '../../../_services';
import { interval, Subscription, Observable } from 'rxjs';
import * as moment from 'moment';
import { DxMapModule, DxCheckBoxModule, DxButtonModule } from 'devextreme-angular';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-admin-interactivo',
  templateUrl: './admin-interactivo.component.html',
  styleUrls: ['./admin-interactivo.component.scss'],
  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)',
        'transform-style': 'revert',
        '-webkit-transform': 'translateZ(1000px)'
      })),
      transition('default => flipped', [
        animate('200ms')
      ]),
      transition('flipped => default', [
        animate('200ms')
      ])
    ])
  ]
})
export class AdminInteractivoComponent implements OnInit {

  @ViewChild(DxMapModule, { static: false }) dataGrid: DxMapModule
  @ViewChild("popupDetalle", { static: false }) popupDetalle: DxPopupComponent

  currentUser: any;
  eventSusc: any;
  userSusc: any;

  vista = "registros";

  servicios: any;
  serviciosHash = {};
  rowsEventos = [];
  rowsUsuarios = [];
  puntosMapa = [];
  puntos = [];
  originalRows = [];
  rowsLicit = [];
  currentRow = null;
  popupVisible = false;

  subscription: Subscription;

  altura;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    this.altura = window.innerHeight-400 + 'px';
    this.mapbox.accessToken = environment.mapBoxToken;

    var cbkServicios = function(res) {
      for(var i = 0; i < res.length; i++){
        res[i].val = false;
        res[i].comentarios = null;
        res[i].importeSalida = null;
        res[i].error = {importeSalida: false};
        res[i].mostrar = false;
        this.serviciosHash[res[i].id+""] = res[i];
      }
      this.servicios = res;
      this.servicios.sort(function (a, b) {
        if (a.orden < b.orden) {
          return -1;
        } else if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      }.bind(this));
    }

    var connectSails = function(){
      if(!self.io.socket) {
        return;
      }
      if(self.io.socket._isConnecting) {
        return;
      }

      self.io.socket.get('/servicio', cbkServicios.bind(this));

      this.loadLicitaciones();

      this.loadEventos();
      this.loadUsuarios();
      
      const source = interval(1000);
      this.subscription = source.subscribe(val => this.procesoCountdown());
    }

    if(!self.io.socket) {

      var reintentar = function(){
        if(!self.io.socket) {
          setTimeout(() => { 
            var fn2 = reintentar.bind(this);
            fn2();
          }, 1000);
        } else {
          if(self.io.socket.isConnected()) {
            var fn = connectSails.bind(this);
            fn();
          } else {
            const socket = self.io.socket;
    
            self.io.socket.on('connect', function onConnect(){
              setTimeout(() => { 
                if(self.io.socket) {
                  var fn = connectSails.bind(this);
                  fn();
                } else {
                  self.io.socket = socket;
                  var fn = connectSails.bind(this);
                  fn();
                }
              }, 500);
            }.bind(this));
          }   
        }
      }

      setTimeout(() => { 
        var fn2 = reintentar.bind(this);
        fn2();
      }, 1000);


      return;
    }

    if(self.io.socket.isConnected()) {
      var fn = connectSails.bind(this);
      fn();
    } else {
      const socket = self.io.socket;

      self.io.socket.on('connect', function onConnect(){
        setTimeout(() => { 
          if(self.io.socket) {
            var fn = connectSails.bind(this);
            fn();
          } else {
            self.io.socket = socket;
            var fn = connectSails.bind(this);
            fn();
          }
        }, 500);
      }.bind(this));
    }    
  }

  loadEventos() {
    console.warn("loadEventos()");

    this.eventSusc = function(msg) {
      console.warn(msg);
      if (this.cdr['destroyed']) {
        return;
      }
      if(msg.verb == "created"){
        
      }
    }

    var loadedEvents = function(events) {
      this.rowsEventos = [];
      console.warn(events);
      for(let event of events) {
        var evento = {
          evento: event.evento,
          usuario: event.user.empresa,
          fecha: event.fecha,
          fechaStr: moment(event.fecha).format("DD/MM/YY HH:mm:ss"),
          detalle: event.detalle 
        };
        this.rowsEventos.push(evento);
      }
      this.rowsEventos = [...this.rowsEventos];
      this.cdr.detectChanges();
    }
    self.io.socket.get('/evento', loadedEvents.bind(this));

    self.io.socket.on('evento', this.eventSusc.bind(this));
  }

  loadUsuarios() {
    console.warn("loadUsuarios()");

    this.userSusc = function(msg) {
      console.warn(msg);
      if (this.cdr['destroyed']) {
        return;
      }
      if(msg.verb == "updated" && msg.data.ultimoLog){
        for(let user of this.rowsUsuarios) {
          if(msg.data.id == user.id) {
            user.evento = msg.data.ultimoLog.evento;
            user.fecha = msg.data.ultimoLog.fecha;
            user.fechaStr =  moment(msg.data.ultimoLog.fecha).format("DD/MM/YY HH:mm:ss");
            break;
          }
        }
        this.rowsUsuarios = [...this.rowsUsuarios];
      }
    }

    var loadedUsers = function(users) {
      this.rowsUsuarios = [];
      console.warn(users);
      for(let user of users) {
        if(user.conectado) {
          var usuario = {
            id: user.id,
            evento: user.ultimoLog.evento,
            usuario: user.empresa,
            fecha: user.ultimoLog.fecha,
            fechaStr: moment(user.ultimoLog.fecha).format("DD/MM/YY HH:mm:ss")
          };
          this.rowsUsuarios.push(usuario);
        }
      }
      this.rowsUsuarios = [...this.rowsUsuarios];
      this.cdr.detectChanges();
    }
    self.io.socket.get('/user', {demo: 0, status: "ACTIVO"}, loadedUsers.bind(this));

    self.io.socket.on('user', this.userSusc.bind(this));
  }

  verMapa() {
    this.loadMapa();
  }

  verRegistros() {
    this.vista = "registros";
    this.cdr.detectChanges();
  }

  loadMapa() {
    this.originalRows = [];
    this.puntosMapa = [];

    var loadedLicitaciones = function(resData){
      //this.originalRows = resData;
      //this.originalRows.concat(resData);
      this.originalRows.push(...resData);
      
      for(var i = 0; i < this.originalRows.length; i++) {
        this.originalRows[i].estado = "ABIERTA";
        this.originalRows[i].fecha = moment(this.originalRows[i].startDate).format("DD/MM/YYYY");
        this.originalRows[i].fechaFin = moment(this.originalRows[i].endDate).format("DD/MM/YYYY HH:mm");
        if(this.originalRows[i].ultimaLicitacion){
          this.originalRows[i].precio = this.originalRows[i].ultimaLicitacion.importe;
        } else {
          this.originalRows[i].precio = this.originalRows[i].importeSalida;
        }
        var ms = moment(this.originalRows[i].endDate).diff();
      
        if(ms < 0){
          this.originalRows[i].estado = "CERRADA";
          this.originalRows[i].days = "0 días";
          this.originalRows[i].countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var hours = Math.floor(d.asHours());
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + moment.utc(ms).format(":mm:ss");
          this.originalRows[i].countdown = s;
        }

        let licit = this.originalRows[i];
        let icon = "https://cdn-icons-png.flaticon.com/512/684/684908.png";

        if(licit.estado == "CERRADA") {
          icon = "https://cdn-icons.flaticon.com/png/512/837/premium/837417.png?token=exp=1646611813~hmac=74a652942e421d6775cbd00c3ca828cf";
        }

        if(licit.tipo == "SERVICIO") {
          this.puntosMapa.push({
            latitud: licit.latitudOrigen,
            longitud: licit.longitudOrigen,
            descripcion: '<b>' + licit.referencia + '</b><br>' + licit.tipo,
            tipo: licit.tipo,
            obj: licit
          })
        } else {
          this.puntosMapa.push({
            latitud: licit.latitudDestino,
            longitud: licit.longitudDestino,
            descripcion: '<b>' + licit.referencia + '</b><br>' + licit.tipo,
            tipo: licit.tipo,
            obj: licit
          })
        }

        
        
      }


      //const source = interval(1000);
      //this.subscription = source.subscribe(val => this.procesoCargaMapa());

      this.loadMapaVista();

      //this.vista = "mapa";
      //this.cdr.detectChanges();
    }

    if(this.puntosMapa.length == 0) self.io.socket.get('/licitacion', {demo: false, abierta: true}, loadedLicitaciones.bind(this));

  }

  mapbox = (mapboxgl as typeof mapboxgl);
  style = `mapbox://styles/mapbox/streets-v11`;

  map: mapboxgl.Map;
  licit = null;

  loadMapaVista() {
    this.vista = "mapa";
    this.cdr.detectChanges();

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 7,
      center: [-3.0149625602118553, 43.31193308383577]
    });
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('draw.create', function (e) {
      if (e.features.length && e.features[0].geometry.type == 'Point') {
        var center = e.features[0].geometry.coordinates;
        //map.setCenter(center);
        this.map.easeTo({center: center});
      }
    })

    for(let punto of this.puntosMapa) {
      if(punto.longitud && punto.latitud) {
        /*const marker1 = new mapboxgl.Marker()
        .setLngLat([punto.longitud, punto.latitud])
        .addTo(this.map);*/

        let marker;

        if(punto.tipo == "WFP") {
          marker = new mapboxgl.Marker({ color: "#FF0000" })
          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
          
        } else if(punto.tipo == "SERVICIO") {
          marker = new mapboxgl.Marker({ color: "#0000FF" })
          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
        } else if(punto.tipo == "AMBOS") {
          marker = new mapboxgl.Marker({ color: "rgb(0 111 139)" })
          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
        } else {
          marker = new mapboxgl.Marker()
          .setLngLat([punto.longitud, punto.latitud])
          .addTo(this.map);
        }
        

        marker.getElement().addEventListener('click', (e) => {
          console.warn(e);
          
          console.warn(punto);
          this.licit = punto.obj;

          this.popupVisible = true;

        });




      }
    }

    this.map.on('click', (e) => {
      console.warn(e);
    });

  }

  indexCarga = 0;

  procesoCargaMapa() {
    console.warn("MAPA");

    console.warn(this.puntos);

    if(this.indexCarga < this.puntosMapa.length) {
      for(var i = this.indexCarga; i < this.puntosMapa.length && i < this.indexCarga + 5; i++) {
        this.puntos.push(this.puntosMapa[i]);
      }
      this.indexCarga += 5;
    } else {
      this.subscription.unsubscribe();
    }
    this.cdr.detectChanges();

    

  }

  /**
   * Carga las licitaciones en base a la vista actual y se suscribe a sus 
   * cambios para actualizar la vista en pantalla (precios, edicion, etc.)
   */
   loadLicitaciones() {
    if(this.subscription) this.subscription.unsubscribe();
    // - EVENTOS -
    this.eventSusc = function(msg) {
      if (this.cdr['destroyed']) {
        return;
      } if(msg.verb == "created"){
        if(msg.data.demo) return;
        if(this.modoPlataforma == "WFN" && msg.data.tipo == "WFP") return;
        if(this.modoPlataforma == "WFP" && msg.data.tipo != "WFP") return;

        msg.data.estado = "ABIERTA";
        msg.data.fecha = moment(msg.data.startDate).format("DD/MM/YYYY");
        msg.data.fechaFin = moment(msg.data.endDate).format("DD/MM/YYYY HH:mm");
        if(msg.data.ultimaLicitacion){
          msg.data.precio = msg.data.ultimaLicitacion.importe;
        } else {
          msg.data.precio = msg.data.importeSalida;
        }
        var ms = moment(msg.data.endDate).diff();
      
        if(ms < 0){
          msg.data.estado = "CERRADA";
          msg.data.days = "0 días";
          msg.data.countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var days = Math.floor(d.asDays());
          var daysStr = days + "";    
          if(this.currentUser.status == 'ADMIN') {
            if(days > 0) msg.data.days = daysStr + " d";
          } else msg.data.days = daysStr + " días";
          var hours = Math.floor(d.asHours()) - (days*24);
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
          msg.data.countdown = s;
        }

        //Comprobamos si pertenece a nuestra suscripción
        if(this.currentUser.proveedor && this.router.url === '/licitaciones' && this.currentUser.id != msg.data.creador.id && this.currentUser.suscripcion) {
          var enc = false;
          var enc2 = false;
          if(this.currentUser.suscripcion.paises.length > 0) {
            for(let pais of this.currentUser.suscripcion.paises) {
              if(pais.id == msg.data.paisOrigen.id) enc = true;
              if(msg.data.paisDestino && pais.id == msg.data.paisDestino.id) enc2 = true;
            }
          } else if(this.currentUser.suscripcion.provincias.length > 0) {
            for(let provincia of this.currentUser.suscripcion.provincias) {
              if(provincia.id == msg.data.provinciaOrigen.id) enc = true;
              if(msg.data.provinciaDestino && provincia.id == msg.data.provinciaDestino.id) enc2 = true;
            }
          } else {
            enc = true;
            enc2 = true;
          }
          if(msg.data.tipo == "SERVICIO") enc2 = true;
          if(enc && enc2) msg.data.suscripcion = true;
        } else {
          msg.data.suscripcion = true;
        }
        msg.state = "default";

        this.rowsLicit = [...this.rowsLicit, msg.data];
        this.filterDatatable();
        this.cdr.detectChanges();
      } else if(msg.verb == "updated"){
        if(msg.data.ultimaLicitacion){
          var loadLicit = function(res){
            for(var i = 0; i < this.rowsLicit.length; i++){
              if(this.rowsLicit[i].id == msg.data.id){
                this.rowsLicit[i].precio = res.importe;
                this.rowsLicit[i].ultimaLicitacion = res;
                this.rowsLicit[i].ultimaLicitacion.user = res.user.id;
                this.filterDatatable();
                break;
              }
            }
          }
          self.io.socket.get('/licita/'+msg.data.ultimaLicitacion, loadLicit.bind(this));
        } else if(msg.data.borrada) { //Licitación eliminada
          for(var i = 0; i < this.rowsLicit.length; i++){
            if(this.rowsLicit[i].id == msg.data.id){
              this.rowsLicit[i].borrada = true;
              this.filterDatatable();
              break;
            }
          }
        } else if(msg.data.validada || msg.data.trazas) {  //Si se ha validado una licitación. La cargamos de nuevo.
          for(var i = 0; i < this.rowsLicit.length; i++){
            if(this.rowsLicit[i].id == msg.data.id){
              self.io.socket.get('/licitacion/' + msg.data.id, function (resData) {
                if(msg.data.validada) {
                  if(msg.data.licitacionElegida) this.rowsLicit[i].licitacionElegida = resData.licitacionElegida;
                  if(msg.data.usuarioElegido) this.rowsLicit[i].usuarioElegido = resData.usuarioElegido;
                  this.rowsLicit[i].validada = true;
                }
                if(msg.data.trazas) this.rowsLicit[i].trazas = resData.trazas;

                if(this.rowsLicit[i].trazas) { //Esto es que ya está validado
                  var trazas = JSON.parse(this.rowsLicit[i].trazas);
                  this.rowsLicit[i].trazabilidad = [];
                  this.cdr.detectChanges();
                  var comp = 0;
                  if(this.row && this.row.id == msg.data.id) this.trazas = [];
                  for(let traza in trazas){
                    if(trazas[traza].completado) {
                      comp++;
                    }
                    trazas[traza].codigo = traza;
                    if(this.row && this.row.id == msg.data.id) this.trazas.push(trazas[traza]);
                    this.rowsLicit[i].trazabilidad.push(trazas[traza]);
                  }
                  this.rowsLicit[i].trazas_comp = comp;
                }
                this.filterDatatable();
              }.bind(this));


              break;
            }
          }
        } else if(msg.data.endDate && !msg.data.importeSalida) { //Si se ha alargado el tiempo
          for(var i = 0; i < this.rowsLicit.length; i++){
            if(this.rowsLicit[i].id == msg.data.id){
              this.rowsLicit[i].endDate = msg.data.endDate;


              this.filterDatatable();
              break;
            }
          }
        } else if(msg.data.id && msg.data.importeSalida) {
          self.io.socket.get('/licitacion/' + msg.data.id, function (resData) {
            resData.estado = "ABIERTA";
            resData.fecha = moment(resData.startDate).format("DD/MM/YYYY");
            resData.fechaFin = moment(resData.endDate).format("DD/MM/YYYY HH:mm");
            if(resData.ultimaLicitacion){
              resData.precio = resData.ultimaLicitacion.importe;
            } else {
              resData.precio = resData.importeSalida;
            }

            //Comprobamos si pertenece a nuestra suscripción
            if(this.currentUser.proveedor && this.router.url === '/licitaciones' && this.currentUser.id != resData.creador.id && this.currentUser.suscripcion) {
              /*var enc = false;
              if(this.currentUser.suscripcion.paises.length > 0) {
                for(let pais of this.currentUser.suscripcion.paises) {
                  if(pais.id == resData.paisOrigen.id || (resData.paisDestino && pais.id == resData.paisDestino.id)) enc = true;
                }
              } else if(this.currentUser.suscripcion.provincias.length > 0) {
                for(let provincia of this.currentUser.suscripcion.provincias) {
                  if(provincia.id == resData.provinciaOrigen.id || (resData.provinciaDestino && provincia.id == resData.provinciaDestino.id)) enc = true;
                }
              } else {
                enc = true;
              }
              if(enc) resData.suscripcion = true;*/

              var enc = false;
              var enc2 = false;
              if(this.currentUser.suscripcion.paises.length > 0) {
                for(let pais of this.currentUser.suscripcion.paises) {
                  if(pais.id == resData.paisOrigen.id) enc = true;
                  if(resData.paisDestino && pais.id == resData.paisDestino.id) enc2 = true;
                }
              } else if(this.currentUser.suscripcion.provincias.length > 0) {
                for(let provincia of this.currentUser.suscripcion.provincias) {
                  if(provincia.id == resData.provinciaOrigen.id) enc = true;
                  if(resData.provinciaDestino && provincia.id == resData.provinciaDestino.id) enc2 = true;
                }
              } else {
                enc = true;
                enc2 = true;
              }
              if(resData.tipo == "SERVICIO") enc2 = true;
              if(enc && enc2) resData.suscripcion = true;
            } else {
              resData.suscripcion = true;
            }

            for(var i = 0; i < this.rowsLicit.length; i++){
              if(this.rowsLicit[i].id == msg.data.id){
                this.rowsLicit[i] = resData;
              }
            }
            this.filterDatatable();
          }.bind(this));
        }
      }
    }

    this.rowsLicit = [];

    var loadedLicitaciones = function(resData){
      this.rowsLicit.push(...resData);
      
      for(var i = 0; i < this.rowsLicit.length; i++) {
        this.rowsLicit[i].estado = "ABIERTA";
        this.rowsLicit[i].fecha = moment(this.rowsLicit[i].startDate).format("DD/MM/YYYY");
        this.rowsLicit[i].fechaFin = moment(this.rowsLicit[i].endDate).format("DD/MM/YYYY HH:mm");
        if(this.rowsLicit[i].ultimaLicitacion){
          this.rowsLicit[i].precio = this.rowsLicit[i].ultimaLicitacion.importe;
        } else {
          this.rowsLicit[i].precio = this.rowsLicit[i].importeSalida;
        }
        var ms = moment(this.rowsLicit[i].endDate).diff();
      
        if(ms < 0){
          this.rowsLicit[i].estado = "CERRADA";
          this.rowsLicit[i].days = "0 días";
          this.rowsLicit[i].countdown = "00 h 00 min 00 s";
        } else {
          var d = moment.duration(ms);
          var hours = Math.floor(d.asHours());
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + moment.utc(ms).format(":mm:ss");
          this.rowsLicit[i].countdown = s;
        }

        this.rowsLicit[i].suscripcion = true;
        
        this.rowsLicit[i].state = "default";

        if(this.rowsLicit[i].trazas) { //Esto es que ya está validado
          var trazas = JSON.parse(this.rowsLicit[i].trazas);
          this.rowsLicit[i].trazabilidad = [];
          this.cdr.detectChanges();
          var comp = 0;
          for(let traza in trazas){
            if(trazas[traza].completado) {
              comp++;
            }
            trazas[traza].codigo = traza;
            this.rowsLicit[i].trazabilidad.push(trazas[traza]);
          } 
          this.rowsLicit[i].trazas_comp = comp;
        }

        if(this.rowsLicit[i].validada && this.rowsLicit[i].pujas && this.rowsLicit[i].licitacionElegida) {
          //Cogemos el precio del elegido, por si es superior a la última.
          this.rowsLicit[i].precio = this.rowsLicit[i].licitacionElegida.importe;
        }
      }
      this.cdr.detectChanges();

      console.warn(this.rowsLicit);
    }

    self.io.socket.get('/licitacion', {demo: false, abierta: true}, loadedLicitaciones.bind(this));
      

    /*if(this.router.url === '/licitaciones') {
      self.io.socket.get('/licitacion', {abierta: 1}, loadedLicitaciones.bind(this));
      self.io.socket.get('/licitacion', {abierta: 0, validada: 0}, loadedLicitaciones.bind(this));
      self.io.socket.get('/licitacion', {abierta: 0, validada: 1, licitacionElegida: { '!=' : null }}, loadedLicitaciones.bind(this));
    } else {
      self.io.socket.get('/licitacion', loadedLicitaciones.bind(this));
    }*/

    self.io.socket.on('licitacion', this.eventSusc.bind(this));
  }

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num)
  {
    if(num){ 
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return num_parts.join(".");
    } else {
      return '';
    }
    
  }

  /**
   * Muestra el detalle del servicio seleccionado.
   * @param serv Servicio a mostrar
   * @param licit Licitación sobre la que mostrar el detalle
   */
   detalleServicio(serv, licit) {
    if(this.translate.currentLang == "es") {
      if(serv) {  //SERVICIO
        licit.detalleServicio = {id: serv.id, nombre: this.serviciosHash[serv.id].nombre, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle, comentarios: serv.comentarios, tipo: serv.tipo, cerem: serv.cerem, multiple: serv.multiple, detalleWFP: serv.detalle, detalleOtros: serv.detalleOtros};
      } else {  //TRASLADO
        licit.detalleServicio = {id: null, nombre: "Traslado", abreviatura: "TRASLADO", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
      }
    } else if(this.translate.currentLang == "en") {
      if(serv) {  //SERVICIO
        licit.detalleServicio = {id: serv.id, nombre: this.serviciosHash[serv.id].nombre_en, abreviatura: serv.abreviatura, detalle: this.serviciosHash[serv.id].detalle_en, comentarios: serv.comentarios, tipo: serv.tipo, cerem: serv.cerem, multiple: serv.multiple, detalleWFP: serv.detalle, detalleOtros: serv.detalleOtros};
      } else {  //TRASLADO
        licit.detalleServicio = {id: null, nombre: "Transfer", abreviatura: "TRASLADO", detalle: "Ut ut quidenis id quia voluptatur maiorem quaspel ecerspe rspelique verum venes que doluptius, to cum ea num exeri deroribus, optation porum comnihicipsa velis quistrum rerrovit as volo et oditiunt eumquam ut voluptas ipsam eaquiamet fuga. Eruntorio et aliquibus ist doluptam, sae viti solore volum ut lit landent into odicid et etur.", comentarios: licit.comentarios};
      }
    }

    licit.state = "flipped";
    this.cdr.detectChanges();

    setTimeout(() => { 
      licit.mostrarDetalleServicio = true;
      this.cdr.detectChanges();
    }, 100);

    
  }

  /**
   * Oculta el detalle del servicio mostrado
   * @param licit Licitación a la que ocultar el detalle
   */
  ocultarServicio(licit: any) {
    licit.mostrarDetalleServicio = false;
    licit.state='default';
    this.cdr.detectChanges();
  }

  /**
   * Oculta el detalle de la aseguradora
   * @param licit Licitación a la que ocultar el detalle
   */
  ocultarAseguradora(licit: any) {
    licit.mostrarAseguradora = false;
    licit.state='default';
    this.cdr.detectChanges();
  }

  /**
   * Muestra el detalle de la aseguradora.
   * @param licit Licitación sobre la que mostrar el detalle
   */
  mostrarAseguradora(licit: any) {
    licit.mostrarAseguradora = true;
    licit.state='flipped';
    this.cdr.detectChanges();
  }


  proceso = 0;
  /**
   * Proceso que se lanza cada segundo para actualizar la cuenta atras
   * y los estados mostrados de las diferentes cards.
   */
  procesoCountdown(){
    for(var i = 0; i < this.rowsLicit.length; i++) {
      var msStart = moment(this.rowsLicit[i].startDate).diff();
      

      if(msStart > 0){
        this.rowsLicit[i].estado = "ABRIENDO";
        var d = moment.duration(msStart);
        var days = Math.floor(d.asDays());
        var daysStr = days + "";    
        if(this.currentUser.status == 'ADMIN') {
          if(days > 0) this.rowsLicit[i].days = daysStr + " d";
        } else this.rowsLicit[i].days = daysStr + " días";
        var hours = Math.floor(d.asHours()) - (days*24);
        var hoursStr = "";
        if (hours < 10) hoursStr = "0" + hours
        else hoursStr = hours + "";
        var s = hoursStr + " h " + moment.utc(msStart).format("mm") + " min " + moment.utc(msStart).format("ss") + " s";
        this.rowsLicit[i].countdown = s;
      } else {
        var ms = moment(this.rowsLicit[i].endDate).diff();
      
        if(ms < 0){
          if(!this.rowsLicit[i].validada){
            this.rowsLicit[i].estado = "CERRADA_PEND";
          } else {
            this.rowsLicit[i].estado = "CERRADA";
          }
          if(this.rowsLicit[i].abierta) {
            if(this.currentRow && this.currentRow.id == this.rowsLicit[i].id) {
              this.currentRow = null;
            }
            this.rowsLicit[i].abierta = false;
            
            if(this.currentUser.status == 'ADMIN') this.rowsLicit[i].days = "0 d"
            else this.rowsLicit[i].days = "0 días";
            this.rowsLicit[i].countdown = "00 h 00 min 00 s";
          }
          if(!this.rowsLicit[i].validada && !this.rowsLicit[i].ultimaLicitacion) {
            this.rowsLicit[i].validada = true; //La ponemos como desierta aunque no lo esté aún por el sistema.
          }
        } else {
          if(ms < 60000 && this.rowsLicit[i].tipo != "WFP") this.rowsLicit[i].ultimoMinuto = true
          else this.rowsLicit[i].ultimoMinuto = false;
          this.rowsLicit[i].estado = "ABIERTA";
          var d = moment.duration(ms);
          var days = Math.floor(d.asDays());
          var daysStr = days + "";    
          if(this.currentUser.status == 'ADMIN') {
            if(days > 0) this.rowsLicit[i].days = daysStr + " d";
          } else this.rowsLicit[i].days = daysStr + " días";
          var hours = Math.floor(d.asHours()) - (days*24);
          var hoursStr = "";
          if (hours < 10) hoursStr = "0" + hours
          else hoursStr = hours + "";
          var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
          this.rowsLicit[i].countdown = s;
        }
      }
      if(!this.rowsLicit[i].state) this.rowsLicit[i].state = "default";


      
    }
    this.proceso++;
    this.cdr.detectChanges();

    if(this.licit) this.procesoCountdown2();
  }

/**
   * Proceso que se lanza cada segundo para actualizar la cuenta atras
   * y los estados mostrados de las diferentes cards.
   */
 procesoCountdown2(){
    var msStart = moment(this.licit.startDate).diff();
    

    if(msStart > 0){
      this.licit.estado = "ABRIENDO";
      var d = moment.duration(msStart);
      var days = Math.floor(d.asDays());
      var daysStr = days + "";    
      if(this.currentUser.status == 'ADMIN') {
        if(days > 0) this.licit.days = daysStr + " d";
      } else this.licit.days = daysStr + " días";
      var hours = Math.floor(d.asHours()) - (days*24);
      var hoursStr = "";
      if (hours < 10) hoursStr = "0" + hours
      else hoursStr = hours + "";
      var s = hoursStr + " h " + moment.utc(msStart).format("mm") + " min " + moment.utc(msStart).format("ss") + " s";
      this.licit.countdown = s;
    } else {
      var ms = moment(this.licit.endDate).diff();
    
      if(ms < 0){
        if(!this.licit.validada){
          this.licit.estado = "CERRADA_PEND";
        } else {
          this.licit.estado = "CERRADA";
        }
        if(this.licit.abierta) {
          if(this.currentRow && this.currentRow.id == this.licit.id) {
            this.currentRow = null;
          }
          this.licit.abierta = false;
          
          if(this.currentUser.status == 'ADMIN') this.licit.days = "0 d"
          else this.licit.days = "0 días";
          this.licit.countdown = "00 h 00 min 00 s";
        }
        if(!this.licit.validada && !this.licit.ultimaLicitacion) {
          this.licit.validada = true; //La ponemos como desierta aunque no lo esté aún por el sistema.
        }
      } else {
        if(ms < 60000 && this.licit.tipo != "WFP") this.licit.ultimoMinuto = true
        else this.licit.ultimoMinuto = false;
        this.licit.estado = "ABIERTA";
        var d = moment.duration(ms);
        var days = Math.floor(d.asDays());
        var daysStr = days + "";    
        if(this.currentUser.status == 'ADMIN') {
          if(days > 0) this.licit.days = daysStr + " d";
        } else this.licit.days = daysStr + " días";
        var hours = Math.floor(d.asHours()) - (days*24);
        var hoursStr = "";
        if (hours < 10) hoursStr = "0" + hours
        else hoursStr = hours + "";
        var s = hoursStr + " h " + moment.utc(ms).format("mm") + " min " + moment.utc(ms).format("ss") + " s";
        this.licit.countdown = s;
      }
    }
    if(!this.licit.state) this.licit.state = "default";


    
  this.cdr.detectChanges();
}

  cerrarPopup() {
    this.popupVisible = false;
  }

}
