import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { LocalizacionService, AuthenticationService, StateService, LicitaService, UserService, NotificacionService, CertificadoService, PrecioService, SolicitudService, CentroService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { TranslationService } from '../../../core/_base/layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxHtmlEditorComponent } from 'devextreme-angular';

// Declare ga function as ambient
declare var ga:Function;
declare var gtag:Function;



declare function initDropdown(): any;
/**
 * Componente que contiene el proceso de registro de licitación particular.
 * @author Informática Integral Vasca
 */
 @Component({
  selector: 'kt-admin-wfp-presupuesto',
  templateUrl: './admin-wfp-presupuesto.component.html',
  styleUrls: ['./admin-wfp-presupuesto.component.scss']
})
export class AdminWfpPresupuestoComponent implements OnInit {

  mapbox = (mapboxgl as typeof mapboxgl);    

  /** Variables globales */
  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent
  @ViewChild('locSwal', {static: false}) private locSwal: SwalComponent
  @ViewChild('emailSwal', {static: false}) private emailSwal: SwalComponent
  @ViewChild('content8', {static: false}) private modalCentro: NgbModal
  @ViewChild('content9', {static: false}) private modalNegocio: NgbModal
  
  model: any = {};
  currentUser: any;
  solicitudId = null;
  solicitudObj = null;

  objectId = null;
  dealId = null;

  tarifaPar = null;

  paises: any;
  paisesHash = {};
  paisesHashCode = {};
  estados: any;
  estadosHash = {};
  estadosAll: any;
  tarifas = [];
  tarifasHash = {};
  provinciasSel = [];
  provinciasSel_tanat = [];
  mostrarAdicionales = false;

  tanatorios = [];
  crematorios = [];
  centros = [];
  allCentros = [];
  loadingCentros = false;
  tanatorio = null;
  crematorio = null;

  negocios = [];
  allNegocios = [];

  licitacion = null;
  
  loading = false;
  step = 1;
  version = null;

  wiz1Form: FormGroup;

  vista = "presupuesto";
  idGeocoder = "geocoder";

  geocoderObj;


	submittedWiz1 = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private ngZone: NgZone,
    private location: Location,
    private userService: UserService,
    private notificacionService: NotificacionService,
    private precioService: PrecioService,
		private translate: TranslateService,
		private translationService: TranslationService,
    private licitaService: LicitaService,
    private localizacionService: LocalizacionService,
    private centroService: CentroService,
    private locationSt: LocationStrategy,
    private certificadoService: CertificadoService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
      history.pushState(null, null, window.location.href);

      // check if back or forward button is pressed.
      this.locationSt.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
	  }

  /**
   * Método que se lanza en el inicio del componente y carga los formularios y los maestros necesarios.
   * Comprueba si hay login y si hay licitación abierta.
   */
	ngOnInit() {
    console.warn("<<<<<<<<INIT>>>>>>>>");

    this.idGeocoder = "geocoder" + moment().unix();

    this.route.paramMap.subscribe(params => {
			var lang = params.get("lang");

      this.translationService.setLanguage(lang);

      var currentRouteUrl = this.router.url;

      this.version = "v1";//this.abTestsService.getVersion();

      if(currentRouteUrl.includes("/entierro")) this.continuarPagina1("ENTIERRO")
      else if(currentRouteUrl.includes("/incineracion")) this.continuarPagina1("INCINERACION");

      const geocoder = new MapboxGeocoder({
        accessToken: environment.mapBoxToken,
        placeholder: 'Buscar',
        proximity: "ip",
        language: "es",
        countries: "ES",
        types: 'place, postcode'
      });
      
      const geocoder2 = new MapboxGeocoder({
        accessToken: environment.mapBoxToken,
        placeholder: 'Buscar',
        proximity: "ip",
        language: "es",
        countries: "ES",
        types: 'place, postcode'
      });

      /*this.solicitudService.getFicheroPresupuesto("2200004")
            .subscribe(loc => {
              console.error(loc);
                this.downloadPDF(loc.file);
              
            });*/

      setTimeout(function(){
        geocoder.addTo('#' + this.idGeocoder);
        geocoder2.addTo('#geocoder2');
      }.bind(this), 1000);      

      // Add geocoder result to container.
      geocoder.on('result', (e) => {
        console.warn(e);
        this.paso2.localizacion = e.result.place_name_es;
        this.paso2.cp = null;
        this.paso2.lat = e.result.center[1];
        this.paso2.long = e.result.center[0];
        this.tipoServicio = "";
        this.packSeleccionado = null;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }

        if(e.result.place_type[0] != "postcode") {
          this.userService.getPostalCode(this.paso2.long, this.paso2.lat)
          .subscribe(res => {
            if(res.features && res.features.length > 0 ) {
              this.paso2.cp = res.features[0].text_es;

              this.localizacionService.getByCP(this.paso2.cp)
              .subscribe(loc => {
                if(loc.length > 0) {
                  this.localizacionObj = loc[0];
                } else {
                  this.errorSwal.fire();
                  this.paso2.localizacion = null;
                  this.paso2.cp = null;
                  this.paso2.lat = null;
                  this.paso2.long = null;
                  geocoder.clear();
                } 

              });
            }
          });
        } else {
          this.paso2.cp = e.result.text_es;

          this.localizacionService.getByCP(this.paso2.cp)
            .subscribe(loc => {
              if(loc.length > 0) {
                this.localizacionObj = loc[0];
              } else {
                this.errorSwal.fire();
                this.paso2.localizacion = null;
                this.paso2.cp = null;
                this.paso2.lat = null;
                this.paso2.long = null;
                geocoder.clear();
              } 

            });
        }
      });


        
      // Clear results container when search is cleared.
      geocoder.on('clear', () => {
        this.paso2.localizacion = null;
        this.paso2.cp = null;
        this.paso2.lat = null;
        this.paso2.long = null;
        this.tipoServicio = "";
        this.packSeleccionado = null;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      });
      

      // Add geocoder result to container.
      geocoder2.on('result', (e) => {
        this.paso2.localizacionFallecido = e.result.place_name_es;
        this.paso2.cpFallecido = null;
        this.paso2.latFallecido = e.result.center[1];
        this.paso2.longFallecido = e.result.center[0];
        this.tipoServicio = "";
        this.packSeleccionado = null;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }

        if(e.result.place_type[0] != "postcode") {
          this.userService.getPostalCode(this.paso2.longFallecido, this.paso2.latFallecido)
          .subscribe(res => {
            if(res.features && res.features.length > 0 ) {
              this.paso2.cpFallecido = res.features[0].text_es;
            }
          });
        } else {
          this.paso2.cp = e.result.text_es;
        }
      });
        
        // Clear results container when search is cleared.
      geocoder2.on('clear', () => {
        this.paso2.localizacionFallecido = null;
        this.paso2.cpFallecido = null;
        this.paso2.latFallecido = null;
        this.paso2.longFallecido = null;
        this.tipoServicio = "";
        this.packSeleccionado = null;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      });

      this.geocoderObj = geocoder;

      this.solicitudService.getHubspotActiveDeals()
        .subscribe(deals => {
          console.warn(deals);
          for(let deal of deals.results) {
            deal.properties.fechaStr = moment(deal.properties.createdate).format("DD/MM/YY HH:mm");
          }
          this.allNegocios = deals.results;
          this.filterDatatable2();
        });

    });
  }

  ngOnDestroy() {
    /*geocoder.off('result', () => {
      console.log("DESTROY OK");
    });
    geocoder.off('clear', () => {});
    geocoder2.off('result', () => {});
    geocoder2.off('clear', () => {});*/

    console.log("DESTROY");
    this.cdr.detach(); // do this


    // for me I was detecting changes using `detectChanges()` inside a subscription with `subscribe()` to observable without limitation, so was enough for me to just `unsubscribe()` like the following line;
    // this.authObserver.unsubscribe();
  }

  cambioTraslado() {
    this.tipoServicio = "";
    this.packSeleccionado = null;
    this.cdr.detectChanges();
  }

  siguiente1() {
    this.step++;
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 100);
    this.cdr.detectChanges();
  }

  /**
   * Método para el cambio de página del wizard.
   * Comprueba en cada paso si todos los requisitos se cumplen.
   */
  siguiente() {

    if(this.step == 1) {
      this.siguiente1();
    } else if(this.step == 2) {
      if(!this.solicitudId) { // Si no se ha guardado todavia la solicitud
        var solicitud = {
          telefono: this.paso2.telefono,
          email: this.paso2.email,
          status: "PROCESO",
          lang: this.translate.currentLang,
          nombre: this.paso2.nombre,
          localizacion: this.paso2.localizacion,
          cp: this.paso2.cp,
          lat: this.paso2.lat,
          long: this.paso2.long,
          traslado: this.paso2.traslado == "SI" ? true : false,
          localizacionFallecido: this.paso2.localizacionFallecido,
          cpFallecido: this.paso2.cpFallecido,
          latFallecido: this.paso2.latFallecido,
          longFallecido: this.paso2.longFallecido,
          distancia: this.paso2.distancia,
          tipo: this.tipoServicio,
          velatorio: null,
          flores: null,
          personalizado: null,
          importe: null,
          divisa: null,
          version: this.version,
          oculta: false,
          enviado: false
        }
        //paso2 = {nombre: "TEST", localizacion: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: "test@test.com", telefono: "666555444", politica: true} as any;

        
        /*this.gtmService.pushTag({
          event: 'generate_lead',
          event_category: 'generate_lead',
          'event_label': 'embudo'
        });*/
        
        this.solicitudService.post(solicitud)
        .pipe(first())
        .subscribe(
          data => {
            this.solicitudId = data.id;
            this.loading = false;
            this.siguiente1();
          },
          error => {
            console.error(error);
            this.loading = false;
          });

          var infor = {
            "properties": {
                "email": this.paso2.email,
                "firstname": this.paso2.nombre,
                "phone": this.paso2.telefono,
                "localizacion": this.paso2.localizacion,
                "cp": this.paso2.cp,
                "lat":this.paso2.lat,
                "long":this.paso2.long,
              }
          };
          this.solicitudService.postHubspot(infor)
          .subscribe(
            data => {
              this.objectId = data;

              var inforDeal = {
                "properties": {
                    "dealname": "Presupuesto - " + this.paso2.email,
                    //"dealstage": "24509206",//"24059319",
                    /*"servicios":serviciosStr,
                    "amount": this.totalServicios,
                    "flores": flores,
                    "velatorio": velatorio,
                    "personalizado": personalizado,*/
                    "nombre_contacto": this.paso2.nombre,
                    "origen": "proceso",
                    "estado" : "PROCESO",
                    "tipo" : this.tipoServicio,
                    "dealstage":"28252536",
                    "pipeline":"9819966",
                    "cp": this.paso2.cp,
                    "lat": this.paso2.lat,
                    "long": this.paso2.long,
                    "localizacion": this.paso2.localizacion
                  }
              };
              this.solicitudService.postHubspotDeal(inforDeal)
              .pipe(first())
              .subscribe(
                dataD => {
                  this.dealId = dataD;
                  var inform = {
                    "objectId": this.objectId,
                    "dealId": this.dealId,
                  };
                  this.solicitudService.postAssociation(inform)
                  .pipe(first())
                  .subscribe(
                    error => {
                      console.error(error);
                    }
                  );
                },
                error => {
                  console.log(error)
                }
              );
            },
            error => {
              console.error(error);
            });

        //******** */
      
        


      } else {  // Si no es la primera vez, actualizamos
        var solicitudUpd = {
          id: this.solicitudId,
          telefono: this.paso2.telefono,
          email: this.paso2.email,
          status: "PROCESO",
          lang: this.translate.currentLang,
          nombre: this.paso2.nombre,
          localizacion: this.paso2.localizacion,
          cp: this.paso2.cp,
          lat: this.paso2.lat,
          long: this.paso2.long,
          traslado: this.paso2.traslado == "SI" ? true : false,
          localizacionFallecido: this.paso2.localizacionFallecido,
          cpFallecido: this.paso2.cpFallecido,
          latFallecido: this.paso2.latFallecido,
          longFallecido: this.paso2.longFallecido,
          distancia: this.paso2.distancia,
          tipo: this.tipoServicio,
          velatorio: null,
          flores: null,
          personalizado: null,
          importe: null,
          divisa: null,
          oculta: false,
          enviado: false
        }
        //paso2 = {nombre: "TEST", localizacion: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: "test@test.com", telefono: "666555444", politica: true} as any;

        this.solicitudService.update(solicitudUpd)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.siguiente1();
          },
          error => {
            console.error(error);
            this.loading = false;
          });

          var inforDealUpd = {
            "properties": {
                "dealname": "Presupuesto - " + this.paso2.email,
                //"dealstage": "24509206",//"24059319",
                "estado" : "PROCESO",
                "origen": "proceso",
                "tipo" : this.tipoServicio,
                "dealstage":"28252536",
                "pipeline":"9819966",
                "cp": this.paso2.cp,
                "lat": this.paso2.lat,
                "long": this.paso2.long,
                "localizacion": this.paso2.localizacion
              },
            id: this.dealId
          };
          this.solicitudService.updateHubspotDeal(inforDealUpd)
          .pipe(first())
          .subscribe(
            dataD => {
              console.log("OK UPDATE", dataD);
            },
            error => {
              console.log(error)
            }
          );
      }
      
    } else if(this.step == 3) {
      var solicitudUpd2 = {
        id: this.solicitudId,
        velatorio: this.packSeleccionado >= 2 ? true : false,
        flores: this.packSeleccionado == 3 ? true : false,
        personalizado: this.vistaPersonalizada,
        importe: this.totalServicios,
        divisa: "EUR"
      }
      //paso2 = {nombre: "TEST", localizacion: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: "test@test.com", telefono: "666555444", politica: true} as any;

      this.solicitudService.update(solicitudUpd2)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          this.siguiente1();
        },
        error => {
          console.error(error);
          this.loading = false;
        });

        let flores = "";
        if(this.packSeleccionado == 3){
          flores = "SI"
        }
        else{
          flores = "NO"
        }

        let velatorio = "";
        if(this.packSeleccionado >= 2){
          velatorio = "SI"
        }
        else{
          velatorio = "NO"
        }


        let personalizado = "";
        if(this.vistaPersonalizada){
          personalizado = "SI"
        }
        else{
          personalizado = "NO"
        }

        var inforDealUpd2 = {
          "properties": {
              "amount": this.totalServicios,
              "flores": flores,
              "velatorio": velatorio,
              "personalizado": personalizado,
            },
          id: this.dealId
        };
        this.solicitudService.updateHubspotDeal(inforDealUpd2)
        .pipe(first())
        .subscribe(
          dataD => {
            console.log("OK UPDATE", dataD);
          },
          error => {
            console.log(error)
          }
        );

    }
    
  }

  /* --------------
      PAGINA 1
  -------------- */

  tipoServicio = "";

  continuarPagina1(tipo: any) {
    if(this.paso2.localizacion && (this.paso2.traslado == "NO" || (this.paso2.traslado == "SI" && (this.paso2.localizacionFallecido && this.paso2.localizacionFallecido != "")))) {
      this.tipoServicio = tipo;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
      this.loadCentros();
    } else {
      this.locSwal.fire();
    }
  }

  loadCentros() {
    this.loadingCentros = true;
    this.cdr.detectChanges();
    this.tanatorios = [];
    this.crematorios = [];
    this.tanatorio = null;
    this.crematorio = null;
    this.centroService.getByCP(this.localizacionObj.cpDesde, this.localizacionObj.cpHasta)
      .subscribe(centros => {
        
        for(let centro of centros) {
          if(centro.funeraria) {
            this.tanatorios.push(centro);
          }
          if(centro.crematorio) {
            this.crematorios.push(centro);
          }
        }

        this.continuarPagina2();
        
        this.loadingCentros = false;
        this.cdr.detectChanges();
      });
  }


  // ------- MODAL -------
  tipoModal = "";
  filters = {nombre: "", domicilio: "", poblacion: "", cp: "", albia: false}

  openDialog(content, tipo) {
    window.scrollTo(0,1);
    this.modalService.open(this.modalCentro, { centered: true, size: 'lg' } );
    window.scrollTo(0,0);
    this.tipoModal = tipo;
    this.filterDatatable();
  }

  selectTanatorio(content) {
    this.allCentros = this.tanatorios;
    this.filterDatatable();
    this.openDialog(content, "tanatorio");
  }

  selectCrematorio(content) {
    this.allCentros = this.crematorios;
    this.filterDatatable();
    this.openDialog(content, "crematorio");
  }

  selectCentro(row: any) {
    if(this.tipoModal == "tanatorio") {
      this.tanatorio = row;
      if(this.tipoServicio == 'INCINERACION'){
        if(row.crematorio) this.crematorio = row
        else if(row.centroRelacionado.crematorio) this.crematorio = row.centroRelacionado;
      }
    } else {  // crematorio
      this.crematorio = row;
    }
    this.modalService.dismissAll();
    this.continuarPagina2();
  }

  deleteTanatorio() {
    this.tanatorio = null;
    this.continuarPagina2();
    this.cdr.detectChanges();
  }

  deleteCrematorio() {
    this.crematorio = null;
    this.continuarPagina2();
    this.cdr.detectChanges();
  }

  filterDatatable(){
    this.centros = this.allCentros.filter(function(item){
      var mostrar = true;

      if(this.filters.nombre && this.filters.nombre != null && this.filters.nombre != "") {
        if(!item.nombre.toLowerCase().includes(this.filters.nombre.toLowerCase())) mostrar = false;
      }

      if(this.filters.domicilio && this.filters.domicilio != null && this.filters.domicilio != "") {
        if(!item.domicilio.toLowerCase().includes(this.filters.domicilio.toLowerCase())) mostrar = false;
      }

      if(this.filters.poblacion && this.filters.poblacion != null && this.filters.poblacion != "") {
        if(!item.poblacion.toLowerCase().includes(this.filters.poblacion.toLowerCase())) mostrar = false;
      }

      if(this.filters.cp && this.filters.cp != null && this.filters.cp != "") {
        if(!(item.cp + "").toLowerCase().includes(this.filters.cp.toLowerCase())) mostrar = false;
      }

      if(this.filters.albia) {
        if(!item.albia || item.grupo) mostrar = false;
      }

      if(this.tipoModal == "crematorio" && this.tanatorio && this.tanatorio.grupo && item.grupo && item.grupo != this.tanatorio.grupo) mostrar = false;
      
      return mostrar;
    }.bind(this));
    
    this.cdr.detectChanges();
  }


  resetFilters() {
    this.filters = {nombre: "", domicilio: "", poblacion: "", cp: "", albia: false};
    this.filterDatatable();
  }

  /////////// Modal negocios ////////////

  filters2 = {negocio: "", contacto: "", localizacion: ""}

  openNegocio() {
    window.scrollTo(0,1);
    this.modalService.open(this.modalNegocio, { centered: true, size: 'lg' } );
    window.scrollTo(0,0);
    this.filterDatatable2();
  }

  loadingNegocio = false;

  selectNegocio(deal: any) {
    console.warn(deal);

    this.modalService.dismissAll();

    this.loadingNegocio = true;
    this.cdr.detectChanges();

    this.solicitudService.getHubspotDealContact(deal.hs_object_id)
      .subscribe(contact => {
        console.warn(contact);

        this.paso2.nombre = contact.properties.firstname;
        this.paso2.telefono = contact.properties.phone;

        if(deal.localizacion) this.geocoderObj.query(deal.localizacion);
        this.loadingNegocio = false;
        this.cdr.detectChanges();

        setTimeout(function() {
          $('.mapboxgl-ctrl-geocoder--input').focus();
          setTimeout(function() {
              $('#inputEmail').focus();

          }, 200);
        }, 100);
        
        //this.paso2.email = contact.properties.email;

      });
  }
  
  filterDatatable2() {
    this.negocios = this.allNegocios.filter(function(item){
      var mostrar = true;

      if(this.filters2.negocio && this.filters2.negocio != null && this.filters2.negocio != "") {
        if(!item.properties.dealname || !item.properties.dealname.toLowerCase().includes(this.filters2.negocio.toLowerCase())) mostrar = false;
      }

      if(this.filters2.contacto && this.filters2.contacto != null && this.filters2.contacto != "") {
        if(!item.properties.nombre_contacto || !item.properties.nombre_contacto.toLowerCase().includes(this.filters2.contacto.toLowerCase())) mostrar = false;
      }

      if(this.filters2.localizacion && this.filters2.localizacion != null && this.filters2.localizacion != "") {
        if(!item.properties.localizacion || !item.properties.localizacion.toLowerCase().includes(this.filters2.localizacion.toLowerCase())) mostrar = false;
      }
      
      return mostrar;
    }.bind(this));
    
    this.cdr.detectChanges();
  }


  resetFilters2() {
    this.filters2 = {negocio: "", contacto: "", localizacion: ""};
    this.filterDatatable2();
  }


  /* --------------
      PAGINA 2
  -------------- */

  paso2 = {nombre: null, localizacion: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: null, telefono: null, politica: false, firma: "NO"} as any;
  //paso2 = {nombre: "Prueba", localizacion: null, cp: null, lat: null, long: null, traslado: "NO", localizacionFallecido: null, cpFallecido: null, latFallecido: null, longFallecido: null, distancia: null, email: "borrar@test.com", telefono: "666555666", politica: false} as any;
  paso2_error = {localizacionFallecido: false} as any;
  vermas = [false, false, false, false]
  paso2_validator: any;

  preciosHash = {};
  preciosObjHash = {};
  precioAlbia = true;
  servicioHash = {};
  servicioNombreHash = {};
  precioTraslado = 0;
  precioTrasladoBase = 0;

  localizacionObj = null;

  continuarPagina2() {
    let error = false;

    //this.loading = true;
    this.cdr.detectChanges();

    if((this.tanatorio && !this.tanatorio.albia) || (this.crematorio && !this.crematorio.albia)) {
      this.precioAlbia = false;
    } else {
      this.precioAlbia = true;
    }

    this.precioService.getByLocalizacion(this.localizacionObj.id)
    .subscribe(precios => {
      console.warn(precios);
      for(let precio of precios) {
        if(!precio.grupo) {
          if(this.precioAlbia) this.preciosHash[precio.servicio.abreviatura] = precio.importe
          else this.preciosHash[precio.servicio.abreviatura] = precio.importeTercero;

          this.preciosObjHash[precio.servicio.abreviatura] = precio;
          this.servicioHash[precio.servicio.abreviatura] = precio.servicio.id;
          this.servicioNombreHash[precio.servicio.id] = precio.servicio.nombre;
        }
      }

      if(this.tanatorio && this.tanatorio.grupo) {
        for(let precio of precios) {
          if(precio.grupo == this.tanatorio.grupo) {
            if(this.precioAlbia) this.preciosHash[precio.servicio.abreviatura] = precio.importe
            else this.preciosHash[precio.servicio.abreviatura] = precio.importeTercero;
  
            this.preciosObjHash[precio.servicio.abreviatura] = precio;
            this.servicioHash[precio.servicio.abreviatura] = precio.servicio.id;
            this.servicioNombreHash[precio.servicio.id] = precio.servicio.nombre;
          }
        }
      }

      if(this.paso2.traslado == "SI") {
        this.userService.getRoute(this.paso2.longFallecido, this.paso2.latFallecido, this.paso2.long, this.paso2.lat)
        .subscribe(route => {
          if(route.routes && route.routes.length > 0){
            this.paso2.distancia = route.routes[0].distance / 1000 * 2;
            let tarifaTraslado = 0;
            if(this.paso2.distancia < 100) tarifaTraslado = this.preciosHash["WFP_TRASLADO_0"]
            else if(this.paso2.distancia < 200) tarifaTraslado = this.preciosHash["WFP_TRASLADO_100"]
            else if(this.paso2.distancia < 400) tarifaTraslado = this.preciosHash["WFP_TRASLADO_200"]
            else if(this.paso2.distancia < 600) tarifaTraslado = this.preciosHash["WFP_TRASLADO_400"]
            else if(this.paso2.distancia < 800) tarifaTraslado = this.preciosHash["WFP_TRASLADO_600"]
            else if(this.paso2.distancia < 1000) tarifaTraslado = this.preciosHash["WFP_TRASLADO_800"]
            else if(this.paso2.distancia < 1200) tarifaTraslado = this.preciosHash["WFP_TRASLADO_1000"]
            else if(this.paso2.distancia < 1400) tarifaTraslado = this.preciosHash["WFP_TRASLADO_1200"]
            else if(this.paso2.distancia < 1600) tarifaTraslado = this.preciosHash["WFP_TRASLADO_1400"]
            else if(this.paso2.distancia < 1800) tarifaTraslado = this.preciosHash["WFP_TRASLADO_1600"]
            else tarifaTraslado = this.preciosHash["WFP_TRASLADO_1800"];
            
            this.precioTraslado = this.preciosHash["WFP_TRASLADO"] + tarifaTraslado;
            if(this.preciosHash["WFP_TRASLADO"].base7 > 0) this.precioTrasladoBase = this.precioTraslado / 1.07
            else this.precioTrasladoBase = this.precioTraslado / 1.21;
            this.packSeleccionado = null;
            this.totalServicios = 0;
            this.totalEsencial = 0;
            this.totalAdicionales = 0;
            this.totalImpuestos = 0;
            this.cdr.detectChanges();
            //this.siguiente();

          }
        });
      } else {
        this.precioTraslado = 0;
        this.precioTrasladoBase = 0;
        this.packSeleccionado = null;
        this.totalServicios = 0;
        this.totalEsencial = 0;
        this.totalAdicionales = 0;
        this.totalImpuestos = 0;
        this.cdr.detectChanges();
        //this.siguiente();
      }
    });
  }

  isControlHasErrorPaso2(controlName: string, validationType: string): boolean {
    if(!this.paso2_validator) return false;

		const control = this.paso2_validator.controls[controlName];
		if (!control) return false;

		if(!validationType) {
			const result = control.invalid;
			return result;
		} else {
			const result = control.hasError(validationType);
			return result;
		}
  }

  /* --------------
      PAGINA 3
  -------------- */

  detalleServicio = false;
  packSeleccionado = null;

  totalServicios = 0;
  totalEsencial = 0;
  totalEsencialBase21 = 0;
  totalEsencialBase10 = 0;
  totalEsencialBase7 = 0;
  objEsencial = null;
  totalAdicionales = 0;
  totalImpuestos = 0;
  totalBase21 = 0;
  totalBase10 = 0;
  totalBase7 = 0;

  vistaPersonalizada = false;
  verextra = [false, false, false, false, false, false, false, false]
  verfooter = false;

  seleccionarPack(pack: any) {
    if(this.packSeleccionado != pack) {
      this.totalAdicionales = 0;
    }
    this.packSeleccionado = pack;

    if(this.packSeleccionado == 1) {
      if(this.tipoServicio == 'ENTIERRO') {
        this.totalEsencial = this.preciosHash['WFP_PACK1_ENTIERRO'];
        this.objEsencial = this.preciosObjHash['WFP_PACK1_ENTIERRO'];
      } else { 
        this.totalEsencial = this.preciosHash['WFP_PACK1_CREMA'];
        this.objEsencial = this.preciosObjHash['WFP_PACK1_CREMA'];
      }
    } else if(this.packSeleccionado == 2) {
      if(this.tipoServicio == 'ENTIERRO') {
        this.totalEsencial = this.preciosHash['WFP_PACK2_ENTIERRO'];
        this.objEsencial = this.preciosObjHash['WFP_PACK2_ENTIERRO'];
      } else {
        this.totalEsencial = this.preciosHash['WFP_PACK2_CREMA'];
        this.objEsencial = this.preciosObjHash['WFP_PACK2_CREMA'];
      }
    } else if(this.packSeleccionado == 3) {
      if(this.tipoServicio == 'ENTIERRO') {
        this.totalEsencial = this.preciosHash['WFP_PACK3_ENTIERRO'];
        this.objEsencial = this.preciosObjHash['WFP_PACK3_ENTIERRO'];
      } else {
        this.totalEsencial = this.preciosHash['WFP_PACK3_CREMA'];
        this.objEsencial = this.preciosObjHash['WFP_PACK3_CREMA'];
      }
    }    

    this.totalServicios = this.totalEsencial + this.precioTraslado;
    //this.totalImpuestos = this.totalServicios - this.totalServicios / 1.21;

    if(this.precioAlbia) this.totalEsencialBase21 = this.objEsencial.base21
    else this.totalEsencialBase21 = this.objEsencial.base21Tercero;

    if(this.precioAlbia) this.totalEsencialBase10 = this.objEsencial.base10
    else this.totalEsencialBase10 = this.objEsencial.base10Tercero;

    if(this.precioAlbia) this.totalEsencialBase7 = this.objEsencial.base7
    else this.totalEsencialBase7 = this.objEsencial.base7Tercero;

    this.calculoLineas();

    this.cdr.detectChanges();
  }

  /* --------------
      PAGINA 4
  -------------- */

  //ANTIGUO
  continuarPagina4() {
    var solicitudUpd3 = null;
    /*if(this.version == "v2") {
      this.paso2_validator = new FormGroup({
        nombre: new FormControl(this.paso2.nombre, Validators.required),
        email: new FormControl(this.paso2.email, Validators.compose([Validators.required,Validators.email])),
        telefono: new FormControl(this.paso2.telefono, Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")])),
        politica: new FormControl(this.paso2.politica, Validators.requiredTrue),
      });

      if(this.paso2_validator.invalid) return;

      solicitudUpd3 = {
        id: this.solicitudId,
        status: "ENVIADO",
        fecha: new Date(),
        nombre: this.paso2.nombre,
        email: this.paso2.email,
        telefono: this.paso2.telefono
      }
    } else {*/
      solicitudUpd3 = {
        id: this.solicitudId,
        status: "ENVIADO",
        fecha: new Date()
      }
    //}


    this.loading = true;

    this.solicitudService.update(solicitudUpd3)
    .pipe(first())
    .subscribe(
      data => {
        let idServicioEsencial = null;
        let servicios = [];

        if(this.packSeleccionado == 1) {
          if(this.tipoServicio == 'ENTIERRO') idServicioEsencial = 20
          else idServicioEsencial = 23;
        } else if(this.packSeleccionado == 2) {
          if(this.tipoServicio == 'ENTIERRO') idServicioEsencial = 21
          else idServicioEsencial = 24;
        } else if(this.packSeleccionado == 3) {
          if(this.tipoServicio == 'ENTIERRO') idServicioEsencial = 22
          else idServicioEsencial = 25;
        }

        let objServicioEsencial = {
          servicio: idServicioEsencial,
          solicitud: this.solicitudId,
          importe: this.vistaPersonalizada ? this.totalEsencial : this.totalServicios - this.precioTraslado,
          divisa: "EUR"
        }
        this.guardarServicio(objServicioEsencial);

        servicios.push({
          nombre: this.servicioNombreHash[idServicioEsencial],
          importe: this.thousands_separators(this.vistaPersonalizada ? this.totalEsencial : this.totalServicios - this.precioTraslado),
          base: this.thousands_separators((this.vistaPersonalizada ? this.totalEsencial : this.totalServicios - this.precioTraslado) / 1.21)
        })

        if(this.paso2.traslado == "SI") {
          let idServicioTraslado = null;
          if(this.paso2.distancia < 100) idServicioTraslado = this.servicioHash["WFP_TRASLADO_0"]
          else if(this.paso2.distancia < 200) idServicioTraslado = this.servicioHash["WFP_TRASLADO_100"]
          else if(this.paso2.distancia < 400) idServicioTraslado = this.servicioHash["WFP_TRASLADO_200"]
          else if(this.paso2.distancia < 600) idServicioTraslado = this.servicioHash["WFP_TRASLADO_400"]
          else if(this.paso2.distancia < 800) idServicioTraslado = this.servicioHash["WFP_TRASLADO_600"]
          else if(this.paso2.distancia < 1000) idServicioTraslado = this.servicioHash["WFP_TRASLADO_800"]
          else if(this.paso2.distancia < 1200) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1000"]
          else if(this.paso2.distancia < 1400) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1200"]
          else if(this.paso2.distancia < 1600) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1400"]
          else if(this.paso2.distancia < 1800) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1600"]
          else idServicioTraslado = this.servicioHash["WFP_TRASLADO_1800"];
          let objServicioTraslado = {
            servicio: idServicioTraslado,
            solicitud: this.solicitudId,
            importe: this.precioTraslado,
            divisa: "EUR"
          }
          this.guardarServicio(objServicioTraslado);

          servicios.push({
            nombre: "TRASLADO (" + parseInt(this.paso2.distancia) + "km.)",
            importe: this.thousands_separators(this.precioTraslado),
            base: this.thousands_separators((this.precioTraslado) / 1.21)
          });
        }

        if(this.vistaPersonalizada) {
          /*if(this.serviciosExtra.catering) {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_CATERING'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_CATERING'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_CATERING']],
              importe: this.thousands_separators(this.preciosHash['WFP_CATERING']),
              base: this.thousands_separators((this.preciosHash['WFP_CATERING']) / 1.21)
            });
          }
          if(this.serviciosExtra.ceremonia && this.serviciosExtra.tipoCeremonia == "CIVIL") {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_CEREMONIA_CIVIL'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_CEREMONIA_CIVIL'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_CEREMONIA_CIVIL']],
              importe: this.thousands_separators(this.preciosHash['WFP_CEREMONIA_CIVIL']),
              base: this.thousands_separators((this.preciosHash['WFP_CEREMONIA_CIVIL']) / 1.21)
            });
          }
          if(this.serviciosExtra.ceremonia && this.serviciosExtra.tipoCeremonia == "RELIGIOSA") {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_CEREMONIA_RELIGIOSA'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_CEREMONIA_RELIGIOSA'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_CEREMONIA_RELIGIOSA']],
              importe: this.thousands_separators(this.preciosHash['WFP_CEREMONIA_RELIGIOSA']),
              base: this.thousands_separators((this.preciosHash['WFP_CEREMONIA_RELIGIOSA']) / 1.21)
            });
          }
          if(this.serviciosExtra.coche) {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_COCHE'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_COCHE'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_COCHE']],
              importe: this.thousands_separators(this.preciosHash['WFP_COCHE']),
              base: this.thousands_separators((this.preciosHash['WFP_COCHE']) / 1.21)
            });
          }
          if(this.serviciosExtra.certificados) {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_CERTIFICADOS'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_CERTIFICADOS'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_CERTIFICADOS']],
              importe: this.thousands_separators(this.preciosHash['WFP_CERTIFICADOS']),
              base: this.thousands_separators((this.preciosHash['WFP_CERTIFICADOS']) / 1.21)
            });
          }
          if(this.serviciosExtra.musical) {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_MUSICAL'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_MUSICAL'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_MUSICAL']],
              importe: this.thousands_separators(this.preciosHash['WFP_MUSICAL']),
              base: this.thousands_separators((this.preciosHash['WFP_MUSICAL']) / 1.21)
            });
          }
          if(this.serviciosExtra.colgantes) {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_COLGANTES'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_COLGANTES'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_COLGANTES']],
              importe: this.thousands_separators(this.preciosHash['WFP_COLGANTES']),
              base: this.thousands_separators((this.preciosHash['WFP_COLGANTES']) / 1.21)
            });
          }
          if(this.serviciosExtra.firmas) {
            let objServicioAdicional = {
              servicio: this.servicioHash['WFP_FIRMAS'],
              solicitud: this.solicitudId,
              importe: this.preciosHash['WFP_FIRMAS'],
              divisa: "EUR"
            }
            this.guardarServicio(objServicioAdicional);
            servicios.push({
              nombre: this.servicioNombreHash[this.servicioHash['WFP_FIRMAS']],
              importe: this.thousands_separators(this.preciosHash['WFP_FIRMAS']),
              base: this.thousands_separators((this.preciosHash['WFP_FIRMAS']) / 1.21)
            });
          }*/
        }


        
        var solicitud = {
          telefono: this.paso2.telefono,
          email: this.paso2.email,
          lang: this.translate.currentLang,
          nombre: this.paso2.nombre,
          localizacion: this.paso2.localizacion,
          cp: this.paso2.cp,
          traslado: this.paso2.traslado == "SI" ? true : false,
          localizacionFallecido: this.paso2.localizacionFallecido,
          cpFallecido: this.paso2.cpFallecido,
          personalizado: this.vistaPersonalizada,
          importe: this.thousands_separators(this.totalServicios),
          divisa: "EUR",
          servicios: servicios,
          base: this.thousands_separators(this.totalServicios / 1.21),
          iva: this.thousands_separators(this.totalServicios - (this.totalServicios / 1.21)),
        }

        let info = {
          nombre: this.paso2.nombre,
          telefono: this.localizacionObj.telefono,
          email: this.localizacionObj.email,
          solicitud: solicitud
        }

        this.notificacionService.sendSolicitud(info)
          .pipe(first())
          .subscribe(
            data => {
            },
            error => {
              console.error(error);
              this.loading = false;
            });


          let serviciosStr = "";
          for (let i = 0 ; i< servicios.length; i++){
            if(i == 0){
              serviciosStr = serviciosStr.concat(JSON.stringify(servicios[i].nombre));
            }
            else{
              serviciosStr = serviciosStr.concat(" " , ", " + JSON.stringify(servicios[i].nombre));
            }
          }
    
          var inforDealUpd = {
            "properties": {
                //"dealstage": "24059319",
                "servicios": serviciosStr,
                "estado" : "ENVIADO",
                "origen": "finalizado"
              },
            id: this.dealId
          };
          this.solicitudService.updateHubspotDeal(inforDealUpd)
          .pipe(first())
          .subscribe(
            dataD => {
              console.log("OK UPDATE", dataD);
            },
            error => {
              console.log(error)
            }
          );


          /*this.gtmService.pushTag({
            event: 'purchase',
            //data: {
              version: this.version
            //}
          });*/

          this.location.replaceState("/" + this.translate.currentLang + '/solicitud/presupuesto-confirmado' + "-" + this.version);


          /*this.gtmService.pushTag({
            event: 'pageview',
            page_path: "/" + this.translate.currentLang + '/solicitud/presupuesto-confirmado' + "-" + this.version,
            version: this.version              
          });*/

          /*if(typeof gtag !== "undefined") {
            gtag('set', 'page_path', "/" + this.translate.currentLang + '/solicitud/presupuesto-confirmado' + "-" + this.abTestsService.getVersion());
            gtag('event', 'page_view', {version: this.abTestsService.getVersion()});
  
            gtag('event', 'generate_lead', {
              'event_category': 'generate_lead',
              'event_label': 'embudo',
            });
          }*/
          
          this.ngZone.run(() => this.router.navigate(["/" + this.translate.currentLang + '/solicitud/presupuesto-confirmado' + "-" + this.version])).then();


      },
      error => {
        console.error(error);
        this.loading = false;
      });

      


  }
  

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
   thousands_separators(num)
   {
     if(num){ 
       var num_parts = num.toFixed(2).split(".");
       num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
       if(parseInt(num_parts[1]) > 0) return num_parts.join(",")
       else return num_parts[0];
       
     } else {
       return '0';
     }
     
   }


  /* ****************************************************************************************** */
  /* ****************************************************************************************** */
  /* ****************************************************************************************** */
  /* ****************************************************************************************** */


  lineasAdicionales = [];
  verAdicionales = false;

  cambioAdicionales(valor: any) {
    this.verAdicionales = valor;
    this.cdr.detectChanges();
  }

  addLinea() {
    this.lineasAdicionales.push({
      concepto: "",
      importe: null
    })
    this.cdr.detectChanges();
  }

  borrarLinea(index: any) {
    this.lineasAdicionales.splice(index, 1);
    this.cdr.detectChanges();
    this.calculoLineas();
  }

  calculoLineas() {
    this.totalAdicionales = 0;
    this.totalBase21 = 0;
    this.totalBase10 = 0;
    this.totalBase7 = 0;
    for(let linea of this.lineasAdicionales) {
      if(linea.importe) {
        this.totalAdicionales += linea.importe;
      }
    }
    this.totalServicios = this.totalEsencial + this.precioTraslado + this.totalAdicionales;
    //this.totalImpuestos = this.totalServicios - this.totalServicios / 1.21;

    this.totalBase21 += this.totalEsencialBase21 + this.precioTrasladoBase;

    if(this.totalBase21 > 0) this.totalBase21 += this.totalAdicionales / 1.21;

    this.totalBase10 = this.totalEsencialBase10;

    this.totalBase7 = this.totalEsencialBase7;
    if(this.totalBase7 > 0) this.totalBase7 += this.totalAdicionales / 1.07;

    this.cdr.detectChanges();
  }

  // -----------------------------------

  verNotasAdicionales = false;
  notasAdicionales = null;

  cambioNotasAdicionales(valor: any) {
    this.verNotasAdicionales = valor;
    this.cdr.detectChanges();
  }

  // -----------------------------------

  guardarServicio(servicio: any) {
    this.solicitudService.postServicio(servicio)
    .pipe(first())
    .subscribe(
      data => {
      },
      error => {
        console.error(error);
        this.loading = false;
      });
  }

  enviarPresupuesto() {
    this.paso2_validator = new FormGroup({
      nombre: new FormControl(this.paso2.nombre, Validators.required),
      email: new FormControl(this.paso2.email, Validators.compose([Validators.required,Validators.email])),
      telefono: new FormControl(this.paso2.telefono, Validators.compose([Validators.required, Validators.pattern("[0-9]{9,11}")]))
    });

    if(this.paso2_validator.invalid) return;

    this.loading = true;

    // Obtenemos código
    this.solicitudService.generarCodigo()
    .subscribe(codigo => {
      console.warn(codigo);

      //Guardamos el presupuesto

      var solicitud = {
        telefono: this.paso2.telefono,
        email: this.paso2.email,
        status: "PRESUPUESTO",
        nombre: this.paso2.nombre,
        localizacion: this.paso2.localizacion,
        cp: this.paso2.cp,
        lat: this.paso2.lat,
        long: this.paso2.long,
        traslado: this.paso2.traslado == "SI" ? true : false,
        localizacionFallecido: this.paso2.localizacionFallecido,
        cpFallecido: this.paso2.cpFallecido,
        latFallecido: this.paso2.latFallecido,
        longFallecido: this.paso2.longFallecido,
        distancia: this.paso2.distancia,
        tipo: this.tipoServicio,
        velatorio: this.packSeleccionado >= 2 ? true : false,
        flores: this.packSeleccionado == 3 ? true : false,
        personalizado: this.lineasAdicionales.length > 0 ? true : false,
        importe: this.totalServicios,
        divisa: "EUR",
        oculta: false,
        enviado: true,
        numero: codigo.numero,
        anyo: parseInt(moment().format("YY")),
        presupuesto: codigo.codigo,
        base21: this.totalBase21,
        base10: this.totalBase10,
        base7: this.totalBase7,
        tanatorio: this.tanatorio ? this.tanatorio.nombre : null,
        crematorio: this.crematorio ? this.crematorio.nombre : null,
        fecha: new Date()
      }

      this.solicitudService.post(solicitud)
      .pipe(first())
      .subscribe(
        data => {
          this.solicitudId = data.id;
          this.solicitudObj = data;
          //this.loading = false;
          this.envioServicios();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    });
  }


  /**
   * Envio de servicios y preparación de objetos para PDF
   */
  envioServicios() {
    let idServicioEsencial = this.objEsencial.servicio.id;
    let servicios = [];

    let objServicioEsencial = {
      servicio: idServicioEsencial,
      solicitud: this.solicitudId,
      importe: this.totalEsencial,
      divisa: "EUR",
      base21: this.totalEsencialBase21,
      base10: this.totalEsencialBase10,
      base7: this.totalEsencialBase7,
      nombre: this.objEsencial.servicio.nombre
    }
    this.guardarServicio(objServicioEsencial);

    if(this.objEsencial.servicio.desglose && this.totalEsencialBase21 > 0 && this.totalEsencialBase10 > 0) {
      servicios.push({
        nombre: this.objEsencial.servicio.nombre21,
        detalle: this.objEsencial.servicio.detalle,
        importe: this.thousands_separators(this.totalEsencialBase21 * 1.21),
        base: this.thousands_separators(this.totalEsencialBase21),
        iva: 21
      })

      servicios.push({
        nombre: this.objEsencial.servicio.nombre10,
        importe: this.thousands_separators(this.totalEsencialBase10 * 1.1),
        base: this.thousands_separators(this.totalEsencialBase10),
        iva: 10
      })

    } else if(this.totalEsencialBase7 > 0) {
      servicios.push({
        nombre: this.objEsencial.servicio.nombre,
        detalle: this.objEsencial.servicio.detalle,
        importe: this.thousands_separators(this.totalEsencial),
        base: this.thousands_separators(this.totalEsencialBase7),
        iva: 7
      })
    } else {
      servicios.push({
        nombre: this.objEsencial.servicio.nombre,
        detalle: this.objEsencial.servicio.detalle,
        importe: this.thousands_separators(this.totalEsencial),
        base: this.thousands_separators(this.totalEsencialBase21),
        iva: 21
      })
    }
    
    if(this.paso2.traslado == "SI") {
      let idServicioTraslado = null;
      if(this.paso2.distancia < 100) idServicioTraslado = this.servicioHash["WFP_TRASLADO_0"]
      else if(this.paso2.distancia < 200) idServicioTraslado = this.servicioHash["WFP_TRASLADO_100"]
      else if(this.paso2.distancia < 400) idServicioTraslado = this.servicioHash["WFP_TRASLADO_200"]
      else if(this.paso2.distancia < 600) idServicioTraslado = this.servicioHash["WFP_TRASLADO_400"]
      else if(this.paso2.distancia < 800) idServicioTraslado = this.servicioHash["WFP_TRASLADO_600"]
      else if(this.paso2.distancia < 1000) idServicioTraslado = this.servicioHash["WFP_TRASLADO_800"]
      else if(this.paso2.distancia < 1200) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1000"]
      else if(this.paso2.distancia < 1400) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1200"]
      else if(this.paso2.distancia < 1600) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1400"]
      else if(this.paso2.distancia < 1800) idServicioTraslado = this.servicioHash["WFP_TRASLADO_1600"]
      else idServicioTraslado = this.servicioHash["WFP_TRASLADO_1800"];
      if(this.totalBase7 > 0) {
        let objServicioTraslado = {
          servicio: idServicioTraslado,
          solicitud: this.solicitudId,
          importe: this.precioTraslado,
          divisa: "EUR",
          base7: this.precioTrasladoBase,
          nombre: "TRASLADO (" + parseInt(this.paso2.distancia) + "km.)"
        }
        this.guardarServicio(objServicioTraslado);

        servicios.push({
          nombre: "TRASLADO",
          importe: this.thousands_separators(this.precioTraslado),
          base: this.thousands_separators(this.precioTrasladoBase),
          iva: 7
        });
      } else {
        let objServicioTraslado = {
          servicio: idServicioTraslado,
          solicitud: this.solicitudId,
          importe: this.precioTraslado,
          divisa: "EUR",
          base21: this.precioTrasladoBase,
          nombre: "TRASLADO (" + parseInt(this.paso2.distancia) + "km.)"
        }
        this.guardarServicio(objServicioTraslado);

        servicios.push({
          nombre: "TRASLADO",
          importe: this.thousands_separators(this.precioTraslado),
          base: this.thousands_separators(this.precioTrasladoBase),
          iva: 21
        });
      }
    }

    // Líneas adicionales
    if(this.lineasAdicionales.length > 0) {
      for(let linea of this.lineasAdicionales) {
        if(this.totalBase7 > 0) {
          let objServicioLinea = {
            servicio: null,
            solicitud: this.solicitudId,
            importe: linea.importe,
            divisa: "EUR",
            base7: linea.importe / 1.07,
            nombre: linea.concepto
          }
          this.guardarServicio(objServicioLinea);
  
          servicios.push({
            nombre: linea.concepto,
            importe: this.thousands_separators(linea.importe),
            base: this.thousands_separators(linea.importe / 1.07),
            iva: 7
          });
        } else {
          let objServicioLinea = {
            servicio: null,
            solicitud: this.solicitudId,
            importe: linea.importe,
            divisa: "EUR",
            base21: linea.importe / 1.21,
            nombre: linea.concepto
          }
          this.guardarServicio(objServicioLinea);
  
          servicios.push({
            nombre: linea.concepto,
            importe: this.thousands_separators(linea.importe),
            base: this.thousands_separators(linea.importe / 1.21),
            iva: 21
          });
        }
        
      }
    }

    this.generarPDF(servicios);

  }
  
  presupuestosGenerados = [];

  generarPDF(servicios: any) {
    let impuestos = [];

    if(this.totalBase21 > 0) {
      impuestos.push({
        base: this.thousands_separators(this.totalBase21),
        iva: 21,
        importe: this.thousands_separators(this.totalBase21 * 1.21 - this.totalBase21)
      })
    }
    if(this.totalBase10 > 0) {
      impuestos.push({
        base: this.thousands_separators(this.totalBase10),
        iva: 10,
        importe: this.thousands_separators(this.totalBase10 * 1.1 - this.totalBase10)
      })
    }
    if(this.totalBase7 > 0) {
      impuestos.push({
        base: this.thousands_separators(this.totalBase7),
        iva: 7,
        importe: this.thousands_separators(this.totalBase7 * 1.07 - this.totalBase7)
      })
    }

    

    let envio = {
      fecha: moment().format("DD/MM/YYYY"),
      presupuesto: this.solicitudObj.presupuesto,
      solicitud: {
        nombre: this.solicitudObj.nombre,
        multiplesIVA: this.totalBase21 > 0 && this.totalBase10 > 0 ? true : false,
        servicios: servicios,
        total: this.thousands_separators(this.totalServicios),
        base: this.thousands_separators(this.totalBase21 + this.totalBase10 + this.totalBase7),
        iva: this.thousands_separators(this.totalServicios - this.totalBase21 - this.totalBase10 - this.totalBase7),
        impuestos: impuestos,
        notas: this.notasAdicionales,
        impuesto: this.localizacionObj.impuesto || "IVA",
        telefono: this.solicitudObj.telefono
      },
      firma: this.paso2.firma == "SI" ? true : false,
    }

    this.solicitudService.generaPresupuesto(envio)
    .subscribe(
      data => {
        console.error(data);
        let firma = "";
        if(data.firma && data.firma.documentsbyoperation[0]) firma = data.firma.documentsbyoperation[0].operationUuid

        this.presupuestosGenerados.push({
          nombre: this.solicitudObj.presupuesto + " - " +  this.objEsencial.servicio.nombre,
          codigo: this.solicitudObj.presupuesto,
          importe: this.solicitudObj.importe,
          file: data.file,
          envio: true,
          firma: firma
        })

        if(this.paso2.email.toLowerCase().includes("prueba")){
          this.downloadPDF(this.presupuestosGenerados[this.presupuestosGenerados.length-1].file, this.presupuestosGenerados[this.presupuestosGenerados.length-1].codigo);
          this.loading = false;
          this.prepararEmail();
          this.vista = "resultado";
          this.cdr.detectChanges();
        } else {
          if(!this.dealId) {
            this.envioHubspot();
          } else {
            this.enviarPresupuestoHubspot();
          }
        }

        //this.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        console.error(error);
        this.loading = false;
      });

  }

  envioHubspot() {
    var infor = {
      "properties": {
          "email": this.paso2.email,
          "firstname": this.paso2.nombre,
          "phone": this.paso2.telefono,
          "localizacion": this.paso2.localizacion,
          "cp": this.paso2.cp,
          "lat":this.paso2.lat,
          "long":this.paso2.long,
        }
    };
    this.solicitudService.postHubspot(infor)
    .subscribe(
      data => {
        console.warn(data);
        this.objectId = data;

        let flores = "";
        if(this.packSeleccionado == 3){
          flores = "SI"
        } else{
          flores = "NO"
        }

        let velatorio = "";
        if(this.packSeleccionado >= 2){
          velatorio = "SI"
        } else{
          velatorio = "NO"
        }

        let personalizado = "";
        if(this.lineasAdicionales.length > 0){
          personalizado = "SI"
        } else{
          personalizado = "NO"
        }

        var inforDeal = {
          "properties": {
              "dealname": "Presupuesto - " + this.paso2.email,
              "amount": this.totalServicios,
              "flores": flores,
              "velatorio": velatorio,
              "personalizado": personalizado,
              "nombre_contacto": this.paso2.nombre,
              "origen": "manual",
              "estado" : "PRESUPUESTO",
              "tipo" : this.tipoServicio,
              "dealstage":"28252536",
              "pipeline":"9819966",
              "cp": this.paso2.cp,
              "lat": this.paso2.lat,
              "long": this.paso2.long,
              "localizacion": this.paso2.localizacion,
              "numero_de_presupuesto": this.solicitudObj.presupuesto,
              "tanatorio": this.solicitudObj.tanatorio,
              "crematorio": this.solicitudObj.crematorio,
              "localizacion_del_fallecido": this.paso2.localizacionFallecido
            }
        };
        this.solicitudService.postHubspotDealCheck(inforDeal.properties, this.objectId)
        .pipe(first())
        .subscribe(
          dataD => {
            this.dealId = dataD;
            var inform = {
              "objectId": this.objectId,
              "dealId": this.dealId,
            };
            this.solicitudService.postAssociation(inform)
            .pipe(first())
            .subscribe(
              data => {
                this.enviarPresupuestoHubspot();
              },
              error => {
                console.error(error);
              }
            );
          },
          error => {
            console.log(error)
          }
        );
      },
      error => {
        console.error(error);
      });
  }

  enviarPresupuestoHubspot() {
    this.solicitudService.uploadPresupuesto({
      codigo: this.presupuestosGenerados[this.presupuestosGenerados.length-1].codigo, 
      deal: this.dealId
    }).pipe(first())
    .subscribe(
      dataX => {
        console.warn("OK", dataX);

        this.presupuestosGenerados[this.presupuestosGenerados.length-1].id = dataX.file;

        this.downloadPDF(this.presupuestosGenerados[this.presupuestosGenerados.length-1].file, this.presupuestosGenerados[this.presupuestosGenerados.length-1].codigo);
        this.loading = false;
        this.prepararEmail();
        this.vista = "resultado";
        this.cdr.detectChanges();
      },
      error => {
        console.error(error);
      }
    );
  }

  emailContent = "";

  prepararEmail () {
    var hora = moment().hour();
    var saludo = "";

    if(hora >= 6 && hora <= 12) saludo = "Buenos días"
    else if(hora >= 13 && hora <= 20) saludo = "Buenas tardes"
    else saludo = "Buenas noches";

    this.emailContent = saludo + ", D/Dña " + this.paso2.nombre + ",<br>" + 
    "Muchas gracias por su contacto con BOREA.<br>" + 
    "Como hemos hablado por teléfono, le enviamos nuestro presupuesto para la despedida que ha escogido.<br>";

    if(this.paso2.firma == "SI") {
      this.emailContent += "Le recordamos que este presupuesto tiene una validez de 90 días si finalmente decide firmarlo. Para ello, pulse sobre el botón de firma y rellene los campos solicitados. Nuestro número de teléfono es 683 67 39 57.<br>" +
      "[FIRMA]<br>";
    } else {
      this.emailContent += "Le recordamos que este presupuesto tiene una validez de 90 días si finalmente decide firmarlo. Para hacérnoslo llegar puede utilizar esta misma vía (correo electrónico). Nuestro número de teléfono es 683 67 39 57.<br>";
    }
    
    this.emailContent += "Atentamente,<br>" +
    "[TU NOMBRE] de BOREA";
  }

  



  downloadPDF(pdf, presupuesto) {
    const linkSource = `${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = presupuesto + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  goHubspot() {
    window.open("https://app.hubspot.com/contacts/14575308/deal/" + this.dealId, "_blank");
  }

  nuevoPresupuesto() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['admin-wfp-presupuesto']);
    });
  }

  volverPresupuesto() {
    this.vista = "presupuesto";
    this.cdr.detectChanges();
  }

  abrirPDF() {
    let pdfWindow = window.open("", "_blank")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
        encodeURI(this.presupuestosGenerados[this.presupuestosGenerados.length-1].file) + "'></iframe>"
    )
  }
  abrirPresPDF(presupuesto: any) {
    let pdfWindow = window.open("", "_blank")
    pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" +
        encodeURI(presupuesto.file) + "'></iframe>"
    )
  }

  // Envio EMAIL
  loadingEmail = false;
  emailEnviado = false;
  textHTML = "";

  sendEmail() {
    if(this.textHTML.includes("[TU NOMBRE]")) {
      this.emailSwal.fire();
      return;
    }

    if(this.textHTML.includes("[FIRMA]")) {
      let firma = "";

      for(let pres of this.presupuestosGenerados) {
        firma += "<a href='https://albia-pre.galeonsoftware.com/customer-info/?uuid=" + pres.firma + "&d=5'>Firmar presupuesto - " + pres.codigo + "</a><br>"
      }

      this.textHTML = this.textHTML.replace("[FIRMA]", firma);
    }


    this.loadingEmail = true;
    this.cdr.detectChanges();

    let adjuntos = [];
    for(let pres of this.presupuestosGenerados) {
      if(pres.envio) {
        adjuntos.push({
          nombre: pres.nombre,
          codigo: pres.codigo,
          id: pres.id
        })
      }
      
    }

    let mail = {
      mensaje: this.textHTML,
      email: this.paso2.email,
      adjuntos: adjuntos,
      deal: this.dealId
    }

    this.solicitudService.envioPresupuesto(mail)
    .pipe(first())
    .subscribe(
      data => {
        this.loadingEmail = false;
        this.emailEnviado = true;
        this.cdr.detectChanges();
      },
      error => {
        console.error(error);
        this.loading = false;
      });

    
  }

  htmlChanged(event) {
    this.textHTML = event.value;
  }

  

}
