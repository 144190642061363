// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { HomeComponent } from './home/home.component';
// NGX DATATABLE
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AngularIbanModule } from 'angular-iban';
import { DxMapModule, DxCheckBoxModule, DxButtonModule, DxScrollViewModule } from 'devextreme-angular';
import { DxChartModule, DxFileManagerModule, DxPieChartModule, DxPopupModule, DxSelectBoxModule, DxHtmlEditorModule } from 'devextreme-angular';
import '@stripe/stripe-js';
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MatProgressSpinnerModule
} from '@angular/material';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { StartComponent } from './start/start.component';

import {RouterModule} from '@angular/router';

import { TooltipModule } from 'ng2-tooltip-directive';
import { AdminEstatisticasComponent } from './admin-estatisticas/admin-estatisticas.component';
import { AdminUsuariosComponent } from './admin-usuarios/admin-usuarios.component';
import { AdminInteractivoComponent } from './admin-interactivo/admin-interactivo.component';
import { AdminSoporteComponent } from './admin-soporte/admin-soporte.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminWfpComponent } from './admin-wfp/admin-wfp.component';
import { AdminEstadisticasWfpComponent } from './admin-estadisticas-wfp/admin-estadisticas-wfp.component';
import { AdminBlogWfpComponent } from './admin-blog-wfp/admin-blog-wfp.component';
import { AdminWfpPresupuestoComponent } from './admin-wfp-presupuesto/admin-wfp-presupuesto.component';
import { CuestionarioComponent } from './cuestionario/cuestionario.component';

@NgModule({
	declarations: [HomeComponent, StartComponent, AdminEstatisticasComponent, AdminUsuariosComponent, AdminInteractivoComponent, AdminSoporteComponent, AdminWfpComponent, AdminEstadisticasWfpComponent, AdminBlogWfpComponent, AdminWfpPresupuestoComponent, CuestionarioComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		PartialsModule,
		MatInputModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		MatSelectModule,
		MatMenuModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatTabsModule,
		MatNativeDateModule,
		MatCardModule,
		MatRadioModule,
		MatIconModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		NgxDatatableModule,
		NgbModule,
		TranslateModule.forChild(),
		SweetAlert2Module,
		OwlDateTimeModule, 
		OwlNativeDateTimeModule,
		TooltipModule,
		RouterModule,
		NgxDropzoneModule,
		AngularIbanModule,
		DxMapModule,
		DxCheckBoxModule,
		DxButtonModule,
		DxChartModule, 
		DxFileManagerModule, 
		DxPieChartModule,
		DxPopupModule,
		DxSelectBoxModule,
		DxScrollViewModule,
		DxHtmlEditorModule,
		NgMultiSelectDropDownModule
	],
	providers: []
})
export class PagesModule {
}
