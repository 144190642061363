import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de funerarias.
 */
@Injectable()
export class SolicitudService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el listado completo de solicitudes
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
      return this.http.get<any[]>(`${environment.apiUrl}/solicitud`);
    }

    /**
     * Realiza una petición `GET` para obtener el listado completo de solicitudes
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getServicios(solicitud: any) {
      return this.http.get<any[]>(`${environment.apiUrl}/user_servicios?solicitud=${solicitud}`);
    }
    
    /**
     * Realiza una petición `POST` para guardar una solicitud
     * @param fune Contenido de la solicitud
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(solicitud: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post<any>(`${environment.apiUrl}/solicitud`, solicitud, httpOptions);
    }
    
    /**
     * Realiza una petición `POST` para guardar un servicio de una solicitud
     * @param fune Contenido del servicio
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postServicio(servicio: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/user_servicios`, servicio, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para actualizar una solicitud
     * @param cert Contenido de la solicitud a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(solicitud: any) {
        return this.http.patch(`${environment.apiUrl}/solicitud/${solicitud.id}`, solicitud);
    }


    getEnviadosTotal(){
      return this.http.get<any>(`${environment.apiUrl}/getEnviadosTotal`);
    }
    getEnviados24(){
      return this.http.get<any>(`${environment.apiUrl}/getEnviados24`)
    }

    getArrayHoras(){
      return this.http.get<any>(`${environment.apiUrl}/getArrayHoras`)
    }
    getArrayDias(){
      return this.http.get<any>(`${environment.apiUrl}/getArrayDias`)
    }
    getCountSolicitudes(status: any){
      return this.http.get<any>(`${environment.apiUrl}/countSolicitudes?status=${status}`)
    }
    getCountSolicitudesHoras(status: any){
      return this.http.get<any>(`${environment.apiUrl}/countSolicitudesHoras?status=${status}`)
    }
    getCountSolicitudesGraficoHoras(status: any){
      return this.http.get<any>(`${environment.apiUrl}/countSolicitudesGraficoHoras?status=${status}`)
    }
    getCountSolicitudesGraficoSemana(status: any){
      return this.http.get<any>(`${environment.apiUrl}/countSolicitudesGraficoSemana?status=${status}`)
    }

    generarCodigo() {
      return this.http.get<any>(`${environment.apiUrl}/generarCodigo`)
    }
    generaPresupuesto(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/generaPresupuesto`, datos, httpOptions)
    }
    getFicheroPresupuesto(presupuesto: any) {
      return this.http.get<any>(`${environment.apiUrl}/getFicheroPresupuesto?presupuesto=${presupuesto}`)
    }
    envioPresupuesto(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/envioPresupuesto`, datos, httpOptions)
    }

    // HUBSPOT

    getHubspotActiveDeals() {
      return this.http.get<any>(`${environment.apiUrl}/activeDeals`)
    }

    getHubspotDealContact(deal: any) {
      return this.http.get<any>(`${environment.apiUrl}/dealContact?deal=${deal}`)
    }

    postHubspot(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/nuevo_contacto`, datos, httpOptions)
    }
    postHubspotDeal(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/create_deal`, datos, httpOptions)
    }
    postHubspotDealCheck(datos: any, contacto: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/create_deal_check`, { properties: datos, contacto: contacto }, httpOptions)
    }
    postAssociation(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/associate_deal`, datos, httpOptions)
    }

    updateHubspotDeal(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.patch<any>(`${environment.apiUrl}/update_deal`, datos, httpOptions)
    }
    uploadPresupuesto(datos: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post<any>(`${environment.apiUrl}/uploadPresupuesto`, datos, httpOptions)
    }
}