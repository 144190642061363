import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, PaymentService, CountryService } from '../../../../_services';
import moment from 'moment';
import { environment } from '../../../../../environments/environment';

declare var Stripe: any;

@Component({
  selector: 'kt-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  //stripe;

  constructor(private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
		private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
		private translate: TranslateService,
    private cdr: ChangeDetectorRef,
		private countryService: CountryService) { 
    }

  tipo = null;
  correcto = true;
  validada = false;
  loading = true;

	paises = [];
	paisesHash = {};
  ip = null;
  pais = null;
  user = null;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
			this.tipo = params.get("tipo");

      this.loadPaises();
		})
    
  }

	loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	    .subscribe(paises => {
			this.paises = paises;
			this.cdr.detectChanges();
			for(var i = 0; i < paises.length; i++){
				this.paisesHash[paises[i].iso2+""] = paises[i];
			}

			this.http.get<any>(`https://ipapi.co/json/`)
			.subscribe(data => {
				console.warn(data);

        this.ip = data.ip
        if(this.paisesHash[data.country_code]) this.pais = this.paisesHash[data.country_code].id;
        
        if(this.tipo) {
          this.continuarDemo();
        } else {
          this.loading = false;
          this.cdr.detectChanges();
        }
			});
		});
	}

  selectTipo(tipo: any) {
    this.tipo = tipo;
    this.cdr.detectChanges();
    this.continuarDemo();
  }

  continuarDemo() {
    this.loading = true;
    this.cdr.detectChanges();
    var actividad = "";
    var ofertante = false;
    var proveedor = false;
    var nombre = "";
    if(this.tipo == "aseguradora") {
      actividad = "Compañías de seguros";
      ofertante = true;
      nombre = "DEMO ASEGURADORA";
    } else {
      actividad = "Funerarias";
      proveedor = true;
      nombre = "DEMO FUNERARIA";
    }
    var user = {
      email: moment().valueOf() + "@demo.com",
      password: "demo_wfn",
      pais: this.pais,
      actividad: "",
      cif: this.ip,
      empresa: nombre,
      lang: this.translate.currentLang,
      status: "ACTIVO",
      demo: true,
      ofertante: ofertante,
      proveedor: proveedor
    }

		this.userService.register(user)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);

          this.authenticationService.login(data["email"], "demo_wfn")
            .pipe(first())
            .subscribe(
              data2 => {

                console.log(data2);
                this.user = data2;
                this.crearLicit1(function(){
                  this.crearLicit2(function(){
                    this.crearLicit3(function(){
                      this.ngZone.run(() => this.router.navigate(['/licitaciones'])).then();
                    }.bind(this));
                  }.bind(this));
                }.bind(this));
              },
              error => {
                console.error(error);
              });

          
        });
  }

  crearLicit1(cb) {
    var servicios = [
      {"createdAt":20200530162009,"updatedAt":20200530162009,"id":1,"nombre":"Servicio Completo","abreviatura":"SVFUN","nombreCorto":"Completo","detalle":"<b>Este servicio deberá incluir:</b><br>\r\n-Recogida local: El fallecimiento y el destino final (inhumación o incineración) se produce dentro de la misma localidad.<br>\r\n-Arca: En España debe será de madera y cumplirá la norma UNE 190001 que les son de aplicación. Pudiéndose elegir diferentes formas -redonda, semiredonda y egipcia-, tipos de acabado -mate o brillo- y ornamentos -distintos cristos o flores- de decoración en la tapa del arca). Del mismo modo, se contemplan diferentes medidas en función de las características del difunto. En servicios de incineración se emplean únicamente arcas ecológicas con su correspondiente certificado emitido por el proveedor. En este caso, todos los materiales utilizados deben ser respetuosos con el medioambiente en todo el ciclo de vida del producto, combustibles, degradables y/o biodegradables.<br>\r\n-Sudario: Bolsa en la que se introduce al difunto. En función de las circunstancias del fallecimiento puede ser de distintos materiales, como por ejemplo texpun o pvc para que ofrezcan mayor resistencia.<br>\r\n-Ornamento floral : Corona, ramos o centros.<br>\r\n-Certificado de defunción<br>\r\n-Acondicionamiento<br>\r\n-Tanatorio<br>\r\n-Ceremonia <br>\r\n-Gestiones y trámites<br>\r\n-Destino final : A elegir inhumación o incineración.<br>\r\n-Gestión documental postmorten\r\n","orden":1,"comentarios":"Comentarios de prueba","importeSalida":2000,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null,"tipo":"CREMA"},
      {"createdAt":20200530162244,"updatedAt":20200530162244,"id":8,"nombre":"Medicina Forense","abreviatura":"MEDIC","nombreCorto":"Forense","detalle":"Servicios de autopsia, tanatopraxia de conservación o servicios judiciales a definir.","orden":8,"comentarios":null,"importeSalida":500,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null},
      {"createdAt":20200530162300,"updatedAt":20200530162300,"id":9,"nombre":"Otros Servicios","abreviatura":"OTROS","nombreCorto":"Otros","detalle":"Servicios diferentes de los anteriores, solicitados por la familia , compañía o colectivo que personalizan la prestación , a modo de ejemplo se enumeran los siguientes:<br>\r\n - Esquela en prensa<br>\r\n-  Esquelas murales <br>\r\n-  Esquelas en radio<br>\r\n-  Recordatorios<br>\r\n-  Libro de firmas<br>\r\n-  Elementos emocionales (Colgantes o joyas)<br>\r\n-  Acompañamiento musical <br>\r\n-  Lápidas granito o mármol","orden":9,"comentarios":null,"importeSalida":200,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null,"detalleOtros":[{"codigo":"otros_esquela-prensa","nombre":"Esquela en prensa","valor":false},{"codigo":"otros_esquelas-murales","nombre":"Esquelas murales","valor":false},{"codigo":"otros_esquela-radio","nombre":"Esquelas en radio","valor":true},{"codigo":"otros_recordatorios","nombre":"Recordatorios","valor":true},{"codigo":"otros_libro-firmas","nombre":"Libro de firmas","valor":true},{"codigo":"otros_elementos-emocionales","nombre":"Elementos emocionales (Colgantes o joyas)","valor":false},{"codigo":"otros_acomp-musical","nombre":"Acompañamiento musical","valor":false},{"codigo":"otros_lapidas","nombre":"Lápidas granito o mármol","valor":false}]}
    ]
    var creador = null;
    if(this.tipo == "aseguradora") {
      creador = this.user.id;
    } else {
      if(environment.production) creador = 9
      else creador = 2;
    }
    var licit = {
      referencia: "WFN" + moment().format("YY") + (Math.random()*100).toFixed() + "00" + (moment().unix()+"").substring(2),
      referenciaCliente: "DEMO_01",
      startDate: new Date(),
      endDate: moment().add(5, 'minutes').toDate(),
      abierta: true,
      pujas: true,
      importeSalida: 2700,
      poblacionOrigen: "Bilbao",
      provinciaOrigen: 1162,
      paisOrigen: 207,
      comentarios: "",
      creador: creador,
      poblacionDestino: "",
      provinciaDestino: null,
      paisDestino: null,
      km: 0,
      tipoTraslado: "",
      servicios: servicios,
      tipo: "SERVICIO",
      divisa: "EUR",
      demo: true,
      usuarioDemo: this.user.id
    }
    var completado = function (resData){
      if(this.tipo == "funeraria") {
        cb();
      } else {
        cb();
      }
      
    }
    self.io.socket.post('/licitacion', licit, completado.bind(this));
  }

  crearLicit2(cb) {
    var creador = null;
    if(this.tipo == "aseguradora") {
      creador = this.user.id;
    } else {
      if(environment.production) creador = 9
      else creador = 2;
    }
    var licit = {
      referencia: "WFN" + moment().format("YY") + (Math.random()*100).toFixed() + "00" + (moment().unix()+"").substring(2),
      referenciaCliente: "DEMO_02",
      startDate: new Date(),
      endDate: moment().add(7, 'minutes').toDate(),
      abierta: true,
      pujas: false,
      importeSalida: 1200,
      poblacionOrigen: "Bilbao",
      provinciaOrigen: 1162,
      paisOrigen: 207,
      comentarios: "Esto es un ejemplo de comentarios para el traslado de Bilbao a Madrid por carretera.",
      creador: creador,
      poblacionDestino: "Madrid",
      provinciaDestino: 1159,
      paisDestino: 207,
      km: 400,
      tipoTraslado: "carretera",
      servicios: null,
      tipo: "TRASLADO",
      divisa: "EUR",
      demo: true,
      usuarioDemo: this.user.id
    }
    var completado = function (resData){
      if(this.tipo == "aseguradora") {  //Creamos una puja
        var userLicit = null;
        if(environment.production) userLicit = 10
        else userLicit = 1;
        var licit = {
          user: userLicit,
          importe: 1200,
          licitacion: resData.id,
        }
        var completado2 = function(resData){
          cb();
        }
        self.io.socket.post('/postLicitacion', {cola: licit}, completado2.bind(this));
      } else {
        cb();
        /*var licit2 = {
          user: 1,
          importe: 1200,
          licitacion: resData.id,
        }
        var completado2 = function(resData){
          cb();
        }
        self.io.socket.post('/postLicitacion', {cola: licit2}, completado2.bind(this));*/
      }
    }
    self.io.socket.post('/licitacion', licit, completado.bind(this));
  }

  crearLicit3(cb) {
    var servicios = [
      {"createdAt":20200530162202,"updatedAt":20200530162202,"id":4,"nombre":"Sala Tanatorio","abreviatura":"TANAT","nombreCorto":"Tanatorio","detalle":"Establecimiento funerario habilitado como lugar de etapa intermedia del cadáver entre el lugar del fallecimiento y el destino final. Supone el alquiler de sala velatorio por un tiempo que puede rondar entre las 18 y 36 horas en función de la hora fijada para la inhumación/incineración. La estancia está dotada generalmente con el servicio de aseos y de catering.","orden":2,"comentarios":null,"importeSalida":700,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null},
      {"createdAt":20200530162142,"updatedAt":20200530162142,"id":2,"nombre":"Cremación + Urna","abreviatura":"CREMA","nombreCorto":"Crem. Urna","detalle":"CREMACION: Proceso de cremación del cadáver a altas temperaturas, enfriamiento y cremulación para terminar vertiendo las cenizas en la urna elegida por la familia<br> <br> \r\nURNA CINERARIA: Recipiente destinado a contener las cenizas de un difunto y a inhumarlas si fuera el caso. Será de materiales no contaminantes y biodegradables si su destino es el medio ambiente (tierra o mar).  \r\n","orden":3,"comentarios":null,"importeSalida":1000,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null},
      {"createdAt":20200530162213,"updatedAt":20200530162213,"id":5,"nombre":"Flores (coronas y centros)","abreviatura":"FLOR","nombreCorto":"Flores","detalle":"El servicio mínimo estándar constará de una corona con 2 cabezales y un centro de flores. Una corona estándar estaría formada por un aro de 80cm y compuesta de aproximadamente de 110-130 claveles, 15-20 margaritas, 12-15 gladiolos, 6-8 robellini y varios ramos de paniculata y helecho. Los centros pueden ser bien de rosas (30-40 unidades aproximadamente) o bien de flor variada.","orden":5,"comentarios":"Lirios blancos","importeSalida":200,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null},
      {"createdAt":20200530162227,"updatedAt":20200530162227,"id":6,"nombre":"Coche Acompañamiento","abreviatura":"COCHE","nombreCorto":"Coche Ac.","detalle":"Vehículo puesto a disposición de la familia para trasladarles desde el tanatorio hasta el cementerio fijado dentro de la provincia para la inhumación del difunto. Se suele prestar con berlinas de lujo, taxis o autobuses.","orden":6,"comentarios":"Eléctrico","importeSalida":100,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null},
      {"createdAt":20200530162300,"updatedAt":20200530162300,"id":7,"nombre":"Gestiones Postmortem","abreviatura":"GEST","nombreCorto":"Gest. Post.","detalle":"Consiste en la obtención a la familia de los documentos esenciales para todos los trámites administrativos posteriores al fallecimiento relacionados con la herencia, cambio de titularidad, etc. Fundamentalmente son dos, los certificados literales de defunción, documento que acredita de manera oficial el fallecimiento de la persona y que será exigido para cualquier gestión ante entidades públicas o privadas, y el acta de últimas voluntades, que señala la existencia o no de testamento, y en su caso, ante qué notario se otorgó y cuando. ","orden":7,"comentarios":null,"importeSalida":400,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null},
      {"createdAt":20200530162244,"updatedAt":20200530162244,"id":8,"nombre":"Medicina Forense","abreviatura":"MEDIC","nombreCorto":"Forense","detalle":"Servicios de autopsia, tanatopraxia de conservación o servicios judiciales a definir.","orden":8,"comentarios":null,"importeSalida":400,"detalleMostrar":false,"detalleMostrarCompleto":false,"completo":false,"detalleCompleto":null}
    ]
    var creador = null;
    if(this.tipo == "aseguradora") {
      creador = this.user.id;
    } else {
      if(environment.production) creador = 9
      else creador = 2;
    }
    var licit = {
      referencia: "WFN" + moment().format("YY") + (Math.random()*100).toFixed() + "00" + (moment().unix()+"").substring(2),
      referenciaCliente: "DEMO_03",
      startDate: new Date(),
      endDate: new Date(),
      abierta: false,
      pujas: true,
      importeSalida: 3600,
      poblacionOrigen: "Sevilla",
      provinciaOrigen: 1145,
      paisOrigen: 207,
      comentarios: "",
      creador: creador,
      poblacionDestino: "",
      provinciaDestino: null,
      paisDestino: null,
      km: 0,
      tipoTraslado: "",
      servicios: servicios,
      tipo: "SERVICIO",
      divisa: "EUR",
      demo: true,
      prorrogas: 3,
      validada: true,
      usuarioDemo: this.user.id,
      trazas: "{\"contacto\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"tanat_cont\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"cremacion_cont\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"flor_cont\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"coche_cont\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"gest_cont\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"presentacion\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"cremacion\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"cremacion_realizada\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"cremacion_entrega\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null},\"medic_cont\":{\"completado\":false,\"fecha\":null,\"texto\":\"\",\"fechaTxt\":null}}"
    }
    var validar = function(resData) {
      console.warn(resData);
      var licit_edit = {
        ultimaLicitacion: resData.id,
        licitacionElegida: resData.id,
        usuarioElegido: resData.user
      }
      var completado2 = function (resData){
        cb();
      }

      self.io.socket.patch('/licitacion/' + resData.licitacion, licit_edit, completado2.bind(this));
    }
    var completado = function (resData){
      if(this.tipo == "aseguradora") {  //Creamos una puja
        var userLicit = null;
        if(environment.production) userLicit = 10
        else userLicit = 1;
        var licit = {
          user: userLicit,
          importe: 3200,
          licitacion: resData.id,
        }
        self.io.socket.post('/postLicitacion', {cola: licit}, validar.bind(this));
      } else {
        var licit2 = {
          user: this.user.id,
          importe: 3200,
          licitacion: resData.id,
        }
        self.io.socket.post('/postLicitacion', {cola: licit2}, validar.bind(this));
      }
    }
    self.io.socket.post('/licitacion', licit, completado.bind(this));
  }

}
